import React from 'react'
import locale from '../utils/locale'

export const getLocaledReasons = (reasons) =>
  reasons.map((reason) => ({
    ...reason,
    content: <LocaledText text={reason.text} />,
  }))

const LocaledText = ({ text }) => <span>{locale(text)}</span>

export default LocaledText
