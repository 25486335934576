import React from 'react'
import './style.css'

const Snackbar = ({ message }) => {
  return (
    <div id="snackbar" className="show">
      {message}
    </div>
  )
}

export default Snackbar
