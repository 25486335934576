import React from 'react'
import { propOr } from 'ramda'

const STACK = process.env.REACT_APP_STACK || 'c'
const defaultCountry = STACK === 'c' ? 'us' : 'gb'

export function Flag(props) {
  const countryCode = propOr(defaultCountry, 'countryCode', props).toLowerCase() || defaultCountry
  return (
    <>
      <img src={`${process.env.PUBLIC_URL}/flag_icons/flag_${countryCode}.png`} style={props.style} alt={countryCode} />
      {props.text}
    </>
  )
}
