import { assign } from '@xstate/immer'
import { parseErrorMessage } from '../../utils'

export const setServerError = assign((ctx, event) => {
  ctx.error = parseErrorMessage(event?.data?.response)
})

export const setLatitudeAndLongitude = assign((ctx, event) => {
  ctx.latitude = event.data.latitude
  ctx.longitude = event.data.longitude
})

export const setFacilityDetails = assign((ctx, event) => {
  ctx.facilityDetails = event.data
  ctx.markers = [
    {
      lat: event.data.yard_location_0_coordinate,
      lng: event.data.yard_location_1_coordinate
    },
    {
      lat: ctx?.latitude,
      lng: ctx?.longitude
    }
  ]
})

export const clearError = assign((ctx, event) => {
  ctx.error = ''
})
