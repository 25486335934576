import { propOr, assoc } from 'ramda'

export function getPropertyFromLocalStorage(key) {
  const selfServeKiosk = localStorage.getItem('self-service-kiosk')
  const obj = selfServeKiosk ? JSON.parse(selfServeKiosk) : {}
  return propOr('', key, obj)
}

export function setPropertyInLocalStorage(key, value) {
  const selfServeKiosk = localStorage.getItem('self-service-kiosk')
  const obj = selfServeKiosk ? JSON.parse(selfServeKiosk) : {}
  localStorage.setItem('self-service-kiosk', JSON.stringify(assoc(key, value)(obj)))
}
