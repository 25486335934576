export default {
  selectUserType: 'اختر أفضل وصف لك من بين الخيارات التالية',
  selectReasons: 'ما سبب زيارتك للموقع؟ (يرجي اختيار جميع الأسباب)',
  lotIndex: 'رقم الصنف (المركبة)',
  firstName: 'الاسم الأول',
  lastName: 'الاسم الأخير (اسم العائلة)',
  enterFirstName: 'الاسم الأول',
  enterLastName: 'الاسم الأخير (اسم العائلة)',
  enterPhone: 'رقم الهاتف المحمول',
  selectState: 'حالة موقع كوبارت',
  selectCopartLocation: 'موقع كوبارت',
  joinQueue: 'إحجز دوري بين العملاء',
  removeQueue: 'إزالة معاملتي من قائمة الانتظار',
  cancel: 'إلغاء',
  goHome: 'الرجوع للصفحة الرئيسة',
  confirmRemoveMessage: 'ستفقد دورك الحالي. لا يمكنك التراجع في العملية',
  selfServiceKiosk: 'منفذ الخدمة الذاتية',
  unableToJoinQueue:
    'إذا كنت لا تستطيع الانضمام لقائمة الانتظار، يرجى إعادة المحاولة لاحقًا',
  textAllowed: 'استلام التحديثات عبر الرسائل النصية',
  disclaimerText:
    '.من خلال اختيارك لهذه الخانه، أنت تتيح لكوبارت فرصة إرسال رسائل نصية عبر رقم الهاتف المحمول الذي قمت بإدخاله',
  thankYouSummary: '.نشكركم على صبركم وسعة صدركم',
  thankYouPremiumMember: '!نشكرك على إنضمامك كعضو مميز',
  currentPosition: 'دورك الحالي هو',
  willSendText: 'سنرسل لك رسالة نصية عندما يكون أحد ممثلينا',
  nearFrontLine: '.جاهزًا لتقديم الدعم والمساعدة',
  summary: ' موجز تعريفي',
  name: 'الاسم',
  userType: 'نوع المستخدم',
  reason: 'سبب (أسباب) الزيارة',
  phone: 'رقم الهاتف المحمول',
  copartLocation: 'موقع كوبارت',
  State: 'حالة موقع كوبارت',
  lotNumbers: 'صنف المركبة',
  createdTime: 'الوقت المحدد',
  English: 'الإنجليزية',
  Arabic: 'عربى',
  setupKioskYard: 'إعداد المنفذ لتسهيل إتمام العملية',
  requestStatus: 'الحالة',
  welcome: '!أهلا بك',
  receiveAssistance: '.يُرجى تعبئة العلومات التالية لكي تحجز دورك  بين عملائنا',
  somethingWentWrong: 'حدث خطأ ما',
  invalidExpiredRequest:
    'طلب غير صحيح أو منتهي الصلاحية. تعذر الحصول على المعلومات الخاصة بطلبكم ',
  thankYouForVisiting: '.نشكركم لزيارة موقع كوبارت',
  requireAssistance:
    'في حال كنت تحتاج للمساعدة، يرجى الانضمام لقائمة الانتظار من خلال الرجوع للصفحة الرئيسة وذلك بالنقر على الزر أدناه',
  lastUpdateTime: 'آخر التحديثات: ',
  estimatedWaitTime: 'وقت الانتظار المقدر',
  readyToAssist: 'أحد ممثلي كوبارت جاهر لتقديم المساعدة، تقدم من فضلك',
  waitTimeDisclaimer:
    '.يرجى الانتباه إلى أن دورك في قائمة الانتظار ووقت الانتظار قد يتغير قليلًا حسب الأولية في القائمة ',
  reportTimeDisclaimer:
    'يرجى إبلاغ المكتب بمجرد إخطارك ، أو قد يتم إلغاء موعدك.',
  numberOfLots: 'عدد الأصناف (المركبات)',
  chooseDesigantionText: 'يُرجى اختيار المسمى المناسب لتتمكن من البدء',
  enterMemberId: 'البريد الإلكتروني أو رقم العضوية',
  queue: 'قائمة الانتظار',
  hey: 'مرحبًا',
  fasterService: ' أدخل جميع أرقام المركبات أدناه، كي نخدمك بشكل أسرع',
  welcomeTo: 'مرحبا بكم في',
  selfServeKiosk: 'منفذ الخدمة الذاتية',
  copartSelfServeKiosk: 'منفذ كوبارت للخدمة الذاتية',
  Member: 'عضو',
  Transporter: 'الناقل',
  'Vehicle Owner': 'مالك السيارة',
  Vendor: 'بائع',
  Adjuster: 'الضابط',
  Other: 'آخر',
  'Vehicle Inspection': 'فحص المركبة',
  'Make Payments': 'تسديد المدفوعات',
  'Pick up/Drop off Vehicle Paperwork': 'استلام / تسليم أوراق المركبة',
  'Vehicle Drop off': 'تسليم السيارة',
  'Member Viewings': 'مشاهدات الأعضاء',
  'Remove Personal Items or Vehicle Items':
    'إزالة العناصر الشخصية أو عناصر السيارة',
  Active: 'نشط',
  Processing: 'العملية قيد التنفيذ',
  Cancelled: 'تم إلغاء العملية',
  Closed: 'مغلق',
  Completed: 'منجز',
  didYouKnow: 'هل كنت تعلم؟',
  payOnline: 'تقدم كوبارت عدة طرق للدفع عبر الإنترنت.',
  'CDS Seller': 'بائع CDS',
  'Premier Member': 'عضو مميز',
  'Pick up checks': 'استلام الشيكات',
  'Pickup Personal Vehicle Items': 'أغراض المركبة الشخصية',
  'Drop off/Pickup Titles or Paperwork': 'تسليم / استلام العناوين أو الأوراق',
  'Pickup Vehicles': 'مركبات بيك أب',
  'Preview Vehicles': 'معاينة المركبات',
  'Drop off/Pickup Keys': 'تسليم / التقاط المفاتيح',
  'Questions about Membership': 'أسئلة حول العضوية',
  'Questions about a Vehicle': 'أسئلة حول السيارة',
  'Sign Titles': 'توقيع العناوين',
  'Windshield labels': 'تسميات الزجاج الأمامي',
  selectFacilityText: 'حدد منشأة لعرض العنوان',
  allow: 'السماح',
  locationSharing: 'مشاركة الموقع',
  facilityAutoSelect: 'لتمكين الاختيار التلقائي لأقرب منشأة لك.',
  nearbyFacilitiesText: 'المواقع القريبة',
  mapViewHeader: 'اختر موقعا',
  facilitySelectMapInstruction:
    'حدد ولاية / مقاطعة لعرض مواقع كوبارت المعنية واضغط على الموقع الذي ترغب في وضعه في قائمة الانتظار على الخريطة لتحديد موقع.',
  selectFacility: 'اختر موقعا',
  locationDetails: 'تفاصيل الموقع',
  viewMap: 'إعرض الخريطة',
  yard: 'حديقة منزل',
  sublot: 'الدفعة الجزئية',
  locationsNotFound: 'لا توجد مواقع!',
  requestSubmitted: 'طلب مقدم',
  learnMore: 'أعرف أكثر'
}
