import React from 'react'
import ReactDOM from 'react-dom'
import JavascriptTimeAgo from 'javascript-time-ago'

import App from './App'

// locales for react-time-ago.
import en from 'javascript-time-ago/locale/en'
import es from 'javascript-time-ago/locale/es'

// Initialize the desired locales.
JavascriptTimeAgo.locale(en)
JavascriptTimeAgo.locale(es)

const rootElement = document.getElementById('root')
ReactDOM.render(<App />, rootElement)
