import React from 'react'
import { useActor } from '@xstate/react'
import { Form, Dropdown } from 'semantic-ui-react'
import LabelWithRequired from '../../components/Label'
import CustomWarningMessage from '../../components/CustomWarningMessage'
import locale from '../../utils/locale'

import LotItem from './LotItem'
import { numberOfLotsOptions, useLogging } from '../../utils'

const LotInfoItems = ({
  lotInfoItemsMachineRef,
  pickupLots,
  isCopartDelivery
}) => {
  const [state, send] = useActor(lotInfoItemsMachineRef)
  useLogging(lotInfoItemsMachineRef)

  const { context } = state

  return (
    <>
      <Form.Group widths="equal">
        <Form.Field className="numberOfLotsField">
          <LabelWithRequired
            labelText={
              state.matches('editing.nonTowProvider')
                ? 'numberOfLots'
                : state.matches('editing.towProvider') && 'numberOfGatePassPINs'
            }
          />
          <Dropdown
            selection
            className="dropdownField"
            placeholder={locale('numberOfLots')}
            value={context.numberOfLots}
            onChange={(e, { value }) =>
              send('SELECT_NUMBER_OF_LOTS', { value })
            }
            options={numberOfLotsOptions}
            data-testid="numberOfLotsField"
          />
        </Form.Field>
      </Form.Group>
      {isCopartDelivery === 'error' && context.countryCode === 'us' && (
        <div className="lotItemCustomMessageContainer">
          <CustomWarningMessage
            message={locale('copartDeliveryErrorMessage')}
          />
        </div>
      )}
      {context.lotInfoItems.map((item, index) => (
        <LotItem
          countryCode={context.countryCode}
          {...item}
          key={item.id}
          reasonsOptions={context.reasonOptions}
          index={index}
          numberOfLots={context.numberOfLots}
          pickupLots={pickupLots}
          isCopartDelivery={isCopartDelivery}
        />
      ))}
    </>
  )
}

export default LotInfoItems
