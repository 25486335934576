import React, { useEffect } from 'react'
import { pathOr } from 'ramda'

import {
  fetchReferenceData,
  fetchFacilitiesReferenceData,
  fetchKioskConfig,
  fetchTruckTypes
} from '../actions/selfServeActions'
import { parseReferenceData, getDefaultCountry } from '../utils'

export default function ReferenceData(props) {
  const fetchCountrySpecificReferenceData = (countryCode) => {
    fetchFacilitiesReferenceData(countryCode).then(
      ({ formattedFacilityData = [], mapViewData = {} }) => {
        props.setFacilities(
          parseReferenceData('facilities', formattedFacilityData)
        )
        props.setMapData(mapViewData)
      }
    )
    fetchReferenceData('user_types', countryCode).then((data) => {
      props.setUserTypes(parseReferenceData('user_types', data))
    })
    fetchReferenceData('reasons', countryCode).then((data) => {
      props.setReasons(parseReferenceData('reasons', data))
    })
    fetchReferenceData('states', countryCode).then((data) => {
      props.setStatesData(parseReferenceData('states', data))
    })
    fetchTruckTypes('truck_types_reference', countryCode).then((data) => {
      props.setTruckTypes(parseReferenceData('truck_types', data))
    })
  }

  useEffect(() => {
    fetchKioskConfig()
      .then((data) => props.setKioskConfig(data))
      .catch((error) => {
        // Default config
        console.error('Failed to fetch config')
        props.setKioskConfig({
          transporter_type: 'TRNSPTR',
          member_type: 'MBR'
        })
      })

    // eslint-disable-next-line
  }, [])

  const requestId = pathOr(null, ['match', 'params', 'requestId'], props)

  useEffect(() => {
    // fetching the ref data on going back to the form, from the summary screen
    if (!requestId && getDefaultCountry() === props.countryCode) {
      fetchCountrySpecificReferenceData(getDefaultCountry())
    }
    // eslint-disable-next-line
  }, [requestId])

  useEffect(() => {
    if (getDefaultCountry() !== props.countryCode) {
      props.setCountryCode(getDefaultCountry())
      fetchCountrySpecificReferenceData(getDefaultCountry())
    }
    // eslint-disable-next-line
  }, [props])

  return <>{props.children}</>
}
