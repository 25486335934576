export default {
  toastClose: {
    position: 'absolute',
    right: '20px',
    top: '15px',
    fontSize: '20px',
  },
  selectFacilityBtn: {
    color: 'white',
    backgroundColor: '#1d5ab9',
  },
  yardName: {
    color: '#1d5ab9',
    fontWeight: 'bold',
  },
  yardIcon: {
    opacity: 0.4,
    marginRight: '10px',
  },
  locationIcon: {
    opacity: 0.4,
    marginRight: '10px',
    marginTop: '2px',
  },
  addressText: {
    lineHeight: 1.33,
    maxWidth: '90%',
  },
  nearbyFacilities: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  divider: {
    margin: 0,
  },
  fieldErrContainerStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  errMessage: {
    color: '#db2828',
    fontSize: '12px',
  },
}
