import React from 'react'
import enDefaults from './en'

export default {
  ...enDefaults,
  selectUserType: "Choisissez l'option qui vous décrit le mieux",
  selectReasons:
    "Motif(s) de la visite? ( Sélectionnez tout ce qui s'applique)",
  lotIndex: 'Numéro de lot',
  firstName: 'Prénom',
  lastName: 'Nom',
  enterFirstName: 'Prénom',
  enterLastName: 'Nom de famille',
  enterPhone: 'Numéro de téléphone portable',
  selectState: "L'État de l'emplacement de Copart",
  selectCopartLocation: "L'emplacement de Copart",
  joinQueue: 'Accéder à Enregistrement - Visiteur',
  removeQueue: "Retirez-moi d'Enregistrement - Visiteur",
  cancel: 'Annuler',
  goHome: "Retour à la page d'accueil",
  confirmRemoveMessage:
    'Vous allez perdre votre position actuelle. Cette action ne peut être annulée.',
  selfServiceKiosk: 'Enregistrement - Visiteur',
  unableToJoinQueue: `Impossible d’accéder à Enregistrement - Visiteur, veuillez réessayer plus tard.`,
  textAllowed: 'Recevoir les mises à jour par messages textes',
  disclaimerText:
    'En cochant cette case, vous autorisez Copart à vous envoyer un message texte au numéro de téléphone indiqué ci-dessus.',
  thankYouSummary: 'Merci pour votre patience',
  thankYouPremiumMember: "Merci d'être un membre Premier!",
  currentPosition: "Votre position actuelle dans la file d'attente est",
  willSendText:
    "Nous vous enverrons un message texte lorsqu'un représentant sera présent",
  nearFrontLine: 'est prêt à vous aider',
  summary: 'Sommaire',
  name: 'Nom',
  userType: "Type d'utilisateur",
  reason: 'Motif(s) de la visite',
  phone: 'Numéro de téléphone',
  copartLocation: 'Copart location',
  State: 'État/Province',
  Province: 'Province de localisation de Copart',
  lotNumbers: 'Lot',
  createdTime: 'Heure de la création',
  English: 'Anglais',
  Spanish: 'Espagnol',
  French: 'Français',
  Russian: 'Russe',
  setupKioskYard: "Configurer un kiosque pour l'installation",
  requestStatus: 'État',
  welcome: 'Bienvenue!',
  receiveAssistance:
    "Veuillez remplir les informations ci-dessous pour réserver votre place dans la file d'attente.",
  somethingWentWrong: "Une erreur s'est produite.",
  invalidExpiredRequest:
    "Requête non valide ou expirée. Impossible d'obtenir un résumé des informations pour votre requête",
  thankYouForVisiting: "Merci d'avoir visité Copart.",
  requireAssistance:
    "Si vous avez besoin d'aide, vous pouvez rejoindre la file d'attente en cliquant sur le bouton 'Retour à la page d'accueil' ci-dessous.",
  lastUpdateTime: 'Dernière mise à jour',
  estimatedWaitTime: "Temps d'attente estimé",
  readyToAssist:
    "Un représentant de Copart est prêt à vous aider. Veuillez vous présenter au comptoir d'accueil.",
  waitTimeDisclaimer:
    "Veuillez noter que votre position dans la file d'attente et le temps d'attente peuvent varier légèrement en fonction de la priorité dans la file.",
  reportTimeDisclaimer:
    'Veuillez vous présenter au bureau dès que vous en serez informé, sinon votre rendez-vous pourrait être annulé.',
  numberOfLots: 'Nombre de lots',
  welcomeTo: 'Bienvenue dans',
  selfServeKiosk: 'Enregistrement - Visiteur',
  copartSelfServeKiosk: 'Enregistrement - Visiteur',
  chooseDesigantionText:
    'Veuillez choisir votre désignation appropriée ci-dessous pour commencer.',
  enterMemberId: 'Entrez votre numéro de membre ou votre adresse courriel',
  queue: "File d'attente",
  hey: 'Bonjour',
  fasterService:
    'Pour un service plus rapide, entrez tous les numéros de lot ci-dessous',
  Member: 'Membre',
  Transporter: 'Transporteur',
  'Vehicle Owner': "Propriétaire d'un véhicule",
  Vendor: 'Fournisseur',
  Adjuster: 'Ajusteur',
  Estimator: 'Estimateur',
  'CDS Seller': 'Vendeur CDS',
  'Premier Member': 'Membre Premier',
  Other: 'Autre',
  'Vehicle Inspection': 'Inspection de véhicule',
  'Make Payments': 'Effectuer des paiements',
  'Pickup Vehicles': 'Véhicules de ramassage',
  'Drop off/Pickup Titles or Paperwork':
    'Remettre / ramasser des titres ou des documents',
  'Drop off/Pickup Keys': 'Remise / ramassage des clés',
  'Preview Vehicles': 'Aperçu des véhicules',
  'Pickup Personal Vehicle Items':
    'Ramasser des articles de véhicule personnel',
  'Questions about Membership': "Questions sur l'adhésion",
  'Questions about a Vehicle': 'Questions sur un véhicule',
  'Sign Titles': 'Titres des signes',
  'Pick up checks': 'Ramasser les chèques',
  'Windshield labels': 'Étiquettes de pare-brise',
  selectFacilityText: "Sélectionnez un établissement pour afficher l'adresse",
  allow: 'Autoriser',
  locationSharing: 'partage de position',
  facilityAutoSelect:
    "pour activer la sélection automatique de l'établissement le plus proche.",
  nearbyFacilitiesText: 'Lieux à proximité',
  mapViewHeader: 'Sélectionnez un emplacement',
  facilitySelectMapInstruction:
    'Sélectionnez un état / une province pour afficher les emplacements Copart respectifs et appuyez sur un emplacement pour lequel vous souhaitez faire la queue sur la carte pour en sélectionner un.',
  selectFacility: "Sélectionnez l'emplacement",
  locationDetails: "Détails de l'emplacement",
  viewMap: 'Voir la carte',
  yard: 'Cour',
  sublot: 'SUBLOT',
  locationsNotFound: 'Aucun emplacement trouvé!',
  requestSubmitted: 'Demande soumise',
  joinOfficeQueue: 'Accéder à Enregistrement - Visiteur',
  chooseCopartLocation: `Choisissez l'emplacement de Copart`,
  phoneNumberInvalidWarning: `
Veuillez vous assurer d'entrer un numéro de téléphone portable valide`,
  Continue: 'Continuer',
  welcomeLabel: 'Bienvenu',
  nonTowProviderSelfServeFormFillFormHelpText: `Veuillez fournir les informations obligatoires ci-dessous pour accéder à Enregistrement - Visiteur.`,
  truckType: 'Type de camion',
  licenseState: `État de la plaque d'immatriculation`,
  licensePlateNumber: `Numéro de plaque d'immatriculation`,
  enterTruckType: `Entrez le type de camion'`,
  enterLicenseState: `Entrer l'état de la licence`,
  enterLicensePlateNumber: `Entrez le numéro de plaque d'immatriculation`,
  confirmation: 'Confirmation',
  limitedSlotsAvailableWarning: `Il est conseillé aux clients de Copart de prendre rendez-vous pour récupérer les véhicules. En raison de la forte demande, les créneaux disponibles peuvent être limités. Veuillez procéder à la prise de rendez-vous maintenant.`,
  checkSchedule: `Vérifier l'horaire`,
  towProviderSelfServeFormFillFormHelpText: (
    <>
      Veuillez fournir les informations{' '}
      <span style={{ color: 'red' }}>obligatoires</span> ci-dessous pour accéder
      à Enregistrement - Visiteur.
    </>
  ),
  gatePassPin: 'Broche de passage de porte',
  or: 'ou',
  slotsNotAvailableMessage:
    'Plage horaire non disponible. Pour toute assistance, cliquez sur le bouton ci-dessous pour accéder à Enregistrement - Visiteur.',
  didYouKnow: 'Le saviez-vous ?',
  payOnline: 'Copart offre plusieurs façons de payer en ligne.',
  learnMore: 'En savoir plus',
  'Unexpected error, could not verify the yard availability status':
    'Erreur inattendue, impossible de vérifier l’état de disponibilité de la cour',
  'Vehicle Drop off': 'Dépôt véhicule',
  'Pickup Check': 'Vérification de récupération',
  gatepassQuestion1: 'Besoin d’un code NIP de bon de sortie ?',
  gatepassInfo1: `Si vous êtes un transporteur, veuillez contacter le membre pour obtenir le code NIP de bon de sortie nécessaire.
  Si vous êtes un membre, vous pouvez voir votre NIP sur Copart.com ou sur l'application mobile des membres Copart.`,
  gatepassQuestion2: `Comment le membre peut-il voir son NIP
   de bon de sortie pour chaque lot ?`,
  gatepassInfo2: `Le membre peut localiser le NIP en se connectant à Copart.com ou à l'application mobile des membres Copart. Après vous être connecté, accédez à l'onglet Paiements. À cet endroit, le NIP de bon de sortie sera affiché pour tout véhicule que le membre a gagné sur la page Paiements dus.`,
  gotIt: 'Compris!',
  numberOfGatePassPINs: 'Nombre de NIP de laissez-passer'
}
