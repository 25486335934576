/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-useless-concat */

const STACK = process.env.REACT_APP_STACK || 'c'

const YCSMapper = () =>
  ({
    c: 'ycskioskus',
    uk: 'ycskioskuk'
  }[STACK])

export const endpoints = {
  get: {
    truckTypes: `/${YCSMapper()}/kiosk/vps/truck_types`,
    loaderBundleStatuses: `/${YCSMapper()}/kiosk/vps/loader_bundle_status`,
    availableSlots:
      `/${YCSMapper()}` +
      '/kiosk/vps/available_slots?start_datetime=${startDateTime}&end_datetime=${endDateTime}&facility_id=${facilityId}',
    appointmentAndTransporterDetailsByPhoneNumber:
      `/${YCSMapper()}` +
      '/kiosk/vps/appointment_details?status=A&start_datetime=${startDateTime}&end_datetime=${endDateTime}&transporter_phone_number=${phoneNumber}&facility_id=${facilityId}',
    appointmentSummaryByTransporterId:
      `/${YCSMapper()}` +
      '/kiosk/vps/appointment_details?transporter_id=${transporterId}',
    appointmentSummaryByAppointmentIdAndPhoneNumber:
      `/${YCSMapper()}` +
      '/kiosk/vps/appointment/${appointmentId}?transporter_phone_number=${phoneNumber}',

    walkInRequest:
      `/${YCSMapper()}` + '/kiosk/walk_in_request_summary/${requestId}',
    config: `/${YCSMapper()}` + `/kiosk/config`,
    kioskStatus: `/${YCSMapper()}` + '/kiosk/kiosk_status/${facilityId}',
    yardCoordinates: `/${YCSMapper()}` + '/kiosk/facility/${facilityId}',
    yardDetails: `/${YCSMapper()}` + '/kiosk/facility/${facilityId}',
    logging: `/${YCSMapper()}` + '/kiosk/logging',
    userTypeByPhoneNum: `/${YCSMapper()}` + '/kiosk/user_type'
  },
  post: {
    validateLots: `/${YCSMapper()}/kiosk/vps/validate_lots`,
    getFacilityInfo: '/ycskioskus/kiosk/facilities_and_sublots',
    markAsArrived: `/${YCSMapper()}/kiosk/vps/appointment/arrive`,
    scheduleAppointment: `/${YCSMapper()}/kiosk/vps/appointment/schedule`,
    joinQueue: `/${YCSMapper()}/kiosk/save_walk_in_request`,
    closestYard: `/${YCSMapper()}/kiosk/closest_yard`,
    references: `/${YCSMapper()}/kiosk/references`,
    sendSMS: `/${YCSMapper()}/kiosk/vps/send_text_notification`,
    validateCopartDelivery: `/${YCSMapper()}/kiosk/lots/list`
  },
  put: {
    cancelWalkInRequest:
      `/${YCSMapper()}` + '/kiosk/cancel_walk_in_request/${requestId}'
  },
  delete: {
    cancelAppointment: `/${YCSMapper()}/kiosk/vps/appointment`
  }
}
