import React from 'react'
import moment from 'moment'
import { toUpper } from 'ramda'

const Date = ({ date, active, holiday, onSelect }) => {
  const momentDate = moment(date, 'YYYY/MM/DD')
  const dayName = toUpper(momentDate.format('ddd'))
  const isHoliday = holiday ? ' DateHoliday' : ''
  const isActive = active ? ' DateActive' : ''

  return (
    <>
      <div
        className={'Date' + isHoliday + isActive}
        onClick={() => onSelect(date)}
      >
        <div className="DateDay">{dayName}</div>
        <div className="DateDate">{momentDate.format('D')}</div>
        <div className="DateMonth">{toUpper(momentDate.format('MMM'))}</div>
      </div>
    </>
  )
}

export default Date
