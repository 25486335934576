import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import renderIf from 'render-if'
import { Dropdown, Sticky } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

import { getCountryOptions, getLanguageOptions } from '../constants'
import { Flag } from './Flag'
import { CopartLogo } from '../components/Logo'
import locale from '../utils/locale'
import { setPropertyInLocalStorage } from '../utils/storage'

import './style.css'

const countryStyles = (countryCode) => {
  if (!countryCode) return { height: '24px' }
  return ['gb', 'us', 'ca'].includes(countryCode)
    ? { height: '24px' }
    : { height: '32px' }
}

function Header({ countryCode, history, language, setLanguage, location }) {
  const [languageText, setLanguageText] = useState('English')
  const [background, setBackground] = useState('transparent')
  const [showLogo, setShowLogo] = useState(false)

  const listenScrollEvent = (e) => {
    if (window.scrollY > 140) {
      setBackground('hsl(217, 73%, 42%)')
      setShowLogo(true)
    } else {
      setBackground('transparent')
      setShowLogo(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent)
  }, [])

  useEffect(() => {
    switch (language) {
      case 'fr':
        setLanguageText('Français')
        break
      case 'es':
        setLanguageText('Español')
        break
      case 'ru':
        setLanguageText('Pусский')
        break
      case 'ar':
        setLanguageText('عربى')
        break
      default:
        setLanguageText('English')
    }
  }, [language])

  return (
    <Sticky active>
      <div className="LogoContainer" style={{ background }}>
        <div className="AppBarSectionLeft">
          {renderIf(showLogo)(
            <Link to={`/`} onClick={(event) => event.stopPropagation()}>
              <div className="Logo">
                <CopartLogo />
                <span className="LogoText">{locale('selfServiceKiosk')}</span>
              </div>
            </Link>
          )}
        </div>
        <div className="AppBarSectionRight">
          <div className="CountryFlag">
            <Dropdown
              inline
              className="CountryDropDown"
              onChange={(e, { value }) => {
                window.location.href = window.location.href
                  .replace(/\.com|\.ca/g, value === 'us' ? '.com' : '.ca')
                  .replace(language, 'en')
              }}
              icon={
                <Flag
                  countryCode={countryCode}
                  style={countryStyles(countryCode)}
                />
              }
              options={getCountryOptions(Flag)}
              text="  "
            />
          </div>
          <Dropdown
            floating
            button
            labeled
            className="icon"
            icon="globe"
            onChange={(e, { value }) => {
              setLanguage(value)
              const updateLanguageFromUrl = location?.pathname.replace(
                location?.pathname.split('/')[1],
                value
              )
              history.push({ pathname: updateLanguageFromUrl })
              setPropertyInLocalStorage('language', value)
            }}
            options={getLanguageOptions(countryCode)}
            text={languageText}
          />
        </div>
      </div>
    </Sticky>
  )
}

export default withRouter(Header)
