import React from 'react'
import { Button, Icon, Table, Divider } from 'semantic-ui-react'
import locale from '../../utils/locale'
import Popup from '../../components/Popup'
import ErrorMessage from '../../components/ErrorMessage'

const LotsDueAmount = ({ joinOfficeQueue, close, loading, dueLots }) => {
  return (
    <Popup height="auto" className="scheduleAppointment lotsDueAmountModal">
      <Icon
        name="close"
        onClick={close}
        style={{ fontSize: '20px' }}
        color="black"
      />
      <div className="lotsInvalidHeader">{locale('lotsStatusHeading')}</div>
      <Divider />
      <ErrorMessage message={locale('clearLotDue')} data-testid="error" />
      <Table unstackable size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="2" className="LotTableHeader">
              {locale('lot')}
            </Table.HeaderCell>
            <Table.HeaderCell className="LotTableHeader"></Table.HeaderCell>
            <Table.HeaderCell className="LotTableHeader">
              {locale('amountDue')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dueLots.map((lot, index) => (
            <Table.Row key={`detail_${index}`}>
              <Table.Cell className="SummaryTableCell" colSpan="2">
                {lot.lot_number}
              </Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell
                className="SummaryTableCell"
                style={{ color: '#9f3a38' }}
              >
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD'
                }).format(lot.amountDue)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <div style={{ paddingTop: '10px' }}>
        <Button fluid onClick={joinOfficeQueue} loading={loading} primary>
          {locale('joinOfficeQueue')}
        </Button>
      </div>
    </Popup>
  )
}

export default LotsDueAmount
