import React from 'react'
import { Icon, Message } from 'semantic-ui-react'

const ErrorMessage = ({ message = '', header = '', list = [], ...props }) => {
  return (
    <Message error {...props}>
      <Message.Header>{header}</Message.Header>
      <Message.List items={list} />
      {message !== '' && (
        <div style={props?.style}>
          <Icon name="warning" />
          {message}
        </div>
      )}
    </Message>
  )
}

export default ErrorMessage
