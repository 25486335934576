import { Machine, sendParent } from 'xstate'
import { stampCustomLog } from '../../utils/logs'

const machine = Machine({
  id: 'yardDetails',
  initial: 'idle',
  on: {
    // FETCH_YARD_DETAILS: {},
    FETCH_YARD_STATUS: {
      actions: 'setClosestYardDetails',
      target: '.fetchingYardStatus'
    },
    LOCATION_DETAILS_CHANGE: [
      {
        cond: 'skipClosestYard',
        actions: 'setClosestYardDetailsFromUrl',
        target: '.fetchingYardStatus'
      },
      {
        actions: 'setLocationDetails',
        target: '.fetchingYardDetails'
      }
    ]
  },
  states: {
    idle: {},
    fetchingYardDetails: {
      invoke: {
        id: 'getClosestYard',
        src: 'getClosestYard',
        onDone: {
          actions: 'setClosestYardDetails',
          target: 'fetchingYardStatus'
        },
        onError: {
          target: 'serverError'
        }
      }
    },
    fetchingYardStatus: {
      invoke: {
        id: 'getYardDetails',
        src: 'getYardDetails',
        onDone: {
          actions: [
            'setYardDetailsAndStatus',
            sendParent((ctx) => ({
              type: 'UPDATE_FACILITY_DETAILS',
              data: { ...ctx }
            })),
            (ctx) =>
              stampCustomLog({
                currentState: ctx.yardOpen ? 'yardOpen' : 'yardClosed',
                event: 'UPDATE_FACILITY_DETAILS',
                facilityId: ctx.facilityId
              })
          ],
          target: 'idle'
        },
        onError: {
          actions: [
            'setYardStatusAndClosedServerError',
            sendParent((ctx) => ({
              type: 'UPDATE_FACILITY_DETAILS',
              data: { ...ctx }
            }))
          ],
          target: 'serverError'
        }
      }
    },
    serverError: {
      tags: ['serverError']
    }
  }
})

export default machine
