import { assign } from '@xstate/immer'
import { send, spawn, assign as xAssign } from 'xstate'

import {
  setPropertyInLocalStorage,
  getPropertyFromLocalStorage
} from '../../../utils/storage'
import {
  parseErrorMessage,
  appendDialCode,
  getNavigatorGeolocation
} from '../../../utils'
import markAsArrivedMachine from '../../../machines/markAsArrived'

const getReasons = (hasKeys, titleStatus) => {
  let reasons = []
  if (hasKeys === 'Y') reasons.push('PUKYS')
  if (titleStatus === 'Y') reasons.push('PKTPP')
  return reasons
}

export const setPhoneNumberFromStorageToCtx = assign((ctx, event) => {
  ctx.form.inputs.parsedPhone = {}
  ctx.form.inputs.parsedPhone.rawPhoneNumber =
    getPropertyFromLocalStorage('phoneNumber')
  ctx.form.inputs.phoneNumber = appendDialCode(
    ctx.form.inputs.parsedPhone.rawPhoneNumber,
    ctx.countryCode
  )
})

export const setAppointmentData = assign((ctx, event) => {
  ctx.appointmentData = event.data
  ctx.timeZone = event.data.timeZone
  ctx.showSummary = true
})

export const setLocationToCtx = assign((ctx, event) => {
  ctx.referenceData = { ...ctx.referenceData, ...event.data }
})

export const toggleSummary = assign((ctx) => {
  ctx.showSummary = !ctx.showSummary
})

export const setLoaderBundleToCtx = assign((ctx, event) => {
  ctx.referenceData = { ...ctx.referenceData, ...event.data }
})

export const setReasonsReferenceDataToCtx = assign((ctx, event) => {
  ctx.referenceData = { ...ctx.referenceData, ...event.data }
})

export const setPickupSublotInfoToCtx = assign((ctx, event) => {
  ctx.pickupSublotData = event.data
})

export const defaultPickupSublotInfoToCtx = assign((ctx) => {
  ctx.pickupSublotData = {}
})

export const mapAppointmentData = assign((ctx) => {
  if (ctx.appointmentData) {
    if (ctx.referenceData.locationDetails)
      ctx.facilityDetails =
        ctx.referenceData.locationDetails.find(
          (loc) => loc.yard_number === ctx.appointmentData.facilityId
        ) || null

    if (ctx.referenceData.loaderBundles)
      ctx.appointmentData.lotDetails = ctx.appointmentData.lotDetails?.map(
        (data) => ({
          ...data,
          lotStatusDescription: ctx.referenceData.loaderBundles[data.lotStatus]
        })
      )

    if (ctx.referenceData.reasons)
      ctx.appointmentData.lotDetails = ctx.appointmentData.lotDetails?.map(
        (data) => ({
          ...data,
          reasons: getReasons(data.hasKeys, data.titleStatus)
        })
      )
  }
})

export const setPhoneNumber = assign((ctx, event) => {
  ctx.form.inputs.phoneNumber = event.phone
})

export const setPhoneNumberInStorage = (ctx) => {
  setPropertyInLocalStorage(
    'phoneNumber',
    ctx.form.inputs.parsedPhone.rawPhoneNumber
  )
}

export const setParsedPhoneNumber = assign((ctx, event) => {
  ctx.form.inputs.parsedPhone = {
    dialCode: event.countryData && event.countryData.dialCode,
    rawPhoneNumber: event.parsedPhone && event.parsedPhone.nationalNumber,
    isValid: event.parsedPhone && event.parsedPhone.isValid()
  }
})

export const setServerError = assign((ctx, event) => {
  ctx.error = parseErrorMessage(event?.data?.response)
})

export const clearServerErrors = assign((ctx) => {
  ctx.error = null
})

export const setVirtualQueueId = assign((ctx, event) => {
  ctx.requestId = event.data.virtualQueueId
})

export const setReasons = assign((ctx, event) => {
  ctx.appointmentData.lotDetails = ctx.appointmentData.lotDetails.map(
    (lotData) => {
      if (event.lotNumber === lotData.lotNumber) {
        return {
          ...lotData,
          reasons: event.value
        }
      }
      return lotData
    }
  )
})

export const setLastSuccessfulFetchTime = assign((ctx) => {
  ctx.lastSuccessfulFetchTime = new Date()
})

export const spawnMarkAsArrivedMachine = xAssign((ctx) => {
  const markAsArrivedMachineRef = spawn(
    markAsArrivedMachine.withContext({
      latitude: ctx.latitude,
      longitude: ctx.longitude,
      closestYard: ctx.closestYard,
      appointmentDate: ctx.appointmentData.startTime,
      lots: ctx.appointmentData.lotDetails,
      appointmentStatus: ctx.appointmentData.appointmentStatus,
      appointmentHeaderId: ctx.appointmentData.appointmentHeaderId,
      transporterId: ctx.appointmentData.transporterId,
      isInSummaryPage: true,
      facilityId: ctx.appointmentData.facilityId,
      timeZone: ctx.timeZone
    }),
    {
      devTools: true
    }
  )
  return { ...ctx, markAsArrivedMachineRef }
})

export const stopSpawnedMarkAsArrivedMachine = xAssign((ctx) => {
  ctx.markAsArrivedMachineRef.stop()
})

export const setLatitudeAndLongitude = assign((ctx, event) => {
  ctx.latitude = event.latitude
  ctx.longitude = event.longitude
})

export const setLatLongFromGeoLocation = assign((ctx, event) => {
  getNavigatorGeolocation(
    function (position) {
      console.log('Latitude is :', position.coords.latitude)
      console.log('Longitude is :', position.coords.longitude)
      ctx.latitude = position.coords.latitude
      ctx.longitude = position.coords.longitude
    },
    (error) => {
      console.error('Error getting location: ', error.message)
    }
  )
})

export const setClosestYard = assign((ctx, event) => {
  ctx.closestYard.stateCode = event.data?.stateCode
  ctx.closestYard.facilityId = event.data?.facilityId
})

export const sendLatitideLongitudeToMarkAsArrivedMachine = send(
  (ctx) => ({
    type: 'LOCATION_DETAILS_CHANGE',
    data: {
      latitude: ctx.latitude,
      longitude: ctx.longitude
    }
  }),
  {
    to: (ctx) => ctx.markAsArrivedMachineRef
  }
)

export const scrollToView = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
}
