import { pathOr, head, isNil, isEmpty } from 'ramda'
import moment from 'moment-timezone'
import fetcher from '../../utils/fetcher'

import { walkinRequestSummary } from './mock'
import {
  transformSlotData,
  mapLotData,
  mapPickupFacility,
  getSource,
  mapTransporterAndAppointmentDetails,
  substitute,
  convertDateToYardTimeZone
} from '../../utils'
import { DATE_RANGE, countryMapper } from '../../constants'
import { endpoints } from '../../config/endpoints'
import { isAppointmentSchedulingForToday } from './guards'

const STACK = process.env.REACT_APP_STACK || 'c'

const mockService = (data) => {
  return new Promise((resolve) => {
    return setTimeout(resolve, 1000, data)
  })
}

export const fetchSummaryDetailsService = () => {
  return mockService(walkinRequestSummary).then((res) => {
    return { data: res }
  })
}

export const getAppointmentDetailsService = (ctx) => {
  return fetcher
    .get(
      substitute(endpoints.get.appointmentAndTransporterDetailsByPhoneNumber, {
        startDateTime: convertDateToYardTimeZone(moment(), ctx.timeZone)
          .startOf('day')
          .utc()
          .format(),
        endDateTime: convertDateToYardTimeZone(moment(), ctx.timeZone)
          .endOf('day')
          .utc()
          .format(),
        phoneNumber: ctx.form.inputs.parsedPhone.rawPhoneNumber,
        facilityId: ctx.form.inputs.facilityId
      })
    )
    .then((res) => {
      return mapTransporterAndAppointmentDetails(
        res.data?.data,
        ctx.form.inputs.reasonCodes,
        ctx.timeZone
      )
    })
}

export const checkForLotsValidityService = (ctx) => {
  const payload = {
    lot_details: ctx.pickupLots?.map((item) => ({
      lot_number: item.lot_number,
      member_id: item.member_number,
      lot_code: item.gate_pass_pin
    })),
    transporter_id: ctx.transporter?.details?.transporterId,
    transporter_phone_number: ctx.form.inputs?.parsedPhone?.rawPhoneNumber,
    facility_id: ctx.form.inputs?.facilityId
  }

  return fetcher
    .post(endpoints.post.validateLots, payload, {
      'X-YARD': ctx.form.inputs.facilityId
    })
    .then((res) => {
      return mapLotData(res.data?.data, ctx.pickupLots)
    })
}

export const getLotsFacilityInfo = (ctx) => {
  const payload = {
    facility_ids: [head(ctx.validatedPickupLots).facility_id]
  }
  return fetcher
    .post(endpoints.post.getFacilityInfo, payload, {
      source: 'mobile'
    })
    .then((res) => {
      return mapPickupFacility(res.data?.data[0])
    })
}

export const getSlotsService = (ctx) => {
  const facilityId =
    ctx?.pickupSublot?.facilityTypeCode !== 'S'
      ? ctx.form.inputs.facilityId
      : ctx?.pickupSublot?.yardNumber
  return fetcher
    .get(
      substitute(endpoints.get.availableSlots, {
        startDateTime: convertDateToYardTimeZone(moment(), ctx.timeZone).unix(),
        endDateTime: convertDateToYardTimeZone(moment(), ctx.timeZone)
          .add(DATE_RANGE, 'days')
          .unix(),
        facilityId
      }),
      { 'X-YARD': ctx.form.inputs.facilityId }
    )
    .then((res) => {
      return transformSlotData(res.data?.data, ctx.timeZone)
    })
}

export const markAsArrivedService = (ctx) => {
  const payload = {
    appointment_header_id: ctx.transporter.appointments.appointmentHeaderId,
    transporter_id: ctx.transporter.details.transporterId
  }
  return fetcher.post(endpoints.post.markAsArrived, payload).then((res) => {
    return res
  })
}
export const getGatePassPin = (lotInfoItems, pickupLot) => {
  const gatePassLot = lotInfoItems.filter(
    (item) => item?.lot_number === pickupLot?.lot_number
  )
  const res =
    !isNil(gatePassLot) || !isEmpty(gatePassLot)
      ? gatePassLot[0]?.gate_pass_pin
      : ''
  return res
}

export const joinQueueTransporter = (ctx, event) => {
  console.log('ctx:::', ctx)
  const vps = {
    schedule_type: isAppointmentSchedulingForToday(ctx) ? 'walkin' : '',
    scheduled_dt: ctx.schedule.selectedStartDateTime,
    schedule_id: ctx.schedule.selectedScheduleId,
    truck_type: ctx.form.inputs.truckType,
    transporter_details: {
      phone_number: ctx.form.inputs?.parsedPhone?.rawPhoneNumber,
      name: `${ctx.form.inputs.firstName} ${ctx.form.inputs.lastName}`,
      license_plate: ctx.form.inputs.licensePlateNumber,
      license_state_code: ctx.form.inputs.licenseState,
      country_code: countryMapper[ctx.form.countryCode],
      state: ctx.form.inputs.stateCode,
      facility_id: ctx.form.inputs.facilityId,
      transporter_id: ctx.transporter.details.transporterId
    },
    lots: ctx.validatedPickupLots.map((lot) => ({
      lot_number: lot.lot_number,
      member_id: lot.member_number,
      reasons: lot.reasons,
      gate_pass_pin: getGatePassPin(ctx.form.inputs?.lotInfoItems, lot)
    }))
    // appointment_header_id: ctx.transporter.appointments.appointmentHeaderId
  }

  const payload = {
    user_type: ctx.form?.inputs?.userType,
    first_name: ctx.form?.inputs?.firstName,
    last_name: ctx.form?.inputs?.lastName,
    phone_number: ctx.form?.inputs?.phoneNumber,
    yard_number: ctx.form?.inputs?.facilityId,
    state: ctx.form?.inputs?.stateCode,
    latitude: ctx.latitude,
    longitude: ctx.longitude,
    lots_count: ctx.form?.inputs?.numberOfLots,
    is_text_allowed: ctx.form?.inputs?.textAllowed ? 'Y' : 'N',
    source: getSource(),
    lots: ctx.form?.inputs?.lotInfoItems.map((item) => ({
      lot_number: item.lot_number,
      reasons: item.reasons,
      member_id: item.member_number,
      gate_pass_pin: item.gate_pass_pin
    })),
    vps
  }

  return fetcher.post(endpoints.post.joinQueue, payload).then((res) => {
    const uniqueId = pathOr(null, ['data', 'data', 'uniq_id'], res)
    const appointmentId = pathOr(
      null,
      ['data', 'data', 'data', 'appointment_header_id'],
      res
    )
    return { virtualQueueId: uniqueId, appointmentId }
  })
}

export const joinQueue = (ctx) => {
  const requestAttributes =
    STACK === 'c' && ctx.form?.inputs?.memberRequestId
      ? {
          request_attributes: {
            member_request_id: ctx.form?.inputs?.memberRequestId
          }
        }
      : {}

  const lots = ctx.form?.inputs?.lotInfoItems.map((item) => ({
    lot_number: item.lot_number,
    reasons: item.reasons,
    member_id: item.member_number,
    gate_pass_pin: item.gate_pass_pin
  }))

  const payload = {
    user_type: ctx.form?.inputs?.userType,
    first_name: ctx.form?.inputs?.firstName,
    last_name: ctx.form?.inputs?.lastName,
    phone_number: ctx.form?.inputs?.phoneNumber,
    latitude: ctx.latitude,
    longitude: ctx.longitude,
    lots_count: ctx.form?.inputs?.numberOfLots,
    yard_number: ctx.form?.inputs?.facilityId,
    state: ctx.form?.inputs?.stateCode,
    source: getSource(),
    is_text_allowed: ctx.form?.inputs?.textAllowed ? 'Y' : 'N',
    lots,
    ...requestAttributes
  }

  return fetcher.post(endpoints.post.joinQueue, payload).then((res) => {
    const uniqueId = pathOr({}, ['data', 'data', 'uniq_id'], res)
    return { virtualQueueId: uniqueId }
  })
}

export const getYardClosedMessage = (ctx) => {
  return fetcher
    .get(
      substitute(endpoints.get.kioskStatus, {
        facilityId: ctx.form?.inputs?.facilityId
      })
    )
    .then((res) => ({
      yardClosedMessage: pathOr('', ['data', 'data'], res)
    }))
}

export const getUserTypeService = (ctx, event) => {
  return fetcher
    .get(endpoints.get.userTypeByPhoneNum, {
      'x-phone-number': ctx.form?.inputs?.phoneNumber,
      'x-yard-number': ctx.form?.inputs?.facilityId
    })
    .then((res) => {
      return {
        userType: pathOr('', ['data', 'data', 'user_type'], res),
        reasons: event.reasons,
        userTypes: event.userTypes
      }
    })
}
