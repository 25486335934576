//eslint-disable-next-line
import React, { useContext } from 'react'
import { isEmpty, type } from 'ramda'

import { LanguageContext } from '../context/LanguageContext'
import locales from '../constants/locale'

export default (text, ...args) => {
  if (text === undefined) return // TO DO: Remove once sure that locale is being called with valid text

  const [currentLocale] = useContext(LanguageContext)
  let translatedPhrases = locales[currentLocale]
  const translation =
    typeof translatedPhrases[text] === 'function' ? translatedPhrases[text](args) : translatedPhrases[text]
  if (translation !== undefined && translation !== null) return translation

  const arr = type(text) === 'String' ? text.split('.') : []

  if (isEmpty(arr)) {
    return text.toString()
  }
  if (arr && arr.length === 1) {
    const translatedValue = translatedPhrases[text]
    return translatedValue ? translatedValue.toString() : text.toString()
  }
  if (arr.length > 1) {
    // eslint-disable-next-line
    arr.map(object => {
      if (translatedPhrases) {
        translatedPhrases = translatedPhrases[object]
      }
    })
  }
  return translatedPhrases ? translatedPhrases.toString() : text
}
