import React from 'react'
import { Button, Icon, Label, Modal, Placeholder } from 'semantic-ui-react'
import { keys, isEmpty, isNil, groupBy } from 'ramda'
import locale from '../../utils/locale'
import Date from '../../components/Date'
import LotsDueAmount from './LotsDueAmount'
import ErrorMessage from '../../components/ErrorMessage'
import { getAddressByFacility } from '../../utils'
import CopartDeliveryLogo from '../../components/CopartDeliveryLogo/CopartDeliveryLogo.png'

const markerStyle = {
  color: '#000000',
  opacity: 0.4,
  margin: '0px 10px 0 1px'
}

const Slot = ({
  slot,
  slotSelected,
  numberOfLots,
  onSlotSelect,
  isCopartDelivery
}) => {
  const availableSlots = slot.capacity - slot?.scheduled_lots
  return (numberOfLots <= availableSlots && availableSlots > 0) ||
    isCopartDelivery ? (
    <Label
      key={slot?.schedule_id}
      size={'medium'}
      onClick={onSlotSelect}
      color={slotSelected === slot?.schedule_id ? 'blue' : 'grey'}
    >
      <div className="slotTime">{slot?.slot}</div>
      <div className="slotCapacity">
        {!isCopartDelivery && `${availableSlots} ${locale('openSlots')}`}
      </div>
    </Label>
  ) : (
    <Label key={slot?.schedule_id} size={'medium'} color={'#bababc'}>
      <div className="slotTime disabled">{slot?.slot}</div>
      <div className="slotCapacity disabled">{locale('spotsNotAvailable')}</div>
    </Label>
  )
}

const Slots = ({
  slots = [],
  slotSelected,
  onSlotSelect,
  numberOfLots,
  isCopartDelivery
}) => {
  const data = groupBy((slot) => {
    const currentHour = slot.startDateTime.format('HH')
    return currentHour >= 0 && currentHour < 12
      ? 'morningSlots'
      : 'afternoonSlots'
  })(slots)

  return (
    <>
      {!isNil(data.morningSlots) && (
        <>
          <div className="gridHeader">{locale('morningTime')}</div>
          {data.morningSlots?.map((slot) => (
            <Slot
              slot={slot}
              slotSelected={slotSelected}
              numberOfLots={numberOfLots}
              onSlotSelect={() => {
                onSlotSelect(slot?.schedule_id)
              }}
              isCopartDelivery={isCopartDelivery}
            />
          ))}
        </>
      )}
      {!isNil(data.afternoonSlots) && (
        <>
          <div className="gridHeader">{locale('afternoonTime')}</div>
          {data.afternoonSlots?.map((slot) => (
            <Slot
              slot={slot}
              slotSelected={slotSelected}
              numberOfLots={numberOfLots}
              onSlotSelect={() => {
                onSlotSelect(slot?.schedule_id)
              }}
              isCopartDelivery={isCopartDelivery}
            />
          ))}
        </>
      )}
    </>
  )
}

const ScheduleAppointment = ({ state, send, joinOfficeQueue, mapData }) => {
  const { context = {} } = state
  const { schedule, validatedPickupLots, isCopartDelivery } = context
  const { availableSlots, selectedDate, selectedScheduleId } = schedule || {}
  const yardAddress =
    context?.pickupSublot?.facilityTypeCode !== 'S'
      ? getAddressByFacility(mapData, context.form.inputs.facilityId)
      : context?.pickupSublot

  const getAvailableSlotsWithCapacities = (dates) => {
    const res = {}
    Object.entries(dates).map(([key, val]) => {
      res[key] = val.filter((el) => el.capacity !== 0)
    })
    return res
  }

  return (
    <Modal open className="scheduleAppointment mapViewModal">
      <Icon
        name="close"
        onClick={() => {
          send('CLOSE')
        }}
        style={{ fontSize: '20px' }}
        color="black"
      />
      <div>
        <div className="selectFacilityHeader">
          {locale('scheduleappointment')}
        </div>
        {state.hasTag('noSlots') ? (
          <div>
            <div
              style={{
                alignItems: 'center',
                fontSize: '20px',
                margin: '150px 15px'
              }}
            >
              {locale('slotsNotAvailableMessage')}
            </div>
            <div className="joinQueueButton">
              <Button
                fluid
                onClick={joinOfficeQueue}
                loading={
                  state.hasTag('noSlots') && state.hasTag('joiningOfficeQueue')
                }
                primary
                style={{
                  fontSize: '16px',
                  position: 'fixed',
                  bottom: '20px',
                  width: '90%',
                  left: '5%'
                }}
                data-testid="reservePlaceInLine"
              >
                {locale('joinOfficeQueue')}
              </Button>
            </div>
          </div>
        ) : state.hasTag('getSlotsDetails') ? (
          <div className="Placeholder">
            <div className="facilityDetails">
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </div>
            <div className="facilityAddress">
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </div>
            <div className="appointmentDate">
              <div className="placeholderSelectDateHeader">
                <Placeholder>
                  <Placeholder.Line />
                </Placeholder>
              </div>
              <div className="placeholderSelectDates">
                <Placeholder>
                  <Placeholder.Line length="full" />
                  <Placeholder.Line length="full" />
                  <Placeholder.Line length="full" />
                </Placeholder>
              </div>
            </div>
            <div className="selectAppointmentTimeHeader">
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </div>

            <div className="lots">
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </div>

            <div className="selectAppointmentTime">
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </div>

            <Placeholder>
              {Array(1)
                .fill(0)
                .map((i) => (
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                ))}
            </Placeholder>
            <div className="placeholderContinueButton">
              <Placeholder>
                <Placeholder.Line length="full" />
                <Placeholder.Line length="full" />
              </Placeholder>
            </div>
          </div>
        ) : (
          <>
            <div
              style={{
                height:
                  state.hasTag('error') && context.error ? '72vh' : '82vh',
                position: 'relative',
                overflowY: 'auto'
              }}
            >
              <div style={{ padding: '10px 20px' }}>
                <div className="addressContainer">
                  <Icon
                    name="building outline"
                    style={{ fontSize: '20px' }}
                    color="black"
                  />
                  {context?.pickupSublot?.facilityTypeCode !== 'S' && (
                    <div className="addressText">
                      {`${locale('yard')} ${yardAddress.yardNumber} - ${
                        yardAddress.yardName
                      }`}
                    </div>
                  )}
                  {context?.pickupSublot?.facilityTypeCode === 'S' && (
                    <div className="addressText">
                      <span className="sublotLabel">{locale('sublot')}</span>
                      &nbsp;
                      <span>{`${
                        yardAddress.sublotCode || yardAddress.yardNumber
                      } - ${yardAddress.yardName}`}</span>
                    </div>
                  )}
                </div>
                <div className="addressContainer">
                  <Icon name="map marker alternate" style={markerStyle} />
                  <div>
                    {' '}
                    {yardAddress?.addressLine1}
                    {yardAddress?.addressLine2 &&
                      `, ${yardAddress?.addressLine2}`}
                    , {yardAddress?.city}, {yardAddress?.yardStateCode},{' '}
                    {yardAddress?.zip}
                  </div>
                </div>
                {context?.pickupSublot?.facilityTypeCode === 'S' && (
                  <div className="sublotWarningText">
                    <div>
                      <strong>
                        <span>{'This appointment is located at '}</span>
                        <span className="sublotLabel inlineSublotLabel">
                          {locale('sublot')}
                        </span>
                        <span>
                          {` ${
                            yardAddress.sublotCode || yardAddress.yardName
                          }. Please ensure you arrive at the sublot's address.`}
                        </span>
                      </strong>
                    </div>
                    <a
                      className="material-icons sublotMapIcon"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://maps.google.com/maps?daddr=${yardAddress?.addressLine1} ${yardAddress?.addressLine2} ${yardAddress?.city} ${yardAddress?.yardStateCode} ${yardAddress?.zip}`}
                    >
                      location_on
                    </a>
                  </div>
                )}

                <section className="selectDateSection">
                  <header className="selectDateHeader">Select Date</header>
                  <div className="selectDates" data-testid="selectDates">
                    {keys(getAvailableSlotsWithCapacities(availableSlots)).map(
                      (date) => (
                        <Date
                          key={date}
                          date={date}
                          active={selectedDate === date}
                          holiday={isEmpty(
                            getAvailableSlotsWithCapacities(availableSlots)[
                              date
                            ]
                          )}
                          onSelect={(date) => {
                            send('SET_SELECTED_DATE', { date })
                          }}
                        />
                      )
                    )}
                  </div>
                </section>
                <div className="selectTime">
                  {locale('selectAppointmentTime')}
                </div>
                <div
                  className="lotsSelected"
                  style={{
                    ...(isCopartDelivery
                      ? { justifyContent: 'flex-start' }
                      : {})
                  }}
                >
                  <span>
                    {validatedPickupLots.length} {locale('lotsSelected')}
                  </span>
                  {isCopartDelivery ? (
                    <div className={'copartDeliveryIcon'}>
                      <img
                        src={CopartDeliveryLogo}
                        style={{
                          height: '17px',
                          width: '20px'
                        }}
                        alt="copartDelivery"
                      />
                    </div>
                  ) : (
                    <>
                      <Icon name="arrow right" />
                      <span>
                        {validatedPickupLots.length} {locale('spotsRequired')}
                      </span>
                    </>
                  )}
                </div>
                <div className="slots" data-testid="slots">
                  <Slots
                    slots={
                      getAvailableSlotsWithCapacities(availableSlots)[
                        selectedDate
                      ]
                    }
                    slotSelected={selectedScheduleId}
                    onSlotSelect={(id) => {
                      send('SET_SELECTED_SLOT', { id })
                    }}
                    numberOfLots={validatedPickupLots.length}
                    isCopartDelivery={isCopartDelivery}
                  />
                </div>

                {state.hasTag('lotBillUnpaid') && (
                  <LotsDueAmount
                    joinOfficeQueue={() => send('JOIN_OFFICE_QUEUE')}
                    close={() => {
                      send('CLOSE')
                    }}
                    loading={
                      state.hasTag('lotBillUnpaid') &&
                      state.hasTag('joiningOfficeQueue')
                    }
                    dueLots={context.validatedPickupLots.filter(
                      (lot) => lot.billPaid === 'N'
                    )}
                  />
                )}
              </div>
            </div>
            <div
              style={{
                position: 'fixed',
                bottom: '20px',
                width: '90%',
                left: '5%'
              }}
            >
              {state.hasTag('error') && context.error && (
                <ErrorMessage message={context.error} data-testid="error" />
              )}
              <Button
                fluid
                onClick={() => send('CONFIRM_APPOINTMENT')}
                style={{
                  fontSize: '16px',
                  backgroundColor: '#7dc033',
                  color: '#ffffff'
                }}
                loading={state.hasTag('confirmingAppointment')}
                data-testid="confirmAppointment"
                disabled={!state.nextEvents.includes('CONFIRM_APPOINTMENT')}
              >
                {locale('confirmAppointment')}
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default ScheduleAppointment
