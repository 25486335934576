import React from 'react'
import BrowserRouter from 'react-router-dom/BrowserRouter'

import { LanguageContextProvider } from './context/LanguageContext'
import Routes from './pages/Routes'
// import './mocks/index.js'

// import { inspect } from '@xstate/inspect'

// inspect({ iframe: false })

const Router = () => {
  return (
    <BrowserRouter>
      <LanguageContextProvider>
        <Routes />
      </LanguageContextProvider>
    </BrowserRouter>
  )
}

export default Router
