//eslint-disable-next-line
import React from 'react'
import locale from '../utils/locale'
import { v4 as uuid } from 'uuid'

const STACK = process.env.REACT_APP_STACK || 'c'

export const GOOGLE_API_KEY = 'AIzaSyCWbn6Rezs3q5J6ykM215FTfPR5wCVSXhI'

export const getStatusLabelProperties = (status) =>
  ({
    Open: { label: locale('Active'), color: 'green' },
    Processing: { label: locale('Processing'), color: 'green' },
    Cancelled: { label: locale('Cancelled'), color: 'red' },
    Closed: { label: locale('Closed'), color: 'red' },
    Completed: { label: locale('Completed'), color: 'green' }
  }[status] || { label: status, color: 'green' })

export const countryMapper = {
  us: 'USA',
  ca: 'CAN',
  uk: 'GBR',
  ie: 'IRL',
  ar: 'ARE',
  bh: 'BHR',
  om: 'OMN'
}

const getLanguageOptionsUS = () => [
  { key: 'en', text: locale('English'), value: 'en' },
  { key: 'es', text: locale('Spanish'), value: 'es' },
  { key: 'fr', text: locale('French'), value: 'fr' },
  { key: 'ru', text: locale('Russian'), value: 'ru' }
]

const getLanguageOptionsUK = (countryCode) =>
  ['gb', 'ie'].includes(countryCode)
    ? [{ key: 'en-uk', text: locale('English'), value: 'en-uk' }]
    : [
        { key: 'en-uk', text: locale('English'), value: 'en-uk' },
        { key: 'ar', text: locale('Arabic'), value: 'ar' }
      ]

export const getLanguageOptions = (countryCode) =>
  ({
    c: getLanguageOptionsUS(),
    uk: getLanguageOptionsUK(countryCode)
  }[STACK])

const countryOptionsUK = (Component) => [
  {
    key: 'gb',
    text: (
      <Component
        countryCode="gb"
        style={{ height: '22px', width: '28px' }}
        text="GBR"
      />
    ),
    value: 'gb'
  },
  {
    key: 'ie',
    text: (
      <Component
        countryCode="ie"
        style={{ height: '24px', width: '28px' }}
        text="IRE"
      />
    ),
    value: 'ie'
  },
  {
    key: 'ar',
    text: (
      <Component
        countryCode="ar"
        style={{ height: '24px', width: '28px' }}
        text="UAE"
      />
    ),
    value: 'ar'
  },
  {
    key: 'om',
    text: (
      <Component
        countryCode="om"
        style={{ height: '24px', width: '28px' }}
        text="OMN"
      />
    ),
    value: 'om'
  },
  {
    key: 'bh',
    text: (
      <Component
        countryCode="bh"
        style={{ height: '24px', width: '28px' }}
        text="BHR"
      />
    ),
    value: 'bh'
  }
]

const countryOptionsUS = (Component) => [
  {
    key: 'us',
    text: (
      <Component
        countryCode="us"
        style={{ height: '22px', width: '28px' }}
        text="USA"
      />
    ),
    value: 'us'
  },
  {
    key: 'ca',
    text: (
      <Component
        countryCode="ca"
        style={{ height: '22px', width: '28px' }}
        text="CAN"
      />
    ),
    value: 'ca'
  }
]

export const getCountryOptions = (Component) =>
  ({
    c: countryOptionsUS(Component),
    uk: countryOptionsUK(Component)
  }[STACK])

export const ONLY_COUNTRIES = {
  c: ['ca', 'mx', 'us'],
  uk: ['gb', 'ie', 'bh', 'om', 'ae']
}[STACK]

export const DATE_RANGE = 7

export const APPOINTMENT_READY_TIME = 30 // IN MINUTES

export const MARKARRIVE_READY_TIME = 2 //IN HOURS

export const DIAL_CODE_MAPPER = {
  ca: '1',
  mx: '52',
  us: '1',
  gb: '44',
  ie: '353',
  bh: '973',
  om: '968',
  ar: '971'
}
export const GEO_FENCING_PILOT_YARDS = [
  4, 5, 7, 8, 10, 12, 26, 28, 29, 43, 45, 59, 62, 73, 74, 76, 85, 95, 97, 98,
  111, 112, 127, 128, 129, 142, 164, 166, 174, 180, 181, 182, 185, 186, 201,
  202, 203, 204, 299, 309, 313, 367, 376
]

export const APPOINTMENT_STATUSES = {
  PENDING: 'PENDING',
  ARRIVED: 'ARRIVED',
  PENDING_DROP_OFF: 'PENDING DROP OFF',
  CANCELLED: 'CANCELLED',
  RESCHEDULED: 'RESCHEDULED',
  CONFIRMED: 'CONFIRMED',
  COMPLETED: 'COMPLETED'
}

export const TIMEZONE_MAP = {
  // US TIME ZONES
  PST: 'America/Los_Angeles',
  PDT: 'America/Los_Angeles',
  EST: 'America/New_York',
  EDT: 'America/New_York',
  CST: 'America/Chicago',
  CDT: 'America/Chicago',
  MST: 'America/Phoenix',
  MDT: 'America/Denver',
  AKST: 'US/Alaska',
  AKDT: 'America/Anchorage',
  AKST: 'America/Anchorage',
  ADT: 'US/Alaska',
  HDT: 'America/Adak',
  HST: 'America/Adak',
  HADT: 'America/Adak',
  HAST: 'America/Adak',
  NDT: 'America/St_Johns',
  NST: 'America/St_Johns',
  AST: 'America/Blanc-Sablon',
  // UK TIME ZONES
  BST: 'Europe/Belfast',
  GMT: 'Europe/Belfast',
  BS: 'Europe/Belfast',
  GM: 'Europe/Belfast'
}

export const CORRELATION_ID = uuid()

export const towProviderFlowIncludedReasons = ['PKVEH']
