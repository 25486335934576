import React, { useState, useEffect, useContext } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { pathOr } from 'ramda'

import Header from '../components/Header'
import ReferenceData from './ReferenceData'
import { initializeGA, sendPageView } from '../utils/sendAnalytics'
import Main from './Main'
import QueueStatus from './Summary'
import FacilityForm from './FacilityForm'
import AppointmentSummary from './AppointmentSummary'
import { LanguageContext } from '../context/LanguageContext'
import { skipGeoFencing } from '../utils'
import { setPropertyInLocalStorage } from '../utils/storage'
import './style.css'

const Routes = (props) => {
  const [countryCode, setCountryCode] = useState()
  const [userTypes, setUserTypes] = useState([])
  const [reasons, setReasons] = useState([])
  const [truckTypes, setTruckTypes] = useState([])
  const [states, setStatesData] = useState([])
  const [facilities, setFacilities] = useState([])
  const [mapData, setMapData] = useState({})
  const [config, setKioskConfig] = useState({})
  const [windowHeight, setWindowInnerHeight] = useState(window.innerHeight)
  const [language, setLanguage] = useContext(LanguageContext)

  const reportWindowSize = (e) => setWindowInnerHeight(window.innerHeight)

  const route = window.location.pathname
  useEffect(() => {
    initializeGA()
    window.addEventListener('resize', reportWindowSize)
    if (skipGeoFencing()) {
      setPropertyInLocalStorage('skipgeofence', true)
    }
    return () => {
      window.removeEventListener('resize')
    }
  }, [])

  useEffect(() => {
    if (route.includes('home') || route === '/') {
      props.history.push(`/${language}/home`)
      const title =
        route === `/${language}/self-serve` ? 'Home page' : 'Summary Page'
      sendPageView(route, 'sendToDefaultTracker', title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route])

  const commonProps = {
    config,
    reasons,
    states,
    facilities,
    userTypes,
    countryCode,
    latitude: pathOr('', ['coords', 'latitude'], props),
    longitude: pathOr('', ['coords', 'longitude'], props),
    geoLocationEnabled: pathOr(false, ['isGeolocationEnabled'], props),
    positionError: pathOr({}, ['positionError'], props),
    mapData,
    setMapData,
    truckTypes,
    language,
    setLanguage,
    location: props?.location
  }

  const referenceDataProps = {
    countryCode,
    setReasons,
    setFacilities,
    setMapData,
    setUserTypes,
    setStatesData,
    setCountryCode,
    setKioskConfig,
    setTruckTypes
  }

  return (
    <div className="main-container overlay" style={{ height: windowHeight }}>
      <Header countryCode={countryCode} {...commonProps} />
      <Switch>
        <Route
          exact
          path="/:language/settings"
          render={(routeProps) => (
            <ReferenceData {...routeProps} {...referenceDataProps}>
              <FacilityForm
                {...routeProps}
                {...commonProps}
                reasons={reasons}
                states={states}
                facilities={facilities}
                userTypes={userTypes}
              />
            </ReferenceData>
          )}
        />
        <Route
          path="/:language/self-serve/:requestId"
          render={(routeProps) => (
            <ReferenceData {...routeProps} {...referenceDataProps}>
              <QueueStatus
                {...routeProps}
                {...commonProps}
                reasons={reasons}
                states={states}
                facilities={facilities}
                userTypes={userTypes}
              />
            </ReferenceData>
          )}
        />
        <Route
          path="/:language/appointment/:appointmentId"
          render={(routeProps) => (
            <AppointmentSummary
              {...routeProps}
              {...commonProps}
              reasons={reasons}
              states={states}
              facilities={facilities}
              userTypes={userTypes}
            />
          )}
        />
        <Route
          exact
          path="/:language/home"
          render={(routeProps) => (
            <ReferenceData {...routeProps} {...referenceDataProps}>
              <Main
                {...routeProps}
                {...commonProps}
                reasons={reasons}
                states={states}
                facilities={facilities}
                userTypes={userTypes}
              />
            </ReferenceData>
          )}
        />
        <Route path="*">
          <Redirect to="/:language/home" />
        </Route>
      </Switch>
    </div>
  )
}

export default withRouter(Routes)
