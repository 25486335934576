export const isAppointmentStatusArrived = (ctx) => {
  return ctx.appointmentStatus === 'ARRIVED'
}

export const hasLatitudeAndLongitude = (ctx) => {
  return ctx.latitude && ctx.longitude
}

export const isStatusOK = (ctx, event) => {
  return event.data?.status === 200
}
