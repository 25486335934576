import React from 'react'
import { Modal } from 'semantic-ui-react'

const Popup = (props) => {
  const { height = '50%', fontFamily = '', className = '' } = props
  return (
    <Modal
      open
      style={{ height: height, fontFamily: fontFamily }}
      className={`Popup ${className}`}
    >
      {props.children}
    </Modal>
  )
}

export default Popup
