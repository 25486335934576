import React from 'react'
import locale from '../utils/locale'

const Label = ({ labelText }) => {
  return (
    <>
      <label className="label-text">{locale(labelText)}</label>
      <span className="label-required">*</span>
    </>
  )
}

export default Label
