import React, { useEffect } from 'react'
import ReactTimeAgo from 'react-time-ago'
import { Button, Icon } from 'semantic-ui-react'
import { sendEventGA } from '../utils/sendAnalytics'

import locale from '../utils/locale'

const handleRefreshETA = (onUpdate, category, action) => {
  sendEventGA({
    category,
    action,
  })
  onUpdate()
}

export default function LastSeen({ date, onUpdate }) {
  useEffect(() => {
    const refreshId = setInterval(
      () => handleRefreshETA(onUpdate, 'App', 'Auto refreshed ETA'),
      60000
    )
    return () => clearInterval(refreshId)
    // eslint-disable-next-line
  }, [])

  return (
    <div className='LastUpdateTime'>
      <span className='LastUpdateTimeText'>{locale('lastUpdateTime')}</span>
      <ReactTimeAgo date={date} locale='en' />
      <span className='LastUpdateTimeText' />
      <Button
        size='mini'
        onClick={() => handleRefreshETA(onUpdate, 'User', 'Refreshed ETA')}
        primary
      >
        <span className='LastUpdatedButton'></span>
        <Icon name='refresh' />
      </Button>
    </div>
  )
}
