import { Machine, send } from 'xstate'
import { sendEventGA } from '../../../utils/sendAnalytics'
import { stampCustomLog } from '../../../utils/logs'

const canShowJoinOfficeQueue = {
  fetchingYardCoordinates: {
    tags: ['fetchingYardCoordinates'],
    id: 'fetchingYardCoordinates',
    always: [
      {
        cond: 'isNotWithinTimeRange',
        target: 'hideJoinOfficeQueue'
      },
      {
        cond: 'isLocationShared',
        target: 'locationValidation'
      },
      {
        target: 'hideJoinOfficeQueue'
      }
    ]
  },
  locationValidation: {
    invoke: {
      id: 'fetchYardCoordinates',
      src: 'fetchYardCoordinates',
      onDone: [
        {
          cond: 'isLocationNotMatching',
          target: 'hideJoinOfficeQueue'
        },
        {
          target: 'showJoinOfficeQueue'
        }
      ]
    }
  },
  showJoinOfficeQueue: {
    tags: ['showJoinOfficeQueue'],
    initial: 'idle',
    id: 'showJoinOfficeQueue',
    on: {
      JOIN_OFFICE_QUEUE: {
        target: '.joinOfficeQueuePopup'
      },
      CANCEL_APPOINTMENT: {
        target: '.cancelAppointment'
      }
    },
    states: {
      idle: {},
      error: {
        tags: ['showJoinOfficeQueueError']
      },
      joinOfficeQueuePopup: {
        tags: ['joinOfficeQueuePopup'],
        initial: 'idle',
        on: {
          CLOSE: {
            target: 'idle'
          },
          CONTINUE: {
            target: '.joiningOfficeQueue'
          },
          PICK_REASONS: {
            actions: 'setReasons'
          }
        },
        states: {
          idle: {},
          joiningOfficeQueue: {
            tags: 'joiningOfficeQueue',
            invoke: {
              id: 'joinQueue',
              src: 'joinQueue',
              onDone: {
                actions: [
                  'setVirtualQueueId',
                  send((ctx) => ({
                    type: 'NAVIGATE_TO',
                    url: `/${ctx.language}/self-serve/${ctx.requestId}`
                  })),
                  (ctx) => {
                    stampCustomLog({
                      currentState: 'kioskSummary',
                      event: 'JOIN_OFFICE_QUEUE',
                      facilityId: ctx?.appointmentData?.facilityId
                    })
                  }
                ],
                target: 'idle'
              },
              onError: {
                actions: ['setServerError'],
                target: 'error'
              }
            }
          },
          error: {
            tags: ['joinOfficeQueuePopupError']
          }
        }
      },
      cancelAppointment: {
        initial: 'idle',
        on: {
          CONFIRM: {
            target: '.cancellingAppointment'
          },
          CLOSE: {
            target: 'idle'
          }
        },
        states: {
          idle: {
            tags: ['cancelAppointmentIdle']
          },
          cancellingAppointment: {
            tags: ['cancellingAppointment'],
            invoke: {
              id: 'cancelAppointment',
              src: 'cancelAppointment',
              onDone: {
                actions: [],
                target: 'success'
              },
              onError: {
                target: '#showJoinOfficeQueue.error',
                actions: 'setServerError'
              }
            }
          },
          success: {
            tags: ['cancelAppointmentSuccess'],
            on: {
              CONTINUE: {
                actions: [
                  send((ctx) => ({
                    type: 'NAVIGATE_TO',
                    url: `/${ctx.language}/home`
                  }))
                ]
              }
            }
          }
        }
      }
    }
  },
  hideJoinOfficeQueue: {
    id: 'hideJoinOfficeQueue',
    initial: 'idle',
    on: {
      CANCEL_APPOINTMENT: {
        target: '.cancelAppointment'
      }
    },
    states: {
      idle: {},
      error: {
        tags: ['showJoinOfficeQueueError']
      },
      cancelAppointment: {
        initial: 'idle',
        on: {
          CONFIRM: {
            target: '.cancellingAppointment'
          },
          CLOSE: {
            target: 'idle'
          }
        },
        states: {
          idle: {
            tags: ['cancelAppointmentIdle']
          },
          cancellingAppointment: {
            tags: ['cancellingAppointment'],
            invoke: {
              id: 'cancelAppointment',
              src: 'cancelAppointment',
              onDone: {
                actions: [],
                target: 'success'
              },
              onError: {
                target: '#hideJoinOfficeQueue.error',
                actions: 'setServerError'
              }
            }
          },
          success: {
            tags: ['cancelAppointmentSuccess'],
            on: {
              CONTINUE: {
                actions: [
                  send((ctx) => ({
                    type: 'NAVIGATE_TO',
                    url: `/${ctx.language}/home`
                  }))
                ]
              }
            }
          }
        }
      }
    }
  }
}

const machine = Machine({
  id: 'appointmentSummary',
  context: {},
  initial: 'unknown',
  on: {
    NAVIGATE_TO: {
      actions: 'pushToHistory'
    },
    SEND_EVENT_GA: {
      actions: (ctx, event) =>
        sendEventGA({
          category: event.category,
          action: event.action
        })
    },
    SET_LATITUDE_AND_LONGITUDE: {
      actions: [
        send('SEND_EVENT_GA', {
          category: 'User',
          action: 'Allowed location sharing'
        }),
        'setLatitudeAndLongitude',
        'sendLatitideLongitudeToMarkAsArrivedMachine'
      ]
    },
    UPDATE_FACILITY_DETAILS: {
      actions: ['setClosestYard']
    },
    GO_BACK_TO_HOME: {
      actions: [
        send((ctx) => ({
          type: 'NAVIGATE_TO',
          url: `/`
        }))
      ]
    }
  },
  states: {
    unknown: {
      always: [
        {
          target: 'phoneNumberPresent',
          cond: 'hasPhoneNumber',
          actions: 'setPhoneNumberFromStorageToCtx'
        },
        {
          target: 'phoneNumberAbsent'
        }
      ]
    },
    phoneNumberPresent: {
      type: 'parallel',
      states: {
        main: {
          initial: 'fetchingAppointmentDetails',
          states: {
            fetchingAppointmentDetails: {
              invoke: {
                id: 'fetchAppointmentDetailsService',
                src: 'fetchAppointmentDetailsService',
                onDone: {
                  actions: ['setAppointmentData', 'mapAppointmentData'],
                  target: 'fetchPickupSublotInfo'
                },
                onError: {
                  target: 'appointmentsDetailsError',
                  actions: 'setServerError'
                }
              }
            },
            fetchPickupSublotInfo: {
              invoke: {
                id: 'fetchPickupSublotInfoService',
                src: 'fetchPickupSublotInfoService',
                onDone: {
                  actions: ['setPickupSublotInfoToCtx', 'mapAppointmentData'],
                  target: 'appointmentsDetailsloaded'
                },
                onError: {
                  actions: ['defaultPickupSublotInfoToCtx'],
                  target: 'appointmentsDetailsloaded'
                }
              }
            },
            appointmentsDetailsloaded: {
              id: 'appointmentsDetailsloaded',
              initial: 'checkingForAppointmentStatus',
              on: {
                TOGGLE_SUMMARY: {
                  actions: 'toggleSummary'
                }
              },
              states: {
                checkingForAppointmentStatus: {
                  always: [
                    {
                      cond: 'isAppointmentCancelled',
                      target: 'cancelledAppointment'
                    },
                    {
                      cond: 'isAppointmentCompleted',
                      target: 'completedAppointment'
                    },
                    {
                      target: 'activeAppointment'
                    }
                  ]
                },
                activeAppointment: {
                  tags: ['activeAppointment'],
                  entry: ['spawnMarkAsArrivedMachine', 'scrollToView'],
                  exit: 'stopSpawnedMarkAsArrivedMachine',
                  type: 'parallel',
                  states: {
                    main: {
                      initial: 'checkingAppointmentDate',
                      states: {
                        checkingAppointmentDate: {
                          always: [
                            {
                              cond: 'isAppointmentScheduledForPast',
                              target: 'pastDay'
                            },
                            {
                              cond: 'isAppointmentScheduledForToday',
                              target: 'today'
                            },
                            {
                              target: 'futureDay'
                            }
                          ]
                        },
                        pastDay: {
                          tags: ['pastDay']
                        },
                        today: {
                          initial: 'transient',
                          states: {
                            transient: {
                              always: [
                                {
                                  cond: 'hasAlreadyPassedAppointmentTime',
                                  target: 'past'
                                },
                                {
                                  target: 'immediate',
                                  cond: 'isWithin30MinsFromAppointment'
                                },
                                {
                                  target: 'later'
                                }
                              ]
                            },
                            past: {
                              tags: 'todayDatePast',
                              initial: 'fetchingYardCoordinates',
                              states: { ...canShowJoinOfficeQueue }
                            },
                            immediate: {
                              tags: 'todayDateImmediate',
                              initial: 'fetchingYardCoordinates',
                              states: { ...canShowJoinOfficeQueue }
                            },
                            later: {
                              tags: 'todayDateLater',
                              initial: 'fetchingYardCoordinates',
                              states: { ...canShowJoinOfficeQueue }
                            }
                          }
                        },
                        futureDay: {
                          tags: 'futureDay',
                          initial: 'hideJoinOfficeQueue',
                          states: { ...canShowJoinOfficeQueue }
                        }
                      }
                    },
                    background: {
                      on: {
                        REFRESH_SUMMARY_DATA: {
                          target: '.fetchingAppointmentDetails'
                        }
                      },
                      states: {
                        idle: {},
                        fetchingAppointmentDetails: {
                          invoke: {
                            id: 'fetchAppointmentDetailsService',
                            src: 'fetchAppointmentDetailsService',
                            onDone: {
                              actions: [
                                'setAppointmentData',
                                'mapAppointmentData',
                                'setLastSuccessfulFetchTime',
                                'setLatLongFromGeoLocation'
                              ],
                              target: [
                                '#appointmentsDetailsloaded.checkingForAppointmentStatus',
                                'idle'
                              ]
                            },
                            onError: {
                              target: 'idle'
                            }
                          }
                        }
                      }
                    }
                  }
                },
                cancelledAppointment: {
                  tags: ['cancelledAppointment']
                },
                completedAppointment: {
                  tags: ['completedAppointment']
                }
              }
            },
            appointmentsDetailsError: {
              always: {
                target: '#appointmentSummary.phoneNumberAbsent.error'
              }
            }
          }
        },
        fetchingLocationDetails: {
          invoke: [
            {
              id: 'fetchLocationDetailsService',
              src: 'fetchLocationDetailsService',
              onDone: {
                actions: ['setLocationToCtx', 'mapAppointmentData']
              },
              onError: {
                actions: 'setErrorLocationMessageToCtx'
              }
            }
          ]
        },
        fetchingLoaderBundle: {
          invoke: {
            id: 'fetchLoaderBundleService',
            src: 'fetchLoaderBundleService',
            onDone: {
              actions: ['setLoaderBundleToCtx', 'mapAppointmentData']
            },
            onError: {
              actions: 'setErrorLoaderBundleMessageToCtx'
            }
          }
        },
        fetchReasonsReferenceData: {
          invoke: {
            id: 'fetchReasonsReferenceData',
            src: 'fetchReasonsReferenceData',
            onDone: {
              actions: ['setReasonsReferenceDataToCtx', 'mapAppointmentData']
            },
            onError: {
              actions: 'setErrorReferenceDataMessageToCtx'
            }
          }
        }
      }
    },
    phoneNumberAbsent: {
      initial: 'idle',
      on: {
        ENTER_PHONE_NUMBER: {
          actions: 'setPhoneNumber',
          target: '.validating'
        },
        PARSE_AND_VALIDATE_PHONE_NUMBER: {
          actions: ['setParsedPhoneNumber'],
          target: '.validating'
        }
      },
      states: {
        idle: {},
        validating: {
          on: {
            '': [
              {
                cond: 'isPhoneNumberValid',
                target: 'errorFree'
              },
              {
                target: 'error'
              }
            ]
          }
        },
        error: {},
        errorFree: {
          on: {
            SUBMIT: {
              actions: ['setPhoneNumberInStorage', 'clearServerErrors'],
              target: '#appointmentSummary.phoneNumberPresent'
            }
          }
        }
      }
    }
  }
})

export default machine
