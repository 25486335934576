import React from 'react'
import { CopartLogo } from '../../components/Logo'
import LandingMessage from './LandingMessage'

const LandingBanner = () => (
  <>
    <div style={{ textAlign: 'center' }}>
      <CopartLogo width="150px" height="55px" />
    </div>
    <div style={{ color: 'white' }}>
      <LandingMessage />
    </div>
  </>
)

export default LandingBanner
