import React from 'react'
import { Label } from 'semantic-ui-react'

import locale from '../utils/locale'

export default function Banner(props) {
  return (
    <div className="Banner">
      <Label image>
        <span className="BannerItem">{locale('State')}</span>
        <span className="BannerItem">{`${props.stateCode},`}</span>
        <span className="BannerItem">{locale('Yard')}</span>
        <span className="BannerItem">{props.facilityId}</span>
      </Label>
    </div>
  )
}
