import React from 'react'
import locale from '../../utils/locale'

const LandingMessage = () => (
    <>
        <div className="LandingMessageForm">
            <div className="LandingMessageFormHeader">{locale('welcomeTo')}</div>
            <div className="LandingMessageFormText">{locale('copartSelfServeKiosk')}</div>
        </div>
    </>
)

export default LandingMessage