import React from 'react'
import { Button, Icon, Table, Divider } from 'semantic-ui-react'
import { isNil, isEmpty } from 'ramda'
import locale from '../../utils/locale'
import { getCountryCode } from '../../utils'
import Popup from '../../components/Popup'
import ErrorMessage from '../../components/ErrorMessage'

// lots invalid
const LotsInvalid = ({
  close,
  validatedPickupLots,
  joinOfficeQueue,
  loading
}) => {
  return (
    <Popup height="auto" className="scheduleAppointment lotsInvalidModal">
      <Icon
        name="close"
        onClick={close}
        style={{ fontSize: '20px' }}
        color="black"
      />
      <div className="lotsInvalidHeader">{locale('lotsStatusHeading')}</div>
      <Divider />
      <ErrorMessage
        message={locale('lotValidationPopupHelpText')}
        data-testid="error"
      />
      <Table unstackable size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="2" className="LotTableHeader">
              {getCountryCode() !== 'CAN'
                ? `${locale('lot')} / ${locale('gatePassPin')}`
                : locale('lot')}
            </Table.HeaderCell>
            <Table.HeaderCell className="LotTableHeader"></Table.HeaderCell>
            <Table.HeaderCell className="LotTableHeader">
              {locale('lotValidity')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {validatedPickupLots.map((lot, index) => (
            <Table.Row key={`detail_${index}`}>
              <Table.Cell className="SummaryTableCell" colSpan="2">
                {lot.lot_number}
              </Table.Cell>
              <Table.Cell></Table.Cell>
              {isNil(lot.errors) || isEmpty(lot.errors) ? (
                <Table.Cell
                  className="SummaryTableCell"
                  style={{ color: 'green' }}
                >
                  {locale('success')}
                </Table.Cell>
              ) : (
                <Table.Cell
                  className="SummaryTableCell"
                  style={{ color: '#9f3a38' }}
                >
                  {lot.errors.join(', ')}
                </Table.Cell>
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <div className="helpText">{locale('joinOfficeQueueHelpText')}</div>

      <div>
        <Button fluid onClick={joinOfficeQueue} loading={loading} primary>
          {locale('joinOfficeQueue')}
        </Button>
      </div>
    </Popup>
  )
}

export default LotsInvalid
