import React from 'react'
import { Dropdown, Button, Form, Icon } from 'semantic-ui-react'
import { propOr, isEmpty } from 'ramda'

import { parsePhoneNumberFromString } from 'libphonenumber-js'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/semantic-ui.css'
import { GoogleApiWrapper } from 'google-maps-react'
import { sendEventGA } from '../../utils/sendAnalytics'
import LocaledText, { getLocaledReasons } from '../../components/LocaledText'
import MapWithBounds from '../../components/MapWithBounds'
import LabelWithRequired from '../../components/Label'
import ErrorMessage from '../../components/ErrorMessage'
import CustomWarningMessage from '../../components/CustomWarningMessage'
import LandingBanner from '../SelfServeForm/LandingBanner'
import AppointmentFound from './AppointmentFound'
import { showMoneyOrderMessage } from '../../utils'

import locale from '../../utils/locale'
import { ONLY_COUNTRIES, GOOGLE_API_KEY } from '../../constants'
import { stateMatchingConditionCheck } from '../../utils'
import LocationDetails from '../FacilityView/LocationDetails'

import './style.css'

const STACK = process.env.REACT_APP_STACK || 'c'

const getUserTypeOptions = (userTypes) =>
  userTypes
    .filter((type) => type.order) // filter out user types with order as null
    .map((type) => ({
      ...type,
      text: <LocaledText text={type.text} />,
      content: <LocaledText text={type.text} />
    }))

const Landing = (props) => {
  const {
    state,
    send,
    userTypes,
    reasons,
    mapData,
    config,
    states,
    facilities,
    setMapData
  } = props

  const { context = {} } = state || {}
  const { form } = context
  const getStateOptions = () => {
    if (form.countryCode !== 'ca') return states
    const canadianStatesToSkip = propOr([], 'skip_canadian_states', config)
    return isEmpty(canadianStatesToSkip)
      ? states
      : states.filter(({ value = '' }) => !canadianStatesToSkip.includes(value))
  }
  const markers = [
    {
      lat: form?.inputs?.latitude,
      lng: form?.inputs?.longitude
    },
    {
      lat: context?.latitude,
      lng: context?.longitude
    }
  ]

  const googleMaps = props.google.maps

  return (
    <>
      <LandingBanner />
      <div className="selfserve-form-container">
        <LocationDetails
          getStateOptions={getStateOptions}
          facilities={facilities}
          stateCode={form.inputs.stateCode}
          setStateCode={(value) => {
            send('STATE_CODE_CHANGE', { name: 'stateCode', value })
          }}
          states={states}
          facilityId={form.inputs.facilityId}
          sendEventGA={sendEventGA}
          setFacilityId={(value) => {
            send('FACILITY_CHANGE', {
              name: 'facilityId',
              facilityId: value
            })
            send('USERTYPE_BY_PHONE_NUMBER', {
              parsedPhone:
                form.inputs.phoneNumber && form?.inputs?.parsedPhone?.isValid,
              userTypes,
              reasons
            })
          }}
          stateMapViewData={
            STACK === 'uk' ? mapData : mapData[form.inputs.stateCode] || {}
          }
          countryCode={form.countryCode}
          setMapData={setMapData}
          fetchYardClosedInProgress={stateMatchingConditionCheck(
            state?.context?.yardDetailsMachineRef?.state,
            ['fetchingYardDetails', 'fetchingYardStatus']
          )}
        />
        {context.form?.yardClosedMessage && (
          <ErrorMessage
            message={locale(context.form?.yardClosedMessage)}
            style={{ display: 'flex', alignItems: 'center' }}
          />
        )}
        {!context.form?.yardClosedMessage &&
          state.hasTag('locationNotSharedError') && (
            <ErrorMessage
              message={locale('locationNotSharedForQueue')}
              style={{ display: 'flex', alignItems: 'center' }}
            />
          )}
        {!context.form?.yardClosedMessage &&
          state.hasTag('locationNotMatchingError') && (
            <div>
              <ErrorMessage
                style={{ display: 'flex' }}
                message={
                  <div>
                    <div> {locale('locationNotMatchingForQueue')} </div>
                    <div>
                      <MapWithBounds
                        isMarkerShown
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places,geometry,drawing`}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={
                          <div style={{ height: `250px`, margin: '10px 0' }} />
                        }
                        mapElement={<div style={{ height: `100%` }} />}
                        markers={markers}
                        googleMaps={googleMaps}
                      />
                    </div>
                  </div>
                }
              />
            </div>
          )}
        <Form className="attached fluid selfServeForm">
          <Form.Group widths="equal">
            <Form.Field className="field-section">
              <LabelWithRequired labelText="enterPhone" />
              <div data-testid="enterPhone">
                <PhoneInput
                  countryCodeEditable={false}
                  onlyCountries={ONLY_COUNTRIES}
                  placeholder={locale('enterPhone')}
                  inputProps={{
                    required: true
                  }}
                  onKeyDown={(e) => {
                    if (
                      form.countryCode === 'us' &&
                      [8, 46].includes(e.which) &&
                      form.inputs.phoneNumber?.length <= 1
                    )
                      e.preventDefault()
                  }}
                  country={form.countryCode === 'ar' ? 'ae' : form.countryCode} // react-phone-input supports ae as country code for UAE
                  value={form.inputs.phoneNumber}
                  onChange={(phone = '', country, e, formattedValue) => {
                    send('ENTER_PHONE_NUMBER', { phone })
                    const parsedPhone =
                      formattedValue &&
                      parsePhoneNumberFromString(formattedValue)
                    send('PARSE_AND_VALIDATE_PHONE_NUMBER', {
                      parsedPhone,
                      country
                    })
                    if (phone?.length === 11 || phone?.length === 12) {
                      send('USERTYPE_BY_PHONE_NUMBER', {
                        parsedPhone: parsedPhone && parsedPhone?.isValid(),
                        userTypes,
                        reasons
                      })
                    }
                  }}
                  onBlur={(e, countryData) => {
                    const phone = e.target.value
                    const parsedPhone =
                      phone && parsePhoneNumberFromString(phone)
                    send('PARSE_AND_VALIDATE_PHONE_NUMBER', {
                      parsedPhone,
                      countryData
                    })
                  }}
                />
              </div>

              {STACK === 'c' && !form?.inputs?.parsedPhone?.isValid && (
                <div>
                  <Icon name="warning" color="red" />
                  <span className="subtext-label">
                    {locale('phoneNumberInvalidWarning')}
                  </span>
                </div>
              )}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <LabelWithRequired labelText="selectUserType" />
              <Dropdown
                fluid
                clearable
                placeholder={locale('selectUserType')}
                selection
                className="userTypeField"
                options={getUserTypeOptions(userTypes)}
                value={form.inputs.userType}
                onChange={(e, { value }) => {
                  send('PICK_USER_TYPE', { value })
                  send('SET_REASONS_OPTIONS', { userTypes, reasons })
                  send('PICK_REASONS', { value: [] })
                }}
                data-testid="selectUserType"
              />
            </Form.Field>
          </Form.Group>
          {showMoneyOrderMessage() &&
            form.countryCode === 'us' &&
            form.inputs.reasonCodes?.includes('MKPAY') && (
              <div className="landingCustomWarningContainer">
                <CustomWarningMessage
                  message={locale('paymentMethodBannerMessage')}
                />
              </div>
            )}
          <Form.Group widths="equal">
            <Form.Field>
              <LabelWithRequired labelText="selectReasons" />
              <Dropdown
                selection
                clearable
                placeholder={locale('selectReasons')}
                multiple
                value={form.inputs.reasonCodes || []}
                onChange={(e, { value }) => {
                  send('PICK_REASONS', { value })
                }}
                renderLabel={({ content }) => ({ content })}
                options={getLocaledReasons(form.reasonOptions || [])}
                data-testid="selectReasons"
              />
            </Form.Field>
          </Form.Group>
        </Form>
        {state.hasTag('error') && context.error && (
          <ErrorMessage message={context.error} data-testid="error" />
        )}
        <Button
          fluid
          disabled={!state.nextEvents.includes('CONTINUE')}
          onClick={() => {
            send('CONTINUE')
          }}
          loading={stateMatchingConditionCheck(state, [
            'towProvider.checkingForAppointment'
          ])}
          primary
          data-testid="continue"
        >
          {locale('Continue')}
        </Button>
      </div>
      {stateMatchingConditionCheck(state, ['towProvider.appointmentFound']) && (
        <AppointmentFound state={state} send={send} mapData={mapData} />
      )}
    </>
  )
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY
})(Landing)
