import axios from 'axios'
import { getPropertyFromLocalStorage } from './storage'
import { getCountryCode } from './index'
import { CORRELATION_ID } from '../constants'

function getSource() {
  if (getPropertyFromLocalStorage('facilityId')) return 'Kiosk'
  return 'Mobile'
}

const commonHeaders = {
  source: getSource(),
  countrycode: getCountryCode(),
  'content-type': 'application/json',
  uiId: CORRELATION_ID
}

const fetcher = {
  get: (url, headers = {}, params) =>
    axios.get(url, {
      headers: { ...commonHeaders, ...headers },
      params
    }),
  post: (url, payload, headers = {}) =>
    axios.post(url, payload, { headers: { ...commonHeaders, ...headers } }),
  put: (url, payload, headers = {}) =>
    axios.put(url, payload, { headers: { ...commonHeaders, ...headers } }),
  delete: (url, payload, headers = {}) =>
    axios.delete(url, {
      headers: { ...commonHeaders, ...headers },
      data: payload
    })
}

export default fetcher
