import React, { useCallback, useEffect, useState } from 'react'
import { useMachine } from '@xstate/react'
import machine from '../machines/SelfServeKiosk'

import './style.css'
import './SelfServeForm/style.css'

import Landing from './Landing'

import SelfServeForm from './SelfServeForm'
import {
  stateMatchingConditionCheck,
  getNavigatorGeolocation,
  useLogging,
  getYardFromUrl,
  getYardFromStorage
} from '../utils'
import { stampCustomLog } from '../utils/logs'
import { isBlank } from '../utils/isBlank'

const showLandingPage = (state) => {
  return stateMatchingConditionCheck(state, [
    'landing',
    'towProvider.checkingForAppointment',
    'towProvider.appointmentFound'
  ])
}

const showForm = (state) => {
  return stateMatchingConditionCheck(state, [
    'towProvider.selfServeForm',
    'nonTowProvider'
  ])
}

const Main = (props) => {
  const [yardFromUrl, setYardFromUrl] = useState('')

  const pushToHistory = (ctx, event) => {
    props.history.push(event.url)
  }

  const [state, send, service] = useMachine(machine(pushToHistory), {
    devTools: true
  })

  useLogging(service)

  const handleLocationError = (err) => {
    send('SEND_EVENT_GA', {
      category: 'User',
      action: 'Blocked location sharing'
    })
    stampCustomLog({
      event: 'locationBlocked',
      currentState: 'locationDisabled'
    })
    console.warn('Location error: Blocked location sharing')
    if (err.code === 1) {
      console.warn('Error: Access is denied!')
    } else if (err.code === 2) {
      console.warn('Error: Position is unavailable!')
    }
  }

  const handleLocationSharingEnabled = useCallback(
    (position) => {
      console.log('Latitude is :', position.coords.latitude)
      console.log('Longitude is :', position.coords.longitude)
      send({
        type: 'SET_LATITUDE_AND_LONGITUDE',
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        facilities: props?.facilities,
        yardFromUrl: yardFromUrl
      })
    },
    [props, yardFromUrl]
  )

  useEffect(() => {
    if (!isBlank(getYardFromUrl())) {
      setYardFromUrl(getYardFromUrl())
      sessionStorage.setItem('yardFromUrl', getYardFromUrl())
    }
    if (!isBlank(props?.facilities)) {
      getNavigatorGeolocation(
        handleLocationSharingEnabled,
        handleLocationError,
        {
          timeout: 10000
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  useEffect(() => {
    send({
      type: 'SET_LANGUAGE',
      language: props?.language
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  return (
    <>
      {showLandingPage(state) && (
        <Landing {...props} state={state} send={send} />
      )}
      {showForm(state) && (
        <SelfServeForm {...props} state={state} send={send} />
      )}
    </>
  )
}

export default Main
