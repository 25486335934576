import { assign } from '@xstate/immer'
import { parseErrorMessage } from '../../utils'
import { spawn, send, assign as xAssign } from 'xstate'
import markAsArrivedMachine from '../markAsArrived'

export const setServerError = assign((ctx, event) => {
  ctx.error = parseErrorMessage(event?.data?.response)
})

export const clearError = assign((ctx, event) => {
  ctx.error = ''
})

export const setLatitudeAndLongitude = assign((ctx, event) => {
  ctx.latitude = event.latitude
  ctx.longitude = event.longitude
})

export const setClosestYardDetails = assign((ctx, event) => {
  ctx.closestYard = event.closestYard
})

export const sendLatitudeAndLongitudeToMarkAsArrivedMachine = send(
  (ctx) => ({
    type: 'LOCATION_DETAILS_CHANGE',
    data: {
      latitude: ctx.latitude,
      longitude: ctx.longitude
    }
  }),
  {
    to: (ctx) => ctx.markAsArrivedMachineRef
  }
)

export const spawnMarkAsArrivedMachine = xAssign((ctx) => {
  const markAsArrivedMachineRef = spawn(
    markAsArrivedMachine.withContext({
      latitude: ctx.latitude,
      longitude: ctx.longitude,
      closestYard: ctx.closestYard,
      appointmentDate: ctx.startTime,
      lots: ctx.lots,
      appointmentStatus: ctx.appointmentStatus,
      appointmentHeaderId: ctx.appointmentHeaderId,
      transporterId: ctx.details.transporterId,
      facilityId: ctx.facilityId,
      countryCode: ctx.countryCode,
      timeZone: ctx.timeZone
    }),
    {
      devTools: true
    }
  )
  return { ...ctx, markAsArrivedMachineRef }
})

export const stopSpawnedMarkAsArrivedMachine = xAssign((ctx) => {
  ctx.markAsArrivedMachineRef.stop()
})

export const setResponseError = assign((ctx, event) => {
  ctx.error =
    event.data?.data?.data?.message || 'Unexpected error, please try again'
})
