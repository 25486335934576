import React from 'react'
import { keys, propOr, path, pathOr } from 'ramda'
import { Label, Table, Icon } from 'semantic-ui-react'
import { AsYouType } from 'libphonenumber-js'
import moment from 'moment'
import renderIf from 'render-if'

import { getReferenceDataDescription, formatAddress } from '../../utils'
import locale from '../../utils/locale'
import { getStatusLabelProperties } from '../../constants'
import LocaledText from '../../components/LocaledText'
import CopartDeliveryLogo from '../../components/CopartDeliveryLogo/CopartDeliveryLogo.png'

import './style.css'

type PropsT = {}

const getReqSubmittedDateTimeFormat = (countryCode) =>
  ['USA', 'CAN'].includes(countryCode)
    ? 'MM/DD/YYYY hh:mm A'
    : 'DD/MM/YYYY hh:mm A'

const tableConfig = {
  name: {
    key: 'first_name',
    headerText: () => locale('name'),
    render: (data) => `${data.first_name || ''} ${data.last_name || ''}`
  },
  userType: {
    key: 'user_type_cd',
    headerText: () => locale('userType'),
    render: ({ user_type_cd: userTypeCode }, { userTypes }) => (
      <LocaledText
        text={getReferenceDataDescription(userTypeCode, userTypes)}
      />
    )
  },
  phone: {
    key: 'phone_number',
    headerText: () => locale('phone'),
    render: ({ phone_number: phoneNumber }) =>
      phoneNumber && new AsYouType().input(`+${phoneNumber}`)
  },
  copartLocation: {
    key: 'facility_id',
    headerText: () => locale('copartLocation'),
    render: (
      {
        facility_id: facilityId,
        address = {},
        facility_type_code: facilityType,
        facility_name: facilityName = ''
      },
      { facilities }
    ) => {
      const facilitySelected = getReferenceDataDescription(
        facilityId,
        facilities
      )
      sessionStorage.setItem('yardFromUrl', facilityId)
      const {
        address_line_1 = '',
        address_line_2 = '',
        state_code = '',
        city = '',
        postal_code = ''
      } = address

      const { addressSec1 = '', addressSec2 = '' } = formatAddress({
        yardStateCode: state_code,
        addressLine1: address_line_1,
        addressLine2: address_line_2,
        city,
        zip: postal_code
      })

      const displayYardName =
        facilityType === 'S' ? facilityName : facilitySelected
      return (
        <>
          <div>{displayYardName}</div>
          <div>{addressSec1}</div>
          <div>{addressSec2}</div>
        </>
      )
    }
  },
  requestStatus: {
    key: 'request_status',
    headerText: () => locale('requestStatus'),
    render: ({ request_status: status }) => {
      const { color, label: statusLabel } = getStatusLabelProperties(status)
      return <Label color={color}>{statusLabel}</Label>
    }
  },
  requestSubmitted: {
    key: 'request_submitted',
    headerText: () => locale('requestSubmitted'),
    render: (data) => {
      const checkInTime = propOr(null, 'check_in_time', data)
      if (!checkInTime) return null
      const country = pathOr('USA', ['address', 'country_code'], data)
      const displayTimeStamp = moment(checkInTime).format(
        getReqSubmittedDateTimeFormat(country)
      )
      return displayTimeStamp
    }
  }
}

const TableRow = ({ data, row, referenceData }) => {
  const kepMapping = path([row, 'key'], tableConfig)
  const displayedData = tableConfig[row].render
    ? tableConfig[row].render(data, referenceData)
    : propOr('', kepMapping, data)

  return (
    <Table.Row>
      <Table.Cell className="SummaryTableCell">
        {tableConfig[row].headerText()}
      </Table.Cell>
      <Table.Cell className="SummaryTableCell">{displayedData}</Table.Cell>
    </Table.Row>
  )
}

export default function SummaryTable({
  data = {},
  userTypes,
  reasons,
  facilities,
  showSummary,
  toggleSummaryDetails
}: PropsT) {
  const referenceData = {
    userTypes,
    reasons,
    facilities
  }
  const requestDetails = propOr([], 'request_details', data)
  const isCopartDelivery = propOr(false, 'copart_delivery_flag', data)
  return (
    <>
      <Table unstackable size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="SummaryTableHeader" colSpan="2">
              <span onClick={() => toggleSummaryDetails(!showSummary)}>
                {locale('summary')}
                <Icon name={showSummary ? 'caret up' : 'caret down'} />
              </span>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {showSummary &&
            keys(tableConfig).map((row) => (
              <TableRow
                key={row}
                data={data}
                row={row}
                referenceData={referenceData}
              />
            ))}
        </Table.Body>
      </Table>
      {renderIf(showSummary)(
        <Table unstackable size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="2" className="LotTableHeader">
                {locale('lotNumbers')}
              </Table.HeaderCell>
              <Table.HeaderCell className="LotTableHeader"></Table.HeaderCell>

              <Table.HeaderCell className="LotTableHeader">
                {locale('reason')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {requestDetails.map((detail, index) => (
              <Table.Row key={`detail_${index}`}>
                <Table.Cell className="SummaryTableCell" colSpan="2">
                  <div className="SummaryLotNumContainer">
                    <div>{detail.lot_number}</div>
                    {isCopartDelivery && (
                      <div className="copartDeliveryIcon">
                        <img
                          src={CopartDeliveryLogo}
                          style={{ height: '18px', width: '19px' }}
                          alt="copartDelivery"
                        />
                      </div>
                    )}
                  </div>
                </Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell className="SummaryTableCell">
                  {detail.reasons.map((reasonCode, idx) => (
                    <span key={reasonCode}>
                      <LocaledText
                        text={getReferenceDataDescription(reasonCode, reasons)}
                      />
                      {renderIf(idx + 1 !== detail.reasons.length)(', ')}
                    </span>
                  ))}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </>
  )
}
