import React, { useEffect, useState } from 'react'
import {
  Divider,
  Placeholder,
  Button,
  Form,
  Icon,
  Dropdown,
  Table,
  Message
} from 'semantic-ui-react'
import { useMachine } from '@xstate/react'
import { isEmpty, isNil, pathOr } from 'ramda'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/semantic-ui.css'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { ONLY_COUNTRIES } from '../../constants'
import machine from './machine'
import locale from '../../utils/locale'
import {
  stateMatchingConditionCheck,
  getDefaultCountry,
  getReasonsForUserType,
  getNavigatorGeolocation,
  useLogging
} from '../../utils'
import { stampCustomLog } from '../../utils/logs'
import { getPropertyFromLocalStorage } from '../../utils/storage'
import HiringBanner from '../../components/HiringBanner'
import SummaryTable from './SummaryTable'
import LastSeen from '../../components/LastSeen'
import LogoBanner from '../SelfServeForm/LogoBanner'
import LabelWithRequired from '../../components/Label'
import AppointmentBanner from './AppointmentBanner'
import Popup from '../../components/Popup'
import { getLocaledReasons } from '../../components/LocaledText'
import MarkAsArrivedButton from '../../components/MarkAsArrivedButton'
import ErrorMessage from '../../components/ErrorMessage'
import CancelAppointment from '../../components/CancelAppointment'

import './style.css'

const STACK = process.env.REACT_APP_STACK || 'c'

const getLocationFormat = (facilityDetails) => {
  if (!isEmpty(facilityDetails) && !isNil(facilityDetails)) {
    const {
      yard_name = '',
      yard_number = '',
      yard_address1 = '',
      yard_address2 = '',
      yard_city = '',
      yard_state_code = '',
      yard_zip = '',
      facilityTypeCode = '',
      sublotCode = ''
    } = facilityDetails
    return (
      <>
        {facilityTypeCode === 'S' ? (
          <div>
            <span className="summarySublotLabel">SUBLOT</span>
            {` ${sublotCode || yard_number} - ${yard_name}`}
            {isEmpty(yard_name) && <span>{yard_number}</span>}
          </div>
        ) : (
          <div> {`${yard_name} - ${yard_number}`}</div>
        )}
        <div>{`${yard_address1} ${yard_address2}`}</div>
        <div>{`${yard_city}, ${yard_state_code} ${yard_zip}`}</div>
      </>
    )
  }
  return ''
}

const tableConfig = {
  name: {
    key: 'first_name',
    headerText: () => locale('name'),
    render: (data) =>
      `${data.appointmentData.firstName} ${data.appointmentData.lastName}`
  },
  copartLocation: {
    key: 'facility_id',
    headerText: () => locale('copartLocation'),
    render: (data) =>
      getLocationFormat(
        data.pickupSublotData?.facilityTypeCode === 'S'
          ? data.pickupSublotData
          : data.facilityDetails
      )
  },
  appointmentDate: {
    key: 'appointment_date',
    headerText: () => locale('appointmentDate'),
    render: (data) => `${data.appointmentData.appointmentDate || ''}`
  }
}

const Text = ({ state }) => {
  const { appointmentData, pickupSublotData } = state.context
  return (
    (state.hasTag('todayDateLater') ||
      state.hasTag('futureDay') ||
      state.hasTag('pastDay')) && (
      <div>
        {!state.hasTag('pastDay') && (
          <>
            <div className="SummaryText">{locale('thankYouNoteText1')}</div>
            <div className="SummaryText">{locale('thankYouNoteText2')}</div>
          </>
        )}
        <div className="DateAndTime">
          <div className="DateStyle">
            {appointmentData.startTime.format('MMMM DD, YYYY')}
          </div>
          <div className="TimeStyle">
            {appointmentData.startTime.format('h:mm a')}
          </div>
        </div>
        <div className="SummaryText">
          {locale('appointmentCreatedShortText')}
        </div>
        {pickupSublotData?.facilityTypeCode === 'S' && (
          <div className="sublotWarningText">
            <div>
              <strong>
                <span>{'This appointment is located at '}</span>
                <span className="summarySublotLabel">{locale('sublot')}</span>
                <span>
                  {` ${
                    pickupSublotData?.sublotCode || pickupSublotData?.yard_name
                  }. Please ensure you arrive at the sublot's address.`}
                </span>
              </strong>
            </div>
            <a
              className="material-icons sublotMapIcon"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://maps.google.com/maps?daddr=${pickupSublotData?.yard_address1} ${pickupSublotData?.yard_address2} ${pickupSublotData?.city} ${pickupSublotData?.yard_state_code} ${pickupSublotData?.yard_zip}`}
            >
              location_on
            </a>
          </div>
        )}
      </div>
    )
  )
}

const PhoneNumber = ({ state, send, countryCode }) => {
  const { context = {} } = state || {}
  const { form } = context
  return (
    <>
      {stateMatchingConditionCheck(state, ['phoneNumberAbsent.error']) &&
        context.error && (
          <ErrorMessage
            message={context.error}
            data-testid="error"
            className="errorMessage"
          />
        )}

      <Form className="attached fluid AppointmentSummaryForm">
        <Form.Group widths="equal">
          <Form.Field className="field-section">
            <LabelWithRequired labelText="enterRegisteredNumber" />
            <PhoneInput
              countryCodeEditable={false}
              onlyCountries={ONLY_COUNTRIES}
              placeholder={locale('enterPhone')}
              inputProps={{
                required: true
              }}
              onKeyDown={(e) => {
                if (
                  countryCode === 'us' &&
                  [8, 46].includes(e.which) &&
                  form?.inputs?.phoneNumber?.length <= 1
                )
                  e.preventDefault()
              }}
              country={countryCode === 'ar' ? 'ae' : countryCode} // react-phone-input supports ae as country code for UAE
              value={form?.inputs?.phoneNumber}
              onChange={(phone = '') => {
                send('ENTER_PHONE_NUMBER', { phone })
              }}
              onBlur={(e, countryData) => {
                const phone = e.target.value
                const parsedPhone = phone && parsePhoneNumberFromString(phone)
                send('PARSE_AND_VALIDATE_PHONE_NUMBER', {
                  parsedPhone,
                  countryData
                })
              }}
            />
            {STACK === 'c' && !form?.inputs?.parsedPhone?.isValid && (
              <div>
                <Icon name="warning" color="red" />
                <span className="subtext-label">
                  {locale('phoneNumberInvalidWarning')}
                </span>
              </div>
            )}
          </Form.Field>
          <Button
            fluid
            disabled={
              !stateMatchingConditionCheck(state, [
                'phoneNumberAbsent.errorFree'
              ])
            }
            onClick={() => {
              send('SUBMIT')
            }}
            loading={stateMatchingConditionCheck(state, [
              // 'towProvider.checkingForAppointment'
            ])}
            primary
          >
            {locale('submit')}
          </Button>
        </Form.Group>
      </Form>
    </>
  )
}

const AppointmentSummary = (props) => {
  const [showHiringBanner, setShowHiringBanner] = useState(false)

  const pushToHistory = (_, event) => {
    props.history.push(event.url)
  }

  const requestId = pathOr('', ['match', 'params', 'appointmentId'], props)
  const [state, send, service] = useMachine(
    machine(pushToHistory).withContext({
      requestId: requestId,
      appointmentData: null,
      referenceData: {},
      form: {
        inputs: {}
      },
      countryCode: getDefaultCountry(),
      userType: 'TRNSPTR',
      lastSuccessfulFetchTime: new Date(),
      closestYard: {}
    }),
    {
      devTools: true
    }
  )

  //logging hook
  useLogging(service)

  const { context } = state

  useEffect(() => {
    const userType = getPropertyFromLocalStorage('userType')
    if (userType === 'TRNSPTR') setShowHiringBanner(true)
    getNavigatorGeolocation(
      function (position) {
        console.log('Latitude is :', position.coords.latitude)
        console.log('Longitude is :', position.coords.longitude)
        send({
          type: 'SET_LATITUDE_AND_LONGITUDE',
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        })
      },
      () => {
        stampCustomLog({
          event: 'locationBlocked',
          currentState: 'locationDisabled',
          phoneNumber: context.form?.inputs?.phoneNumber
        })
      }
    )

    if (props.history.action !== 'PUSH') {
      stampCustomLog({
        event: 'appointmentSummaryURL',
        currentState: 'appointmentSummary',
        phoneNumber: context.form?.inputs?.phoneNumber
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <LogoBanner backToHome={() => send('GO_BACK_TO_HOME')} />
      <div className="AppointmentSummaryContainer">
        {stateMatchingConditionCheck(state, ['phoneNumberAbsent']) && (
          <Popup height="auto">
            <div className="appointmentSummaryPopupHeader">
              {locale('appointmentSummary')}
            </div>
            <Divider />
            <div style={{ margin: '0px 20px' }}>
              {locale('confirmPhoneNumber')}
            </div>
            <PhoneNumber
              state={state}
              send={send}
              countryCode={context.countryCode}
            />
          </Popup>
        )}

        {stateMatchingConditionCheck(state, [
          'phoneNumberPresent.main.fetchingAppointmentDetails'
        ]) && (
          <div className="Placeholder">
            <div className="lastUpdateTime">
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </div>
            <div className="summaryHeader">
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </div>
            <div className="weAreReadySubText">
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </div>
            <div className="date">
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </div>
            <div className="time">
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </div>
            <Divider />
            <div className="markArrivedButton">
              <Placeholder>
                <Placeholder.Paragraph>
                  <Placeholder.Line length="full" />
                  <Placeholder.Line length="full" />
                  <Placeholder.Line length="full" />
                  <Placeholder.Line length="full" />
                </Placeholder.Paragraph>
              </Placeholder>
            </div>
            <div className="cancelAppointment">
              <Placeholder>
                <Placeholder.Paragraph>
                  <Placeholder.Line length="full" />
                </Placeholder.Paragraph>
              </Placeholder>
            </div>
            <Table unstackable size="small">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="2">
                    <Placeholder>
                      <Placeholder.Paragraph>
                        <Placeholder.Line />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Placeholder>
                      <Placeholder.Line />
                    </Placeholder>
                  </Table.Cell>
                  <Table.Cell>
                    <Placeholder>
                      <Placeholder.Line />
                    </Placeholder>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Placeholder>
                      <Placeholder.Line />
                    </Placeholder>
                  </Table.Cell>
                  <Table.Cell>
                    <Placeholder>
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Placeholder>
                      <Placeholder.Line />
                    </Placeholder>
                  </Table.Cell>
                  <Table.Cell>
                    <Placeholder>
                      <Placeholder.Line />
                    </Placeholder>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Table unstackable size="small">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="2">
                    <Placeholder>
                      <Placeholder.Paragraph>
                        <Placeholder.Line />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Placeholder>
                      <Placeholder.Line />
                    </Placeholder>
                  </Table.Cell>
                  <Table.Cell>
                    <Placeholder>
                      <Placeholder.Line />
                    </Placeholder>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        )}

        {stateMatchingConditionCheck(state, [
          'phoneNumberPresent.main.appointmentsDetailsloaded'
        ]) && (
          <>
            <div className="AppointmentLoadedContainer">
              {state.hasTag('activeAppointment') && (
                <>
                  <LastSeen
                    date={context.lastSuccessfulFetchTime}
                    onUpdate={() => send('REFRESH_SUMMARY_DATA')}
                  />
                  <HiringBanner
                    showHiringBanner={showHiringBanner}
                    onLinkClick={() =>
                      stampCustomLog({
                        currentState: 'appointmentSummary',
                        event: 'HIRING_BANNER_CLICKED'
                      })
                    }
                  />
                  <div className="SummaryHeader">
                    {locale('hey')} {context.appointmentData.firstName || ''}!
                  </div>
                  {(state.hasTag('todayDatePast') ||
                    state.hasTag('todayDateImmediate')) && (
                    <div>
                      <div className="SubText">
                        {locale('weAreReadySubText')}
                      </div>
                    </div>
                  )}
                  <Text state={state} />
                  <Divider />
                  <MarkAsArrivedButton
                    markAsArrivedMachineRef={context.markAsArrivedMachineRef}
                  />
                  {state.nextEvents.includes('CANCEL_APPOINTMENT') && (
                    <>
                      <div className="helpText">
                        {state.hasTag('futureDay') &&
                          locale('cancelAppointmentHelpText')}
                      </div>
                      <div className="cancelAppointmentLink">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                          onClick={() => {
                            send('CANCEL_APPOINTMENT')
                          }}
                        >
                          {locale('cancelAppointment')}
                        </a>
                      </div>
                    </>
                  )}
                  {state.hasTag('error') && (
                    <ErrorMessage message={context.error} data-testid="error" />
                  )}
                  {state.hasTag('showJoinOfficeQueue') && (
                    <div className="joinOfficeQueueBlock">
                      <div className="helpText">
                        {locale('joinOfficeQueueHelpText')}
                      </div>
                      <Button
                        fluid
                        onClick={() => send('JOIN_OFFICE_QUEUE')}
                        style={{
                          marginBottom: '10px',
                          fontSize: '16px',
                          border: '1px solid #cccccc'
                        }}
                        basic
                      >
                        {locale('joinQueue')}
                      </Button>
                    </div>
                  )}

                  {state.hasTag('showJoinOfficeQueueError') && (
                    <ErrorMessage message={context.error} data-testid="error" />
                  )}
                  <CancelAppointment state={state} send={send} />

                  {state.hasTag('joinOfficeQueuePopup') && (
                    <Popup height="auto">
                      <Icon
                        name="close"
                        onClick={() => {
                          send('CLOSE')
                        }}
                        style={{ fontSize: '20px' }}
                      />
                      <div className="pickReasonsHeading">
                        {locale('joinOfficeQueue')}
                      </div>
                      <Divider />
                      <div style={{ padding: '0 10px' }}>
                        <Form>
                          {context.appointmentData.lotDetails.map(
                            (lotData, index) => (
                              <div>
                                <Form.Group
                                  className="field-section"
                                  widths="equal"
                                >
                                  <Form.Field className="inputFieldDisabled">
                                    <Form.Input
                                      label={`${locale('lotIndex')} ${
                                        index + 1
                                      }`}
                                      placeholder={`${locale('lotIndex')} ${
                                        index + 1
                                      }`}
                                      value={lotData.lotNumber}
                                      maxLength={8}
                                      onChange={() => {}}
                                      type="tel"
                                      data-testid="lotNumber"
                                      readOnly
                                    />
                                  </Form.Field>
                                </Form.Group>
                                <Form.Group
                                  className="field-section"
                                  widths="equal"
                                >
                                  <Form.Field className="field-section">
                                    <LabelWithRequired labelText="reasonsLabel" />
                                    <Dropdown
                                      selection
                                      placeholder={locale('reasonsLabel')}
                                      multiple
                                      value={lotData.reasons}
                                      onChange={(_, { value }) => {
                                        send('PICK_REASONS', {
                                          lotNumber: lotData.lotNumber,
                                          value
                                        })
                                      }}
                                      renderLabel={({ content }) => ({
                                        content
                                      })}
                                      options={getLocaledReasons(
                                        getReasonsForUserType(
                                          context.userType,
                                          context.referenceData?.reasons
                                        )
                                      )}
                                      onBlur={() => {
                                        send('UPDATE_LOT')
                                      }}
                                      data-testid="selectReasons"
                                    />
                                  </Form.Field>
                                </Form.Group>
                              </div>
                            )
                          )}
                        </Form>
                        {state.hasTag('joinOfficeQueuePopupError') && (
                          <ErrorMessage
                            message={context.error}
                            data-testid="error"
                          />
                        )}
                        <Button
                          fluid
                          primary
                          onClick={() => send('CONTINUE')}
                          style={{ marginBottom: '20px' }}
                          loading={state.hasTag('joiningOfficeQueue')}
                        >
                          {locale('Continue')}
                        </Button>
                      </div>
                    </Popup>
                  )}
                </>
              )}
              {state.hasTag('cancelledAppointment') && (
                <ErrorMessage message={locale('appointmentCancelledText')} />
              )}
              {state.hasTag('completedAppointment') && (
                <Message positive>{locale('appointmentCompletedText')}</Message>
              )}
              <SummaryTable
                send={send}
                data={context}
                tableConfig={tableConfig}
                showSummary={context.showSummary}
                hideLotsSummary={state.hasTag('completedAppointment')}
              />

              <Button
                fluid
                basic
                onClick={() => send('GO_BACK_TO_HOME')}
                style={{
                  fontSize: '16px',
                  border: '1px solid #cccccc'
                }}
              >
                <Icon name="angle left" />
                {locale('goBackHome')}
              </Button>
            </div>
            <AppointmentBanner />
          </>
        )}

        {stateMatchingConditionCheck(state, [
          'phoneNumberPresent.main.appointmentsDetailsError'
        ]) && (
          <ErrorMessage
            message={<div className="NoData">{locale('cannotLoadData')}</div>}
          />
        )}
      </div>
    </>
  )
}

export default AppointmentSummary
