import React from 'react'
import { useActor } from '@xstate/react'
import { Form, Dropdown, Button, Icon, Modal, Segment } from 'semantic-ui-react'

import locale from '../../utils/locale'
import {
  stateMatchingConditionCheck,
  useLogging,
  checkCopartDelivery
} from '../../utils'
import { getLocaledReasons } from '../../components/LocaledText'
import Popup from '../../components/Popup'
import LabelWithRequired from '../../components/Label'
import CustomWarningMessage from '../../components/CustomWarningMessage'
import { showMoneyOrderMessage } from '../../utils'
import CopartDeliveryLogo from '../../components/CopartDeliveryLogo/CopartDeliveryLogo.png'

type PropsT = {
  countryCode: String,
  items: Array<Object>,
  index: Number,
  userType: String,
  reasons: Array<Object>,
  userTypes: Array<Object>,
  setLotItemInfo: () => void,
  addLotItemInfo: () => void,
  removeLotItemInfo: () => void
}

const LotItem = ({
  countryCode,
  lotItemRef,
  reasonsOptions,
  index,
  numberOfLots,
  pickupLots,
  isCopartDelivery
}: PropsT) => {
  const [state, send] = useActor(lotItemRef)
  useLogging(lotItemRef)

  const { context } = state

  const getPlaceHolderLabel = (label) => `${locale(label)} ${index + 1}`

  const LabelWithIcon = ({ labelText, iconName }) => {
    return (
      <div className="gatepassblock">
        <label
          className={
            stateMatchingConditionCheck(state, [
              'editing.towProvider.lotNumber.error'
            ])
              ? 'gatepass-label error'
              : 'gatepass-label active'
          }
        >
          {locale(labelText)}
        </label>
        <span className="label-required">*</span>
        <span
          className="gatepassInfoIcon"
          onClick={() => send({ type: 'LOT_ITEM_POPUP', value: true })}
        >
          <Icon name={iconName} color="blue" />
        </span>
      </div>
    )
  }

  const getFieldProperties = () => ({ unstackable: true, width: 2 })
  const imgpath = '/images/gatepassPin.png'

  return (
    <>
      <Modal
        open={context?.showPopup}
        onClose={() => send({ type: 'LOT_ITEM_POPUP', value: false })}
        closeIcon
        className={'gatepassPopup'}
      >
        <Modal.Content>
          <Segment raised className="gatepassSegmentBanner">
            <div className="imageHeader">
              <img src={imgpath} />
            </div>

            <div className="gatepassInfoContainer">
              <div className="gatepassQuestion">
                {locale('gatepassQuestion1')}
              </div>
              <div className="infoText">{locale('gatepassInfo1')}</div>
              <div className="gatepassQuestion">
                {locale('gatepassQuestion2')}
              </div>
              <div className="infoText">{locale('gatepassInfo2')}</div>
              <div
                className="gatepassInfoFooter"
                onClick={() => send({ type: 'LOT_ITEM_POPUP', value: false })}
              >
                {locale('gotIt')}
              </div>
            </div>
          </Segment>
        </Modal.Content>
      </Modal>
      <Form.Group className="field-section" {...getFieldProperties()}>
        {(state.matches('editing.nonTowProvider') ||
          state.matches('editing.towProvider')) && (
          <>
            <Form.Field className={'LotItemInputField'}>
              <Form.Input
                label={getPlaceHolderLabel('lotIndex')}
                placeholder={getPlaceHolderLabel('lotIndex')}
                value={context.lot_number}
                maxLength={8}
                onChange={(e, { value }) => {
                  send({ type: 'LOT_NUMBER_CHANGE', value })
                  if (value.length === 8) {
                    send({
                      type: 'LOT_COPART_DELIVERY'
                    })
                  }
                }}
                onBlur={() => {
                  send('LOT_ITEM_BLUR')
                }}
                type="tel"
                data-testid="lotNumber"
                required={state.matches('editing.towProvider')}
                error={stateMatchingConditionCheck(state, [
                  'editing.towProvider.lotNumber.error'
                ])}
              />
            </Form.Field>
            {context?.isCopartDelivery && (
              <div className={'copartDeliveryIcon'}>
                <img
                  src={CopartDeliveryLogo}
                  style={{ height: '32px', width: '28px' }}
                  alt="copartDelivery"
                />
              </div>
            )}
            <Form.Field className={'LotItemButtonField'}>
              <Button
                size="mini"
                circular
                icon="minus"
                onClick={() => send('REMOVE')}
                data-testid="remove"
                disabled={index === 0 && numberOfLots === 1}
                className="removeButton"
              />
              <Button
                size="mini"
                circular
                icon="add"
                onClick={() => {
                  send('ADD')
                }}
                data-testid="add"
                className="addButton"
              />
            </Form.Field>
          </>
        )}
      </Form.Group>
      {state.matches('editing.towProvider') && (
        <>
          <Form.Field className="gatePassPin">
            <LabelWithIcon
              labelText="gatePassPin"
              iconName="question circle outline"
            />
            <Form.Input
              label={''}
              placeholder={getPlaceHolderLabel('gatePassPin')}
              value={context.gate_pass_pin}
              maxLength={6}
              onChange={(e, { value }) => {
                send({
                  type: 'GATE_PASS_PIN_CHANGE',
                  value: value?.toUpperCase()
                })
              }}
              onBlur={() => {
                send('LOT_ITEM_BLUR')
              }}
              data-testid="gatePassPin"
              error={stateMatchingConditionCheck(state, [
                'editing.towProvider.gatePassPin.error'
              ])}
            />
          </Form.Field>
        </>
      )}
      {showMoneyOrderMessage() &&
        countryCode === 'us' &&
        context.reasons?.includes('MKPAY') && (
          <div className="lotItemCustomMessageContainer">
            <CustomWarningMessage
              message={locale('paymentMethodBannerMessage')}
            />
          </div>
        )}
      <Form.Field className="field-section">
        <LabelWithRequired labelText="selectReasons" />
        <Dropdown
          selection
          placeholder={locale('selectReasons')}
          multiple
          value={context.reasons}
          onChange={(e, { value }) => {
            send({ type: 'PICK_REASONS', value })
          }}
          renderLabel={({ content }) => ({ content })}
          options={getLocaledReasons(reasonsOptions)}
          onBlur={() => {
            send('LOT_ITEM_BLUR')
          }}
          data-testid="selectReasons"
        />
      </Form.Field>
    </>
  )
}

export default LotItem
