import React, { useEffect, useState } from 'react'
import { Button, Confirm, Divider } from 'semantic-ui-react'
import renderIf from 'render-if'
import { pathOr, prop, propOr, propEq, isNil } from 'ramda'
import moment from 'moment'
import SummaryTable from './SummaryTable'
import Banner from './Banner'
import HiringBanner from '../../components/HiringBanner'
import LastSeen from '../../components/LastSeen'
import ErrorMessage from '../../components/ErrorMessage'
import CustomWarningMessage from '../../components/CustomWarningMessage'
import { fetchRequestData, leaveQueue } from '../../actions/selfServeActions'
import { fetchPickupSublotInfoService } from '../AppointmentSummary/machine/services'
import { getPropertyFromLocalStorage } from '../../utils/storage'
import locale from '../../utils/locale'
import { stampCustomLog } from '../../utils/logs'
import { showMoneyOrderMessage } from '../../utils'
import LogoBanner from '../SelfServeForm/LogoBanner'
import './style.css'
import queryString from 'query-string'

type PropsT = {}

const STACK = process.env.REACT_APP_STACK || 'c'

const getAppointmentCreated = () => {
  const search = propOr('', 'search', window.location)
  return propOr(false, 'appointmentCreated', queryString.parse(search))
}

const QueueStatus = (props: PropsT) => {
  const getRequestId = () => pathOr('', ['match', 'params', 'requestId'], props)
  const [openConfirm, setConfirmVisibility] = useState(false)
  const [openBanner, toggleBanner] = useState(false)
  const [showSummary, toggleSummaryDetails] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [summaryData, setUserSummaryData] = useState({})
  const [requestDateTime, setRequestDateTime] = useState(null)
  const [pickupSublot, setPickupSublot] = useState({})
  const [showHiringBanner, setShowHiringBanner] = useState(false)

  const getFacilityInfo = (facility_id) => {
    fetchPickupSublotInfoService(facility_id).then((data = {}) => {
      if (data.facilityTypeCode === 'S') {
        setPickupSublot(data)
      }
    })
  }

  const setSummaryData = (showBanner = false) => {
    // Fetch details for request Id
    return fetchRequestData(getRequestId())
      .then((data = {}) => {
        setRequestDateTime(new Date())
        setUserSummaryData(data)
        if (enableBanner(data)) {
          toggleBanner(showBanner)
        }
        return data
      })
      .catch((error) => {
        console.log('Invalid/Expired Request Id', getRequestId())
        setErrorMessage('invalidExpiredRequest')
        return {}
      })
  }

  useEffect(() => {
    setSummaryData(true).then((data) => {
      if (data?.appointment?.facility_id) {
        getFacilityInfo(data?.appointment?.facility_id)
      }
      if (isTransporter(data)) {
        setShowHiringBanner(true)
      }
    })
    if (props.history.action !== 'PUSH') {
      stampCustomLog({ currentState: 'kioskSummary', event: 'kioskRequestURL' })
    }
    // eslint-disable-next-line
  }, [])

  const isRequestOpen = ({ request_status: status }) =>
    ['Open'].includes(status)

  const isRequestProcessing = ({ request_status: status }) =>
    ['Processing'].includes(status)

  const isRequestClosed = ({ request_status: status }) =>
    ['Closed', 'Cancelled', 'Completed'].includes(status)

  const isMobile = () => !Boolean(getPropertyFromLocalStorage('facilityId'))

  const getTransporterCodeFromConfig = () =>
    propOr('TRNSPTR', 'transporter_type', props.config)

  const isTransporter = ({ user_type_cd: userTypeCode }) =>
    userTypeCode === getTransporterCodeFromConfig()

  const isMember = ({ user_type_cd: userTypeCode }) =>
    ['MBR', 'PMRMBR'].includes(userTypeCode)

  const showMemberBanner = (data) =>
    isMember(data) &&
    (data.request_details || []).some(({ reasons = [] }) =>
      reasons.includes('MKPAY')
    )

  const enableBanner = (data) =>
    ['uk', 'c'].includes(STACK) && showMemberBanner(data)

  const BackHomeButton = () => (
    <>
      <br />
      <Button fluid onClick={() => props.history.push('/')}>
        {locale('goHome')}
      </Button>
    </>
  )

  const getWaitTime = () => prop('wait_time', summaryData)

  const getUserFristName = () => prop('first_name', summaryData)

  const isTextAllowed = propOr(false, 'is_text_allowed', summaryData)

  const isPremierMember = () => propEq('user_type_cd', 'PMRMBR', summaryData) // Read from config

  const showQueuePosition = propOr(false, 'show_queue_position', summaryData)
  return (
    <>
      <LogoBanner />
      {renderIf(!errorMessage)(
        <div className="queue-status-container">
          {renderIf(isRequestOpen(summaryData))(
            <>
              {requestDateTime && (
                <LastSeen date={requestDateTime} onUpdate={setSummaryData} />
              )}
              {showHiringBanner && (
                <HiringBanner
                  showHiringBanner={showHiringBanner}
                  onLinkClick={() =>
                    stampCustomLog({
                      currentState: 'kioskSummary',
                      event: 'HIRING_BANNER_CLICKED'
                    })
                  }
                />
              )}
              {showMoneyOrderMessage() &&
                props.countryCode === 'us' &&
                summaryData?.request_details?.[0].reasons?.includes(
                  'MKPAY'
                ) && (
                  <div className="SummaryContainer">
                    <CustomWarningMessage
                      message={locale('paymentMethodBannerMessage')}
                    />
                  </div>
                )}
              <div className="SummaryHeader">
                {`${locale('hey')} ${getUserFristName()}!`}
              </div>
              {getAppointmentCreated() && isNil(summaryData?.appointment) && (
                <div
                  className="AppointmentScheduledHelpText"
                  style={{ color: 'green' }}
                >
                  {locale('appointmentCreatedText')}
                </div>
              )}
              {getAppointmentCreated() && !isNil(summaryData?.appointment) && (
                <div>
                  <div className="SummaryText">
                    {locale('thankYouNoteText1')}
                  </div>
                  <div className="SummaryText">
                    {locale('thankYouNoteText2')}
                  </div>
                  <div className="DateAndTime">
                    <div className="DateStyle">
                      {moment(summaryData?.appointment?.start_datetime)?.format(
                        'MMMM DD, YYYY'
                      )}
                    </div>
                    <div className="TimeStyle">
                      {moment(summaryData?.appointment?.start_datetime)?.format(
                        'h:mm a'
                      )}
                    </div>
                  </div>
                  <div className="SummaryText">
                    {locale('appointmentCreatedShortText')}
                  </div>
                  {pickupSublot?.facilityTypeCode === 'S' && (
                    <div className="sublotWarningText">
                      <div>{locale('sublotWarning')}</div>
                      <a
                        className="material-icons sublotMapIcon"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://maps.google.com/maps?daddr=${pickupSublot?.yard_address1} ${pickupSublot?.yard_address2} ${pickupSublot?.city} ${pickupSublot?.yard_state_code} ${pickupSublot?.yard_zip}`}
                      >
                        location_on
                      </a>
                    </div>
                  )}
                </div>
              )}
              {renderIf(isPremierMember())(
                <div className="SummaryText">
                  {locale('thankYouPremiumMember')}
                </div>
              )}
              <div className="SummaryText">{locale('thankYouSummary')}</div>
              {renderIf(showQueuePosition)(
                <>
                  <div className="SummaryText">{locale('currentPosition')}</div>
                  <div className="SummaryPositionText">
                    {summaryData.position}
                  </div>
                </>
              )}

              <Divider horizontal className="EstimatedWaitTimeHeader">
                {locale('estimatedWaitTime')}
              </Divider>
              <div className="EstimatedWaitTime">
                <span className="EstimatedWaitTimeText">{getWaitTime()}</span>
                <span className="EstimatedWaitTimeText">
                  {getWaitTime() > 1 ? locale('minutes') : locale('minute')}
                </span>
              </div>
              <div className="SummarySubText">
                {locale('waitTimeDisclaimer')}
              </div>
              {renderIf(isTextAllowed)(
                <>
                  <div className="SummaryText">{locale('willSendText')}</div>
                  <div className="SummaryText">{locale('nearFrontLine')}</div>
                </>
              )}
            </>
          )}
          {renderIf(isRequestProcessing(summaryData))(
            <div className="SummaryTextContainer">
              <div className="SummaryText">{locale('readyToAssist')}</div>
              <div className="SummarySubText">
                {locale('reportTimeDisclaimer')}
              </div>
            </div>
          )}
          {renderIf(isRequestClosed(summaryData))(
            <div className="SummaryTextContainer">
              <div className="SummaryText">{locale('thankYouForVisiting')}</div>
              <div className="SummaryText">{locale('requireAssistance')}</div>
            </div>
          )}
          <div className="SummaryContainer">
            {renderIf(isRequestOpen(summaryData) && isMobile())(
              <>
                <Button
                  negative
                  fluid
                  onClick={() => setConfirmVisibility(true)}
                >
                  {locale('removeQueue')}
                </Button>
                <Confirm
                  open={openConfirm}
                  cancelButton={locale('cancel')}
                  confirmButton={locale('removeQueue')}
                  size="large"
                  content={
                    <div className="confirmContent">
                      {locale('confirmRemoveMessage')}
                    </div>
                  }
                  onCancel={() => setConfirmVisibility(false)}
                  onConfirm={() => {
                    setConfirmVisibility(false)
                    // Make API Call to remove user from Queue
                    leaveQueue(getRequestId())
                    props.history.push('/') // Redirect to Home
                  }}
                />
              </>
            )}
            <BackHomeButton />
            <SummaryTable
              data={summaryData}
              {...props}
              showSummary={showSummary}
              toggleSummaryDetails={toggleSummaryDetails}
            />
            <Banner
              open={openBanner}
              closeBanner={toggleBanner}
              userType={propOr('', 'user_type_cd', summaryData)}
              countryCode={props.countryCode}
            />
          </div>
        </div>
      )}
      {renderIf(errorMessage)(
        <div className="SummaryContainer">
          <ErrorMessage
            header={locale('somethingWentWrong')}
            list={[`${locale(errorMessage)} ${getRequestId()}`]}
          />
          <BackHomeButton />
        </div>
      )}
    </>
  )
}

export default QueueStatus
