import React from 'react'
import enDefaults from './en'

export default {
  ...enDefaults,
  selectUserType: 'Выберите вариант, который относится к Вам',
  selectReasons:
    'Причина (-ы) посещения. Выберите все варианты, которые к Вам относятся',
  lotIndex: 'Номер лота',
  firstName: 'Имя',
  lastName: 'Фамилия',
  enterFirstName: 'Имя',
  enterLastName: 'Фамилия',
  enterPhone: 'Номер мобильного телефона',
  selectState: 'Штат площадки Copart',
  selectCopartLocation: 'Площадка Copart',
  joinQueue: 'Войти в Регистрацию',
  joinOfficeQueue: 'Войти в Регистрацию',
  chooseCopartLocation: 'Выберите местоположение Copart',
  removeQueue: 'Удалить меня из Регистрации посетителей',
  cancel: 'Отменить',
  goHome: 'Вернуться на Главную',
  confirmRemoveMessage:
    'Вы потеряете место в очереди. Его не удастся восстановить.',
  selfServiceKiosk: 'Регистрация посетителей',
  unableToJoinQueue:
    'Не удалосьвойти в Регистрацию посетителей. Повторите попытку позже.',
  textAllowed: 'Получать уведомления через смс-сообщения',
  disclaimerText:
    'Отмечая это поле Вы даете Copart разрешение отправлять Вам сообщения на указанный номер телефона.',
  thankYouSummary: 'Благодарим за терпение',
  thankYouPremiumMember: 'Спасибо за то, что Вы являетесь Премьер-членом!',
  currentPosition: 'Ваше текущее место в очереди -',
  willSendText: 'Мы пришлем сообщение, когда представитель',
  nearFrontLine: 'будет готов Вам помочь.',
  summary: 'Итого',
  name: 'Имя',
  userType: 'Тип пользователя',
  reason: 'Причины (-ы) посещения',
  phone: 'Номер телефона',
  copartLocation: 'Площадка Copart',
  State: 'Штат',
  Province: 'Провинция местоположения Копарт',
  lotNumbers: 'Лот',
  createdTime: 'Время создания',
  English: 'Английский',
  Spanish: 'Испанский',
  French: 'французский',
  Russian: 'Pусский',
  setupKioskYard: 'Установить киоск для площадки',
  requestStatus: 'Статус',
  welcome: 'Добро пожаловать!',
  welcomeLabel: 'Добро пожаловать!',
  receiveAssistance:
    'Пожалуйста, заполните информацию ниже, чтобы записаться в очередь.',
  somethingWentWrong: 'Произошла ошибка',
  invalidExpiredRequest:
    'Неверный или просроченный запрос. Не удалось получить информацию по запросу',
  thankYouForVisiting: 'Спасибо, что посетили Copart.',
  requireAssistance:
    'Если Вам необходима помощь, запишитесь в очередь нажав на кнопку возврата на главную страницу.',
  lastUpdateTime: 'Последнее обновление: ',
  estimatedWaitTime: 'Примерное время ожидания',
  readyToAssist:
    'Представитель Copart готов Вам помочь. Пожалуйста, подойдите к главной стойке.',
  waitTimeDisclaimer:
    'Пожалуйста, обратите внимание, что Ваш номер в очереди и время ожидания могут незначительно измениться в зависимости от приоритетности в очереди.',
  reportTimeDisclaimer:
    'Пожалуйста, приходите в офис, как только вы получите уведомление, иначе ваша встреча может быть отменена.',
  numberOfLots: 'Количество лотов',
  welcomeTo: 'Добро пожаловать в',
  selfServeKiosk: 'Регистрация посетителей',
  copartSelfServeKiosk: 'Регистрация посетителей',
  chooseDesigantionText: 'Пожалуйста, выберите вариант ниже, чтобы начать.',
  enterMemberId: 'Номер участника или и-мейл',
  queue: 'Очередь',
  hey: 'Привет',
  fasterService:
    'Для более быстрого обслуживания введите все номера лотов ниже',
  selectFacilityText: 'Выберите объект, чтобы просмотреть адрес',
  allow: 'Позволять',
  locationSharing: 'совместное использование местоположения',
  facilityAutoSelect: 'для автоматического выбора ближайшего к вам объекта.',
  nearbyFacilitiesText: 'Ближайшие места',
  mapViewHeader: 'Выберите место',
  facilitySelectMapInstruction:
    'Выберите штат / провинцию, чтобы просмотреть соответствующие местоположения Copart, и нажмите на место, которое вы хотите занять в очереди на карте, чтобы выбрать его.',
  selectFacility: 'Выберите место',
  locationDetails: 'Детали местоположения',
  viewMap: 'Посмотреть карту',
  yard: 'Двор',
  sublot: 'Подлот',
  noLocationsFound: 'Ничего не найдено!',
  requestSubmitted: 'Запрос отправлен',
  towProviderSelfServeFormFillFormHelpText: (
    <>
      Заполните <span style={{ color: 'red' }}>обязательную</span> информацию
      ниже, чтобы войти в Регистрацию посетителей.
    </>
  ),
  nonTowProviderSelfServeFormFillFormHelpText:
    'Заполните обязательную информацию ниже, чтобы войти в Регистрацию посетителей.',
  slotsNotAvailableMessage:
    'Мест нет. Для получения помощи нажмите кнопку ниже, чтобы войти в Регистрацию посетителей.',
  didYouKnow: 'Вы знали?',
  payOnline: 'Copart предлагает несколько способов оплаты онлайн.',
  learnMore: 'Узнать больше',
  'Drop off/Pickup Titles or Paperwork':
    'Оставить / забрать титулы или документы',
  'Pickup Personal Vehicle Items':
    'Самовывоз личных вещей из транспортного средства',
  Other: 'Другое',
  'Vehicle Inspection': 'Осмотр транспортного средства',
  'Vehicle Drop off': 'Привоз транспортного средства',
  'Sign Titles': 'Подпись титула',
  'Pickup Check': 'Проверка самовывоза',
  'Windshield labels': '	Лейблы на лобовое стекло',
  'Make Payments': 'Осуществить платежи',
  'Pickup Vehicles': 'Траки и пикапы',
  'Preview Vehicles': 'Предварительный просмотр транспортных средств',
  'Questions about Membership': 'Вопросы об программе участника',
  'Drop off/Pickup Keys': 'Ключи для передачи/самовывоза',
  'Questions about a Vehicle': '	Вопросы о транспортном средстве',
  'Vehicle Owner': 'Владелец транспортного средства',
  Adjuster: 'Регулятор',
  'CDS Seller': 'Продавец CDS',
  Vendor: 'Продавец',
  'Premier Member': 'Участник плана Премьер',
  Member: 'Участник',
  Transporter: 'Транспортер',
  'Unexpected error, could not verify the yard availability status':
    'Непредвиденная ошибка, не удалось проверить статус доступности ярдов',
  phoneNumberInvalidWarning:
    'Пожалуйста, убедитесь, что вы ввели действительный номер телефона',
  gatepassQuestion1: 'Нужен PIN-код для пропуска?',
  gatepassInfo1: `Если вы являетесь перевозчиком, обратитесь к участнику плана, чтобы получить правильный PIN-код пропуска.
  Если вы являетесь участником плана, вы можете получить PIN-код, зайдя на сайт Copart.com или в мобильное приложение участника плана.`,
  gatepassQuestion2: `Как участнику плана получить доступ к своему уникальному PIN-коду пропуска?`,
  gatepassInfo2: `Участник плана может найти PIN-код, войдя на Copart.com или в мобильное приложение участника плана. После входа в систему участнику плана следует перейти на вкладку «Платежи». Здесь будет отображен PIN-код пропуска для любого автомобиля, выигранного участником плана. PIN-код также будет указан в электронном письме, получаемом участником плана после выигрыша лота. После регистрации участнику плана следует перейти на вкладку «Платежи». Здесь будет отображен PIN-код пропуска для любого автомобиля, выигранного участником плана. PIN-код также будет указан в электронном письме, получаемом участником плана после выигрыша лота.`,
  gotIt: 'Понятно!',
  licenseState: 'Состояние номерного знака',
  licensePlateNumber: 'Номерной знак',
  numberOfGatePassPINs: 'Количество PIN-кодов пропусков для ворот',
  gatePassPin: 'PIN-код пропуска',
  truckType: 'Тип грузовика',
  enterTruckType: 'Введите тип грузовика',
  enterLicenseState: 'Укажите штат лицензии',
  enterLicensePlateNumber: 'Введите номер номера лицензии',
  notice: 'ВАЖНО',
  limitedSlotsAvailableWarning:
    'Клиентам Copart рекомендуется записываться на прием автомобилей. Из-за высокого спроса могут быть ограниченные слоты. Пожалуйста, продолжайте записываться на прием прямо сейчас.',
  checkSchedule: 'Проверьте расписание'
}
