import fetcher from '../../utils/fetcher'
import { endpoints } from '../../config/endpoints'

export const x = () => {}

export const checkCopartDeliveryService = (ctx) => {
  const payload = {
    lot_numbers: [Number(ctx.lot_number)]
  }
  return fetcher.post(endpoints.post.validateCopartDelivery, payload, {
    'X-YARD': ctx.facilityId
  })
}
