import React from 'react'
import { Modal, Button, Confirm, Dimmer, Loader } from 'semantic-ui-react'
import locale from '../utils/locale'

const CancelAppointment = ({ state, send }) => {
  return (
    <>
      {state.hasTag('cancelAppointmentIdle') && (
        <Confirm
          content={
            <div className="cancelHeader">{locale('confirmCancel')}?</div>
          }
          open={true}
          onCancel={() => {
            send('CLOSE')
          }}
          onConfirm={() => {
            send('CONFIRM')
          }}
        />
      )}
      {state.hasTag('cancellingAppointment') && (
        <Dimmer active>
          <Loader>{locale('Loading')}</Loader>
        </Dimmer>
      )}
      {state.hasTag('cancelAppointmentSuccess') && (
        <Modal open>
          <Modal.Header>{locale('cancelSuccess')}</Modal.Header>
          <Modal.Actions>
            <Button
              color="green"
              onClick={() => {
                send('CONTINUE')
              }}
            >
              {locale('Continue')}
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  )
}

export default CancelAppointment
