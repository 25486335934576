import React from 'react'
import { Icon, Message } from 'semantic-ui-react'

const WarningMessage = ({ message = '', header = '', list = [], ...props }) => {
  return (
    <Message warning {...props}>
      <Message.Header>{header}</Message.Header>
      <Message.List items={list} />
      {message !== '' && (
        <>
          <Icon name="warning" />
          {message}
        </>
      )}
    </Message>
  )
}

export default WarningMessage
