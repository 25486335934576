import React, { useState } from 'react'
import { Dropdown, Form, Button } from 'semantic-ui-react'

import locale from '../../utils/locale'
import { getFilteredYards } from '../../utils'
import {
  getPropertyFromLocalStorage,
  setPropertyInLocalStorage
} from '../../utils/storage'

import './style.css'

const LabelWithRequired = ({ labelText }) => {
  return (
    <>
      <label className="label-text">{locale(labelText)}</label>
      <span className="label-required">*</span>
    </>
  )
}

export default function FacilityForm({ facilities, states, history }) {
  const [facilityId, setFacilityId] = useState(
    getPropertyFromLocalStorage('facilityId')
  )
  const [stateCode, setStateCode] = useState(
    getPropertyFromLocalStorage('stateCode')
  )

  const areFieldsValid = () => !(facilityId && stateCode)

  const handleSubmit = () => {
    setPropertyInLocalStorage('facilityId', facilityId)
    setPropertyInLocalStorage('stateCode', stateCode)
    history.push('/')
  }

  return (
    <Form className="attached fluid segment half-height">
      <Form.Group widths="equal">
        <Form.Field>
          <LabelWithRequired labelText="selectState" />
          <Dropdown
            clearable
            placeholder={locale('selectState')}
            selection
            options={states}
            search
            value={stateCode}
            onChange={(e, { value }) => setStateCode(value)}
            data-testid="selectState"
          />
        </Form.Field>
        <Form.Field>
          <LabelWithRequired labelText="selectCopartLocation" />
          <Dropdown
            clearable
            placeholder={locale('selectCopartLocation')}
            selection
            search
            options={getFilteredYards(stateCode, facilities, states)}
            value={facilityId}
            onChange={(e, { value }) => setFacilityId(value)}
            data-testid="selectCopartLocation"
          />
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <Button
          fluid
          disabled={areFieldsValid()}
          onClick={handleSubmit}
          primary
          data-testid="setupKioskYard"
        >
          {locale('setupKioskYard')}
        </Button>
      </Form.Field>
    </Form>
  )
}
