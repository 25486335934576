import React from 'react'

export default {
  selectUserType: 'Pick the option that best describes you',
  selectReasons: 'Reason(s) for visit? (Select all that apply)',
  lotIndex: 'Lot number',
  firstName: 'First name',
  lastName: 'Last name',
  enterFirstName: 'First name',
  enterLastName: 'Last name',
  enterPhone: 'Mobile number',
  selectState: 'State',
  selectCopartLocation: 'Location',
  joinQueue: 'Enter Visitor Sign-In',
  removeQueue: 'Remove me from Visitor Sign-In',
  cancel: 'Cancel',
  goHome: 'Go back to home page',
  goBackHome: 'Go back home',
  confirm: 'Confirm',
  confirmCancel: 'Confirm Cancellation',
  confirmRemoveMessage:
    'You will lose your current position. This action cannot be undone.',
  selfServiceKiosk: 'Visitor Sign-In',
  unableToJoinQueue: 'Unable to enter Visitor Sign-In, please try again later.',
  textAllowed: 'Receive Updates via Text Messages',
  disclaimerText:
    'By checking this box, you give Copart permission to text you at the mobile number entered.',
  thankYouSummary: 'Thank you for your patience.',
  thankYouPremiumMember: 'Thank you for being a Premier Member!',
  currentPosition: 'Your current queue position is',
  willSendText: 'We will text you when a representative',
  nearFrontLine: 'is ready to assist you.',
  summary: 'Summary',
  name: 'Name',
  userType: 'Type of User',
  reason: 'Reason(s) for visit',
  phone: 'Mobile number',
  copartLocation: 'Location',
  State: 'State',
  Province: 'Province',
  createdTime: 'Created Time',
  English: 'English',
  Spanish: 'Spanish',
  French: 'French',
  Russian: 'Russian',
  setupKioskYard: 'Setup kiosk for facility',
  requestStatus: 'Status',
  welcome: 'Welcome!',
  receiveAssistance:
    'Please fill out the information below to reserve your place in line.',
  somethingWentWrong: 'Something went wrong',
  invalidExpiredRequest:
    'Invalid or expired request. Unable to obtain summary information for your request: ',
  thankYouForVisiting: 'Thank you for visiting Copart.',
  requireAssistance:
    'If you require assistance, you may join the queue by clicking the go back to home page button below.',
  lastUpdateTime: 'Last Update: ',
  estimatedWaitTime: 'Estimated wait time',
  readyToAssist:
    'A Copart representative is ready to assist you. Please come to the front counter.',
  waitTimeDisclaimer:
    'Please note that your position in line and wait time may change slightly based on priority in the queue.',
  reportTimeDisclaimer:
    'Please report to the office as soon as you are notified, or your appointment may be cancelled.',
  numberOfLots: 'Number of lots',
  numberOfGatePassPINs: 'Number Of Gate Pass PINs',
  welcomeTo: 'Welcome to the',
  selfServeKiosk: 'Visitor Sign-In',
  copartSelfServeKiosk: 'Visitor Sign-In',
  chooseDesigantionText:
    'Please choose your appropriate designation below to begin.',
  enterMemberId: 'Email or Member Number',
  queue: 'Queue',
  hey: 'Hey',
  fasterService: 'For faster service, enter all Lot Numbers below',
  selectFacilityText: 'Select a facility to view the address',
  allow: 'Allow',
  locationSharing: 'location sharing',
  facilityAutoSelect: 'to enable automatic selection of your nearest facility.',
  nearbyFacilitiesText: 'Nearby locations',
  mapViewHeader: 'Select a Location',
  facilitySelectMapInstruction:
    'Select a state/province to view the respective Copart locations and tap on a location you wish to queue for on the map to select one.',
  selectFacility: 'Select Location',
  locationDetails: 'Location Details',
  viewMap: 'View Map',
  yard: 'Yard',
  sublot: 'SUBLOT',
  locationsNotFound: 'No locations found!',
  requestSubmitted: 'Request submitted',
  Continue: 'Continue',
  fillToScheduleAppt:
    'Please fill out the information below to schedule an appointment',
  readyToSchedule: 'Do you want to schedule an appointment?',
  scheduleApptHeader: 'Schedule appointment',
  selectApptTime: 'Appointment time',
  confirmAppointment: 'Confirm appointment',
  truckType: 'Truck type',
  licenseState: 'License plate state',
  licensePlateNumber: 'License plate number',
  enterTruckType: 'Enter truck type',
  enterLicenseState: 'Enter license state',
  enterLicensePlateNumber: 'Enter license plate number',
  confirmation: 'Confirmation',
  weAreReadyText: 'We’re Ready For You!',
  weAreReadySubText: 'Please proceed to the vehicle load-out area.',
  appointmentSummary: 'Appointment Summary',
  fetchingAppointMentDetails: 'Fetching Appointment Details',
  appointmentDate: 'Appointment Date',
  lotsSummary: 'Lots Summary',
  lotNumber: 'Lot number',
  lotNumberStatus: 'Lot number status',
  thankYouNoteText1: 'Thank you for scheduling an appointment.',
  thankYouNoteText2: 'We look forward to seeing you on',
  limitedSlotsAvailableWarning:
    'Copart customers are advised to schedule appointments to pick up vehicles. Due to high demand, there may be limited slots available. Please proceed to book an appointment now.',
  memberRequestIdWarning: 'Enter a valid email or member number',
  notice: 'IMPORTANT:',
  lotValidity: 'Issue',
  facilityId: 'Facility Id',
  lotsStatusHeading: 'Schedule Appointment',
  success: 'Success',
  welcomeLabel: 'Welcome!',
  back: 'Back',
  serviceFailure: 'Service Failure, Please retry',
  slotsNotAvailableMessage:
    'Slots not available. For assistance click the button below to enter Visitor Sign-In.',
  slotsNotAvailableHeader: 'No Slots Available',
  spotsNotAvailable: 'No Available Spots',
  markAsArrived: 'Mark as arrived',
  joinOfficeQueue: 'Enter Visitor Sign-In',
  lotNumbers: 'Lot Numbers',
  pickupAppointment: 'Your Pickup Appointment',
  lotStatus: 'Pickup Status',
  awaiting_claim: 'Pick-Up Request Received',
  awaiting_schedule: 'Awaiting Schedule',
  awaiting_confirmation: 'Awaiting Confirmation',
  awaiting_arrival: 'Awaiting Arrival',
  awaiting_stage: 'Retrieving Vehicle',
  awaiting_delivery: 'Retrieving Vehicle',
  awaiting_lot_left_yard: 'Delivered',
  awaiting_drop_off: 'Awaiting Drop Off',
  completed: 'Completed',
  enterRegisteredNumber: 'Enter registered mobile number',
  phoneNumberInvalidWarning:
    'Please make sure you enter a valid mobile phone number',
  submit: 'Submit',
  cannotLoadData: 'Cannot Load data',
  memberNumber: 'Member number',
  gatePassPin: 'Gate Pass PIN',
  appointmentBadgeHeading:
    'For an easier booking experience, download our mobile app!',
  appointmentBookingPoint1: '• Manage appointments',
  appointmentBookingPoint2: '• Customize reminders',
  appointmentBookingPoint3: '• Many other great features',
  joinOfficeQueueAndBookAppointmentHelpText:
    'Please click the button below to schedule a new appointment.',
  selectAppointmentTime: 'Select Appointment Time',
  lotsSelected: 'Lots Selected',
  morningTime: 'Morning Time',
  afternoonTime: 'Afternoon Time',
  eveningTime: 'Evening Time',
  validatingLotsMessage: 'Validating Lots...',
  scheduleappointment: 'Schedule Appointment',
  selectdate: 'Select Date',
  markAsArrivedHelpText:
    'Please click the button below to mark the appointment as arrived.',
  shareLocationForMarkAsArrived:
    'Please enable location sharing to mark as arrived. You can do this by changing your location settings in browser.',
  noOfVehicles: 'No. of Vehicles to be picked up',
  locationNotmatchingnonPilot:
    'Please make sure you are at the Copart Location where the vehicle(s) are to be picked up.',
  joinOfficeQueueHelpText:
    'Please click the button below if you need to enter the office to pick up titles or keys or to make payments.',
  openSlots: 'Open Spots',
  spotsRequired: 'Spots Required',
  lotValidationPopupHelpText:
    'One or more lots has an issue that must be corrected before the lot can be scheduled for pick-up. Please correct or remove the lot(s) from your request.',
  confirmPhoneNumber:
    'Please confirm your phone number to fetch the appointment details',
  lot: 'Lot',
  reasonsLabel: 'Reason(s) (Select all that apply)',
  chooseCopartLocation: 'Choose Copart location',
  checkSchedule: 'Check Schedule',
  scheduleNewAppointment: 'Schedule new appointment',
  showAppointment: 'Show appointment',
  clearLotDue:
    'Please pay balance due to schedule appointment. Click Enter Visitor Sign-In for additional assistance.',
  amountDue: 'Amount Due',
  cancelSuccess: 'Appointment cancelled successfully',
  loading: 'Loading',
  cancelAppointment: 'Cancel Appointment',
  checkForLotsPaidStatus: 'Checking for lots paid status',
  fetchLotsPaidStatusFail: 'Fetching Lot Paid Status Failed',
  checkingYardProximity: 'Checking yard proximity',
  fetchingYardCoordinatesFail: 'Fetching Yard Coordinates Fail',
  futureAppointmentDate: 'This Appointment is scheduled for a future date.',
  notWithinTimeRangeToMarkArrive:
    'Please mark arrive your appointment not more than 2 hours before the appointment start time',
  appointmentWarning:
    'You already have marked as arrived, Please make sure you are in Copart location and ready to pickup vehicle(s) on the appointment time.',
  locationNotShared:
    'Please enable location sharing to mark as arrived. You can do this by changing your location settings in browser (Settings > Privacy > Location Services).',
  locationNotMatching: `Location services shows you are outside of the facility's range. Please ensure you are within the range as outlined in the image below before marking yourself arrived.`,
  lotsUnPaidText:
    'Just to remind you, There is an outstanding balance on one [or]more number of lots in this appointment. Please make sure you make payment on the lots before you arrive at the yard for your appointment.',
  ok: 'Ok',
  towProviderSelfServeFormFillFormHelpText: (
    <>
      Please fill out the <span style={{ color: 'red' }}>mandatory</span>{' '}
      information below to join the office queue.
    </>
  ),
  nonTowProviderSelfServeFormFillFormHelpText:
    'Please fill out the mandatory information below to enter Visitor Sign-In.',
  appointmentCancelledText: 'This appointment has been cancelled',
  or: 'OR',
  sendSMSHelpText:
    "To mark as arrived or see details of your current appointment, please click 'Send Text Message' below to send out the appointment link to your phone number on file.",
  sendSMS: 'Send Text Message',
  resendSMS: 'Resend Text Message',
  clickHere: 'Click here to resend',
  sentSMSSuccess: 'Message sent successfully',
  cancelAppointmentHelpText:
    'If you want to cancel the appointment please click below.',
  appointmentCreatedText:
    'Your vehicle pickup appointment is scheduled successfully. We sent you a text message with more details',
  appointmentCreatedShortText: 'We sent you a text message with more details.',
  appointmentCompletedText: 'This appointment has been completed',
  locationNotSharedForQueue:
    'Please enable location sharing to continue. You can do this by changing your location settings in browser (Settings > Privacy > Location Services).',
  locationNotMatchingForQueue: `Location services shows you are outside of the facility's range. Please ensure you are within the range as outlined in the image below to continue.`,
  didYouKnow: 'Did you know?',
  payOnline: 'Copart offers several ways to pay online.',
  learnMore: 'Learn more',
  'Unexpected error, could not verify the yard availability status':
    'Unexpected error, could not verify the yard availability status',
  gatepassQuestion1: 'Need a Gate Pass PIN?',
  gatepassInfo1: `If you are a Transporter, please reach out to the member to obtain the correct gate pass PIN.
  If you are a Member, you can obtain the PIN by either accessing Copart.com or the Member Mobile Application.`,
  gatepassQuestion2: `How can the member access their
   unique Gate Pass PIN?`,
  gatepassInfo2: `The member can locate the PIN by signing into Copart.com or the Member Mobile application. After signing in, the member must navigate to their “Payments” tab. Here, the gate pass pin will be displayed for any vehicle the member has won. The PIN will also be present in the members email when a lot is won.`,
  gotIt: 'Got It!',
  paymentMethodBannerMessage: (
    <span>
      Effective March 25th, 2024, Copart will no longer accept paper money
      orders as a payment method. Visit{' '}
      <a href="https://www.copart.com/payments" target="_blank">
        Copart.com/Payments
      </a>{' '}
      for all acceptable payment methods. You can also pay at MoneyGram and
      Western Union locations, as those payments are electronically transferred
      to Copart.
    </span>
  ),
  copartDeliveryErrorMessage:
    'Copart Delivery Lot(s) cannot be combined with regular Lots(s).'
}
