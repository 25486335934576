import fetcher from '../../utils/fetcher'
import { endpoints } from '../../config/endpoints'
import { substitute } from '../../utils'

export const markAsArrivedService = (ctx) => {
  const payload = {
    appointment_header_id: ctx.appointmentHeaderId,
    transporter_id: ctx.transporterId
  }
  return fetcher.post(endpoints.post.markAsArrived, payload).then((res) => {
    return res
  })
}

export const fetchYardCoordinates = (ctx) => {
  return fetcher
    .get(
      substitute(endpoints.get.yardCoordinates, { facilityId: ctx.facilityId })
    )
    .then((res) => {
      return res.data.data
    })
}
