import { pathOr, compose, map, head, values } from 'ramda'

import fetcher from '../../../utils/fetcher'
import { endpoints } from '../../../config/endpoints'
import {
  transformAppointmentData,
  substitute,
  getSource,
  parseReferenceData,
  getMode,
  mapPickupLocationForSummary
} from '../../../utils'
import { getPropertyFromLocalStorage } from '../../../utils/storage'
import { TIMEZONE_MAP } from '../../../constants'

export const fetchAppointmentDetailsService = (ctx) => {
  const phoneNumber = getPropertyFromLocalStorage('phoneNumber')
  let appointmentResp
  return fetcher
    .get(
      substitute(
        endpoints.get.appointmentSummaryByAppointmentIdAndPhoneNumber,
        {
          appointmentId: ctx.requestId,
          phoneNumber
        }
      )
    )
    .then((res) => {
      const yardDetailsPromise = fetcher.get(
        substitute(endpoints.get.yardDetails, {
          facilityId: res.data.data.data.facility_id
        })
      )
      appointmentResp = res
      return yardDetailsPromise
    })
    .then((yardDetails) => {
      const timeZone = TIMEZONE_MAP[yardDetails.data.data.time_zone]
      return transformAppointmentData(appointmentResp.data.data, timeZone)
    })
}

export const fetchLocationDetailsService = (ctx) => {
  return fetcher
    .post(endpoints.post.closestYard, {
      skip_test_yards: getMode() !== 'test'
    })
    .then((res) => ({ locationDetails: res.data.data }))
}

export const fetchLoaderBundleService = () => {
  return fetcher.get(endpoints.get.loaderBundleStatuses).then((res) => {
    return {
      loaderBundles: res.data.data
    }
  })
}
export const fetchPickupSublotInfoService = (ctx) => {
  const facilityId =
    typeof ctx === 'object' ? ctx?.appointmentData?.facilityId : ctx
  const payload = {
    facility_ids: [facilityId]
  }
  return fetcher
    .post(endpoints.post.getFacilityInfo, payload, {
      source: 'mobile'
    })
    .then((res) => {
      return mapPickupLocationForSummary(res.data?.data[0])
    })
}

export const joinQueue = (ctx, event) => {
  const payload = {
    user_type: ctx.userType, //  take it from a constant
    first_name: ctx.appointmentData?.firstName,
    last_name: ctx.appointmentData?.lastName,
    phone_number: ctx?.form?.inputs?.phoneNumber,
    lots_count: ctx.appointmentData?.lotDetails?.length,
    yard_number: ctx.appointmentData?.facilityId,
    state: ctx.appointmentData?.stateCode,
    source: getSource(),
    is_text_allowed: 'Y', // TODO: change it later
    lots: ctx.appointmentData?.lotDetails?.map((item) => ({
      lot_number: item.lotNumber,
      reasons: item.reasons
    })),
    latitude: ctx.latitude,
    longitude: ctx.longitude
  }

  return fetcher.post(endpoints.post.joinQueue, payload).then((res) => {
    const uniqueId = pathOr({}, ['data', 'data', 'uniq_id'], res)
    return { virtualQueueId: uniqueId }
  })
}

export const fetchReasonsReferenceData = (ctx) => {
  return fetcher
    .post(endpoints.post.references, { data_type: 'reasons' })
    .then((res) => {
      const filteredData = compose(
        map(head),
        values,
        pathOr({}, ['data', 'data', 'reasons'])
      )(res)
      return {
        reasons: parseReferenceData('reasons', filteredData)
      }
    })
}

export const cancelAppointment = (ctx) => {
  return fetcher
    .delete(endpoints.delete.cancelAppointment, {
      appointment_header_ids: [ctx.appointmentData.appointmentHeaderId],
      transporter_id: ctx.appointmentData.transporterId

      // transporter_details: {
      //   phone_number: ctx.form?.inputs?.parsedPhone?.rawPhoneNumber,
      //   transporter_id: ctx.appointmentData.transporterId
      // }
    })
    .then((res) => res)
}
export const fetchYardCoordinates = (ctx) => {
  return fetcher
    .get(
      substitute(endpoints.get.yardCoordinates, {
        facilityId: ctx?.appointmentData?.facilityId
      })
    )
    .then((res) => {
      return res.data.data
    })
}
