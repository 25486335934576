import React from 'react'
import { Icon } from 'semantic-ui-react'
import locale from '../../utils/locale'

import { sendEventGA } from '../../utils/sendAnalytics'

const bannerProps = {
  appStoreLink:
    'https://apps.apple.com/us/app/copart-transportation/id1410332198',
  googlePlayLink:
    'https://play.google.com/store/apps/details?id=com.copart.transportation'
}

const logGAEvent = (actionText) =>
  sendEventGA({ category: 'Banner', action: actionText })

const Link = ({ link, children, actionText = '' }) => (
  <a
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    onClick={() => logGAEvent(actionText)}
  >
    {children}
  </a>
)

const AppointmentBanner = () => {
  return (
    <div className="AppointmentBadge">
      <div className="header">{locale('appointmentBadgeHeading')}</div>
      <div className="AppointmentBookingText">
        <span className="subText">
          <div>{locale('appointmentBookingPoint1')}</div>
          <div>{locale('appointmentBookingPoint2')}</div>
          <div>{locale('appointmentBookingPoint3')}</div>
        </span>
        <span className="phoneIcon">
          <Icon
            name="mobile alternate"
            style={{ color: 'white', fontSize: '60px' }}
          />
        </span>
      </div>

      <div className="badges">
        <Link
          link={bannerProps.googlePlayLink}
          actionText="Clicked on Google Play Store"
        >
          <img
            alt="Get it on Google Play"
            src={`${process.env.PUBLIC_URL}/images/badges-google-play-dark.png`}
          />{' '}
        </Link>
        <Link
          link={bannerProps.appStoreLink}
          actionText="Clicked on Apple App Store"
        >
          <img
            alt="Download on the Apple App Store"
            src={`${process.env.PUBLIC_URL}/images/badges-apple-dark.png`}
          />
        </Link>
      </div>
    </div>
  )
}

export default AppointmentBanner
