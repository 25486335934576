import { Machine } from 'xstate'
import { stampCustomLog } from '../../utils/logs'

const machine = Machine({
  id: 'markAsArrived',
  initial: 'checkingForMarkAsArrivedConditions',
  context: {},
  on: {
    LOCATION_DETAILS_CHANGE: {
      actions: 'setLatitudeAndLongitude',
      target: '.checkingForMarkAsArrivedConditions'
    }
  },
  states: {
    checkingForMarkAsArrivedConditions: {
      always: [
        {
          cond: 'hasLotsDue',
          target: 'lotsUnPaid'
        },
        {
          cond: 'isAppointmentScheduledForFutureDay',
          target: 'futureDayAppointment'
        },
        {
          cond: 'isAppointmentMarkedAsArrived',
          target: 'alreadyArrivedAppointment'
        },
        {
          cond: 'isNotWithinTimeRange',
          target: 'notWithinTimeRange'
        },
        {
          cond: 'isLocationShared',
          target: 'fetchingYardCoordinates'
        },
        {
          target: 'locationNotShared'
        }
      ]
    },
    fetchingYardCoordinates: {
      meta: { warning: 'checkingYardProximity' },
      invoke: {
        id: 'fetchYardCoordinates',
        src: 'fetchYardCoordinates',
        onDone: [
          {
            target: 'markAsArrivedEnabledState',
            cond: 'locationMatching'
          },
          {
            target: 'locationNotMatching',
            actions: ['setFacilityDetails']
          }
        ],
        onError: {
          target: 'fetchingYardCoordinatesFailed'
        }
      }
    },
    fetchingYardCoordinatesFailed: {
      meta: {
        error: 'fetchingYardCoordinatesFail'
      },
      on: {
        RETRY: {
          target: 'fetchingYardCoordinates'
        }
      }
    },
    futureDayAppointment: {
      meta: {
        warning: 'futureAppointmentDate'
      }
    },
    alreadyArrivedAppointment: {
      initial: 'checkingIfAppointmentDateIsPastDayOrToday',
      states: {
        checkingIfAppointmentDateIsPastDayOrToday: {
          always: [
            {
              cond: 'isAppointmentScheduledForPast',
              target: 'pastDay'
            },
            {
              target: 'today'
            }
          ]
        },
        pastDay: {},
        today: {
          meta: {
            warning: 'appointmentWarning'
          }
        }
      }

      // here we should show 'Show appointment summary' button
    },
    notWithinTimeRange: {
      tags: ['notWithinTimeRange'],
      meta: {
        warning: 'notWithinTimeRangeToMarkArrive'
      }
    },
    locationNotShared: {
      meta: {
        warning: 'locationNotShared'
      }
    },
    locationNotMatching: {
      meta: {
        warning: 'locationNotMatching'
      }
    },
    lotsUnPaid: {
      meta: {
        warning: 'lotsUnPaidText'
      }
    },
    markAsArrivedEnabledState: {
      initial: 'idle',
      on: {
        MARK_AS_ARRIVED: {
          target: '.markingAsArrived'
        }
      },
      states: {
        idle: {},
        markingAsArrived: {
          invoke: {
            id: 'markAsArrivedService',
            src: 'markAsArrivedService',
            onDone: {
              actions: [
                (ctx) => {
                  stampCustomLog({
                    currentState: 'markedAsArrived',
                    event: 'MARK_AS_ARRIVED',
                    facilityId: ctx.facilityId
                  })
                }
              ],
              target: '#markAsArrived.alreadyArrivedAppointment'
            },
            onError: {
              actions: ['setServerError'],
              target: 'error'
            }
          }
        },
        error: {
          tags: ['serverError'],
          exit: ['clearError']
        }
      }
    }
  }
})

export default machine
