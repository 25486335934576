import React from 'react'
import locale from '../../utils/locale'
import { CopartLogo } from '../../components/Logo'

const LogoBanner = ({ backToHome = () => {} }) => (
  <div className="LogoBanner">
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a
      onClick={(event) => {
        event.stopPropagation()
        backToHome()
      }}
    >
      <CopartLogo width="100px" />
    </a>
    <div className="LogoBannerText">{locale('selfServeKiosk')}</div>
  </div>
)

export default LogoBanner
