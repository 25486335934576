import React from 'react'
import { Icon } from 'semantic-ui-react'
import './style.css'

const CustomWarningMessage = ({ message }) => {
  return (
    message && (
      <div className="customWarningMessage">
        <Icon name="warning" />
        {message}
      </div>
    )
  )
}

export default CustomWarningMessage
