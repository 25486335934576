import ReactGA from 'react-ga'

const GA_TRACKING_ID = {
  c: '245192085',
  uk: '251110781',
}[process.env.REACT_APP_STACK || 'c']

export const initializeGA = () => {
  if (window.location.host.includes('kiosk.copart')) {
    ReactGA.initialize(GA_TRACKING_ID, {
      gaOptions: {
        name: 'self-serve-kiosk-tracker',
      },
    })
  }
}

export const sendPageView = (route, trackerNames, title) => ReactGA.pageview(route, trackerNames, title)

export const sendEventGA = (eventObj) => ReactGA.event(eventObj)
