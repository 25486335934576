import { assign } from '@xstate/immer'
import { actions, spawn, sendParent, send } from 'xstate'
import { v4 as uuid } from 'uuid'
import lotItemMachine from '../lotItem'

const { choose, pure } = actions

const getItemObjectToAdd = (reasons, userType, facilityId, memberNumber) => ({
  id: uuid(),
  lot_number: '',
  member_number: memberNumber || '',
  gate_pass_pin: '',
  reasons: [...reasons],
  userType,
  facilityId,
  showPopup: false
})

export const setNumberOfLots = assign((ctx, event) => {
  ctx.numberOfLots = event.value
})

export const addOrRemoveLotItems = choose([
  {
    cond: 'isNumberOfLotsGreaterThanLotsAdded',
    actions: 'addLots'
  },
  {
    cond: 'isNumberOfLotsEqualToLotsAdded',
    actions: 'addLots'
  },
  {
    actions: 'removeLots'
  }
])

export const entryAction = choose([
  {
    cond: (ctx) => {
      return ctx.lotInfoItems.length === 0
    },
    actions: 'addLot'
  },
  {}
])

export const addLots = assign((ctx, _, meta) => {
  const numberOfExtraLots = Number(ctx.numberOfLots) - ctx.lotInfoItems.length

  for (let count = 1; count <= numberOfExtraLots; count++) {
    const lotItem = getItemObjectToAdd(
      ctx.reasons,
      ctx.userType,
      ctx.facilityId,
      ctx.memberNumber
    )
    ctx.lotInfoItems.push({
      ...lotItem,
      lotItemRef: spawn(
        lotItemMachine.withContext({
          ...lotItem
        }),
        { name: `lotItem-${lotItem.id}` }
      )
    })
  }
})

export const removeLots = assign((ctx) => {
  ctx.lotInfoItems = ctx.lotInfoItems
    .map((lotInfo, index) => {
      if (index > ctx.numberOfLots - 1) {
        lotInfo.lotItemRef.stop()
        return undefined
      }
      return lotInfo
    })
    .filter((lotInfo) => lotInfo)
})

export const removeLotItem = assign((ctx, event) => {
  const removedLotItem = ctx.lotInfoItems.find((lot) => lot.id === event.id)
  removedLotItem.lotItemRef.stop()
  ctx.lotInfoItems = ctx.lotInfoItems.filter((lot) => lot.id !== event.id)
})

export const changeNumberOfLotsField = assign((ctx, event) => {
  ctx.numberOfLots = ctx.lotInfoItems.length
})

export const updateLotItem = assign((ctx, event) => {
  ctx.lotInfoItems = ctx.lotInfoItems.map((lotInfo) => {
    if (lotInfo.id === event.data.id) {
      return { ...lotInfo, ...event.data }
    }
    return { ...lotInfo }
  })
})

export const addLot = assign((ctx, _, meta) => {
  const lotItem = getItemObjectToAdd(
    ctx.reasons,
    ctx.userType,
    ctx.facilityId,
    ctx.memberNumber
  )
  ctx.lotInfoItems = [
    ...ctx.lotInfoItems,
    {
      ...lotItem,
      lotItemRef: spawn(
        lotItemMachine.withContext({
          ...lotItem
        }),
        { devTools: true, name: `lotItem-${lotItem.id}` }
      )
    }
  ]
})

export const updateNumberOfLotsToForm = sendParent((ctx) => ({
  type: 'FORM_FIELD_CHANGE',
  name: 'numberOfLots',
  value: ctx.numberOfLots
}))

export const updateLotInfoItemsToForm = sendParent((ctx) => ({
  type: 'LOT_INFO_ITEMS_CHANGE',
  name: 'lotInfoItems',
  value: ctx.lotInfoItems
}))

export const setTowProviderReasons = assign((ctx) => {
  if (!ctx.reasons.includes('PKVEH')) {
    ctx.reasons.push('PKVEH')
  }
})

export const removeTowProviderReasons = assign((ctx) => {
  ctx.reasons = ctx.reasons.filter((reason) => reason !== 'PKVEH')
})

export const sendMemberNumberUpdateEventToLotItemMachine = pure(
  (context, event) => {
    return context.lotInfoItems.map((lotItem) => {
      return send(
        {
          type: 'MEMBER_NUMBER_UPDATE_FROM_PARENT',
          value: event.memberNumber
        },
        { to: lotItem.lotItemRef }
      )
    })
  }
)

export const setMemberNumber = assign((ctx, event) => {
  ctx.memberNumber = event.memberNumber
})
