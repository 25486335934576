import React, { useEffect } from 'react'
import {
  Dropdown,
  Button,
  Form,
  Message,
  Divider,
  Icon
} from 'semantic-ui-react'
import renderIf from 'render-if'

import LotInfoItems from './LotInfoItems'
import LabelWithRequired from '../../components/Label'
import WelcomeBanner from './WelcomeBanner'
import LogoBanner from './LogoBanner'
import { mergeMeta, stateMatchingConditionCheck } from '../../utils'
import { isBlank } from '../../utils/isBlank'
import locale from '../../utils/locale'
import ScheduleAppointment from './ScheduleAppointment'

import './style.css'
import LotsInvalid from './LotsInvalid'
import Snackbar from '../../components/Snackbar'
import WarningMessage from '../../components/WarningMessage'
import ErrorMessage from '../../components/ErrorMessage'
import LocaledText from '../../components/LocaledText'

const dividerStyle = {
  marginLeft: '-15px',
  marginRight: '-15px'
}
const getTruckTypes = (truckTypes) =>
  truckTypes.map((type) => ({
    ...type,
    text: <LocaledText text={type.text} />,
    content: <LocaledText text={type.text} />
  }))

export default function SelfServeForm(props) {
  const { userTypes, truckTypes, states, mapData, state, send } = props

  const { context } = state

  const { pickupLots, isCopartDelivery } = context

  const {
    form: { inputs }
  } = context

  const { userType } = inputs

  const renderWholeForm = renderIf(
    stateMatchingConditionCheck(state, [
      'towProvider.selfServeForm',
      'nonTowProvider.member',
      'nonTowProvider.nonMember'
    ])
  )

  return (
    <>
      <LogoBanner
        backToHome={() => {
          send('BACK_TO_HOME_PAGE')
        }}
      />
      <div className="selfserve-form-container">
        {renderWholeForm(
          <>
            <WelcomeBanner
              facilityId={inputs.facilityId}
              stateCode={inputs.stateCode}
              firstName={inputs.firstName}
              userType={
                userTypes.find((userTypeRef) => userTypeRef.key === userType)
                  ?.text
              }
              backToHome={() => send('BACK_TO_HOME_PAGE')}
              fillInformationHelpText={locale(
                mergeMeta(state.meta)?.fillInformationHelpText
              )}
            />
            <Divider style={dividerStyle} />
            <Form className="attached fluid selfServeForm">
              {state.hasTag('memberEditingForm') && (
                <Form.Field>
                  <LabelWithRequired labelText="enterMemberId" />
                  <Form.Input
                    fluid
                    placeholder={locale('enterMemberId')}
                    type="text"
                    maxLength={255}
                    value={inputs?.memberRequestId}
                    onChange={(e, { value }) =>
                      send('MEMBER_REQUEST_ID_CHANGE', {
                        name: 'memberRequestId',
                        value
                      })
                    }
                    data-testid="memberId"
                  />
                  {!isBlank(inputs?.memberRequestId) &&
                    !inputs?.isMemberRequestIdValid && (
                      <div className="memberRequestIdWarning">
                        <Icon name="warning" color="red" />
                        <span className="subtext-label">
                          {locale('memberRequestIdWarning')}
                        </span>
                      </div>
                    )}
                </Form.Field>
              )}
              <Form.Group unstackable widths={2}>
                <Form.Field>
                  <Form.Input
                    fluid
                    label={locale('firstName')}
                    placeholder={locale('enterFirstName')}
                    type="text"
                    required
                    maxLength={255}
                    value={inputs.firstName}
                    onChange={(e, { value }) =>
                      send('FORM_FIELD_CHANGE', { name: 'firstName', value })
                    }
                    data-testid="firstName"
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    fluid
                    label={locale('lastName')}
                    placeholder={locale('enterLastName')}
                    type="text"
                    required
                    maxLength={255}
                    value={inputs.lastName}
                    onChange={(e, { value }) =>
                      send('FORM_FIELD_CHANGE', { name: 'lastName', value })
                    }
                    data-testid="lastName"
                  />
                </Form.Field>
              </Form.Group>
              {stateMatchingConditionCheck(state, [
                'towProvider.selfServeForm'
              ]) && (
                <>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <LabelWithRequired labelText="truckType" />
                      <Dropdown
                        selection
                        clearable
                        name="truckType"
                        placeholder={locale('enterTruckType')}
                        value={inputs.truckType}
                        onChange={(e, { value }) => {
                          send('FORM_FIELD_CHANGE', {
                            name: 'truckType',
                            value
                          })
                        }}
                        renderLabel={({ content }) => ({ content })}
                        options={getTruckTypes(truckTypes)}
                        data-testid="truckType"
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group unstackable widths={2}>
                    <Form.Field>
                      <LabelWithRequired labelText={locale('licenseState')} />
                      <Dropdown
                        selection
                        clearable
                        name="licenseState"
                        placeholder={locale('enterLicenseState')}
                        value={inputs.licenseState}
                        onChange={(e, { value }) => {
                          send('FORM_FIELD_CHANGE', {
                            name: 'licenseState',
                            value
                          })
                        }}
                        renderLabel={({ content }) => ({ content })}
                        options={states}
                        className="dropdownField"
                        data-testid="licenseState"
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Input
                        fluid
                        name="licensePlateNumber"
                        label={locale('licensePlateNumber')}
                        placeholder={locale('enterLicensePlateNumber')}
                        type="text"
                        required
                        maxLength={255}
                        value={inputs.licensePlateNumber}
                        onChange={(e, { value }) =>
                          send('FORM_FIELD_CHANGE', {
                            name: 'licensePlateNumber',
                            value
                          })
                        }
                        data-testid="licensePlateNumber"
                      />
                    </Form.Field>
                  </Form.Group>
                </>
              )}
              <LotInfoItems
                lotInfoItemsMachineRef={context.lotInfoItemsMachineRef}
                pickupLots={pickupLots}
                isCopartDelivery={isCopartDelivery}
              />
              <Message attached="bottom">
                <Form.Checkbox
                  label={locale('textAllowed')}
                  onChange={() => send('TOGGLE_TEXT_ALLOWED')}
                  checked={inputs?.textAllowed}
                  data-testid="textAllowed"
                />
                <span className="DisclaimerText">
                  {locale('disclaimerText')}
                </span>
              </Message>
            </Form>
          </>
        )}
        <br />
        {state.matches('towProvider') && (
          <WarningMessage
            message={
              <span>
                <b>{locale('notice')} </b>
                {locale('limitedSlotsAvailableWarning')}
              </span>
            }
            data-testid="limitedSlotsAvailableWarning"
          />
        )}
        {state.hasTag('error') && context.error && (
          <ErrorMessage message={context.error} data-testid="error" />
        )}
        {state.matches('towProvider.selfServeForm') && (
          <Button
            fluid
            disabled={
              !state.nextEvents.includes('CHECK_SCHEDULE') ||
              isCopartDelivery === 'error'
            }
            onClick={() => send('CHECK_SCHEDULE')}
            loading={state.hasTag('checkingForLotsValidity')}
            primary
            data-testid="continue"
          >
            {locale('checkSchedule')}
          </Button>
        )}
        {state.matches('nonTowProvider') && (
          <Button
            fluid
            disabled={!state.hasTag('errorFree')}
            onClick={() => send('JOIN_OFFICE_QUEUE')}
            loading={state.hasTag('joiningOfficeQueue')}
            primary
            data-testid="continue"
          >
            {locale('joinQueue')}
          </Button>
        )}
        {state.hasTag('checkingForLotsValidity') && (
          <Snackbar message={locale('validatingLotsMessage')} />
        )}
        {stateMatchingConditionCheck(state, [
          'towProvider.selfServeForm.schedulingAppointment'
        ]) && (
          <ScheduleAppointment
            state={state}
            send={send}
            joinOfficeQueue={() => send('JOIN_OFFICE_QUEUE')}
            mapData={mapData}
          />
        )}
        {state.hasTag('lotsInvalid') && (
          <LotsInvalid
            close={() => {
              send('CLOSE')
            }}
            joinOfficeQueue={() => send('JOIN_OFFICE_QUEUE')}
            loading={state.hasTag('joiningOfficeQueue')}
            validatedPickupLots={context.validatedPickupLots}
          />
        )}
      </div>
    </>
  )
}
