import React from 'react'
import { Button, Divider } from 'semantic-ui-react'

import locale from '../../utils/locale'
import { stateMatchingConditionCheck, useLogging } from '../../utils'
import { useService } from '@xstate/react'
import ErrorMessage from '../../components/ErrorMessage'

const AppointmentPopup = ({ appointmentRef, mapData, show }) => {
  const [state, send] = useService(appointmentRef)
  useLogging(appointmentRef)

  const { context } = state

  return (
    show && (
      <>
        <div className="appointmentFoundContainer">
          <div className="helpText">{locale('sendSMSHelpText')}</div>
          <Button
            fluid
            onClick={() => {
              send('SEND_SMS')
            }}
            loading={state.matches('sendingSMS')}
            primary
            data-testid="sendSMS"
            style={{
              fontWeight: 'normal',
              backgroundColor: '#7dc033',
              marginBottom: '10px'
            }}
            disabled={!state.nextEvents.includes('SEND_SMS')}
          >
            {locale('sendSMS')}
          </Button>
          {stateMatchingConditionCheck(state, ['error']) && (
            <ErrorMessage message={context.error} data-testid="error" />
          )}
          {state.matches('waitingToResendSMS') && (
            <div className="SMSSuccessHelpText">{locale('sentSMSSuccess')}</div>
          )}
          {state.matches('resendSMS') && (
            <div className="helpText">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                onClick={() => send('RESEND_SMS')}
                style={{ cursor: 'pointer' }}
              >
                {' '}
                {locale('clickHere')}
              </a>
            </div>
          )}
          <Divider />
          <div className="helpText">
            {locale('joinOfficeQueueAndBookAppointmentHelpText')}
          </div>
          <div className="joinQueueButton">
            <Button
              fluid
              onClick={() => {
                send('BOOK_NEW_APPOINTMENT')
              }}
              data-testid="bookNewAppointment"
            >
              {locale('scheduleNewAppointment')}
            </Button>
          </div>
        </div>
      </>
    )
  )
}

export default AppointmentPopup
