import fetcher from './fetcher'
import { endpoints } from '../config/endpoints'
import { CORRELATION_ID } from '../constants'
import { getDeviceId } from './index'

const TAGS_TO_LOG = [
  'serverError',
  {
    tag: 'hasAppointment',
    events: ['done.invoke.getAppointmentDetailsService']
  },
  {
    tag: 'memberEditingForm',
    events: ['GOTO_SELF_SERVE_FORM', 'done.invoke.getAppointmentDetailsService']
  },
  {
    tag: 'transporterEditingForm',
    events: ['GOTO_SELF_SERVE_FORM', 'done.invoke.getAppointmentDetailsService']
  },
  {
    tag: 'landing',
    events: ['BACK_TO_HOME_PAGE', 'CLOSE']
  },

  { tag: 'lotsInvalid', events: ['done.invoke.checkForLotsValidityService'] },
  {
    tag: 'lotsFacilityInfo',
    events: ['done.invoke.getLotsFacilityInfo']
  },
  { tag: 'lotBillUnpaid', events: ['CONFIRM_APPOINTMENT'] },
  {
    tag: 'schedulingAppointment',
    events: ['done.invoke.checkForLotsValidityService']
  },
  'appointmentSummary',
  'kioskSummary',
  'appointmentWithKioskSummary',
  { tag: 'kioskFlow', events: ['GOTO_NON_TOW_PROVIDER_STATE', 'CONTINUE'] },
  { events: ['CLOSE'], tag: 'memberEditingForm' },
  { events: ['CLOSE'], tag: 'transporterEditingForm' },
  'cancelAppointmentSuccess'
]

const EVENTS_TO_LOG = [
  { event: 'SET_LATITUDE_AND_LONGITUDE', state: 'locationEnabled' },
  // { event: 'SET_LATITUDE_AND_LONGITUDE', state: 'locationEnabled' },

  { event: 'SEND_SMS', state: 'smsSent' },
  { event: 'RESEND_SMS', state: 'smsReSent' }
  // { event: 'MARK_AS_ARRIVED', state: 'markedAsArrived' }
]

const loggingService = (data) =>
  requestIdleCallback(() => {
    try {
      return fetcher
        .post(endpoints.get.logging, {
          ...data,
          uuid: CORRELATION_ID,
          logType: 'UI',
          userAgent: window?.navigator?.userAgent,
          time: new Date().toISOString(),
          deviceId: getDeviceId()
        })
        .then((res) => {
          return res
        })
    } catch (e) {
      console.error(e, 'logging failed: logging api failed')
    }
  })

export const stampLogs = (state) =>
  requestIdleCallback(() => {
    try {
      const tagToLog = TAGS_TO_LOG.find((tag) =>
        typeof tag === 'object'
          ? state.hasTag(tag?.tag) &&
            tag?.events?.find((event) => state.event?.type?.includes(event))
          : state.hasTag(tag)
      )
      const eventToLog = EVENTS_TO_LOG.find((event) =>
        state.event?.type?.includes(event?.event)
      )

      const currentState = tagToLog
        ? tagToLog?.tag || tagToLog
        : eventToLog?.state

      if (!!tagToLog || !!eventToLog) {
        loggingService({
          currentState,
          event: state.event?.type,
          facilityId: state.context?.form?.inputs?.facilityId,
          userType: state.context?.form?.inputs?.userType,
          reasons: state.context?.form?.inputs?.reasonCodes
        })
      }
    } catch (e) {
      console.error(e, 'logging failed: logging api failed')
    }
  })

export const stampCustomLog = (data) => {
  try {
    loggingService(data)
  } catch (e) {
    console.error(e, 'logging failed: logging api failed')
  }
}
