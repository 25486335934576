import { pathOr, values, compose, filter, head } from 'ramda'

const STANDARD_MARKER_HEIGHT = 26
const STANDARD_MARKER_WIDTH = 16
const SELECTED_FACILITY_MARKER_HEIGHT = 46
const SELECTED_FACILITY_MARKER_WIDTH = 30

export const mapConfig = {
  us: {
    initialCoords: {
      lat: 39.809734,
      lng: -98.55562,
    },
    zoomLevel: {
      initial: 4,
      facilitiesAvailable: 6,
    },
  },
  ca: {
    initialCoords: {
      lat: 51.969274447657575,
      lng: -100.6099199065111,
    },
    zoomLevel: {
      initial: 4,
      facilitiesAvailable: 6,
    },
  },
  gb: {
    initialCoords: {
      lat: 53.19697,
      lng: -2.99074,
    },
    zoomLevel: {
      initial: 5,
      facilitiesAvailable: 6,
    },
  },
  ie: {
    initialCoords: {
      lat: 52.90050762433793,
      lng: -8.109289226684568,
    },
    zoomLevel: {
      initial: 6,
      facilitiesAvailable: 6,
    },
  },
  ar: {
    initialCoords: {
      lat: 24.84208,
      lng: 55.07863,
    },
    zoomLevel: {
      initial: 6,
      facilitiesAvailable: 6,
    },
  },
  om: {
    initialCoords: {
      lat: 23.62016,
      lng: 58.02644,
    },
    zoomLevel: {
      initial: 6,
      facilitiesAvailable: 6,
    },
  },
  bh: {
    initialCoords: {
      lat: 23.783521700679593,
      lng: 49.919295096477306,
    },
    zoomLevel: {
      initial: 6,
      facilitiesAvailable: 6,
    },
  },
}

export const updateMarkerDimensions = (mapMarkers, yardNumber, enlarge) => ({
  [yardNumber]: {
    ...mapMarkers[yardNumber],
    props: {
      ...pathOr({}, [yardNumber, 'props'], mapMarkers),
      icon: {
        ...pathOr({}, [yardNumber, 'props', 'icon'], mapMarkers),
        scaledSize: {
          ...pathOr(
            {},
            [yardNumber, 'props', 'icon', 'scaledSize'],
            mapMarkers
          ),
          height: setMarkerHeight(enlarge),
          width: setMarkerWidth(enlarge),
        },
      },
    },
  },
})

export const setMarkerHeight = (enlarge) =>
  enlarge ? SELECTED_FACILITY_MARKER_HEIGHT : STANDARD_MARKER_HEIGHT

export const setMarkerWidth = (enlarge) =>
  enlarge ? SELECTED_FACILITY_MARKER_WIDTH : STANDARD_MARKER_WIDTH

const filterLatLng = ({ yardLat, yardLong }) => yardLat && yardLong

const removeFacilitiesWithoutLatLngs = (stateMapViewData) =>
  compose(filter(filterLatLng), values)(stateMapViewData)

// setting map center to the initial lat longs if the location coordinates are unavailable
export const getMapPositionProps = (
  countryCode,
  stateMapViewData,
  selectedYardNum,
  currMapCenter
) => {
  const { lat: currentLat, lng: currentLong } = currMapCenter
  const yardSelected = stateMapViewData[selectedYardNum]
  const { lat: defaultLat, lng: defaultLng } = mapConfig[
    countryCode || 'us'
  ].initialCoords

  if (!yardSelected) {
    const firstFacility = head(removeFacilitiesWithoutLatLngs(stateMapViewData))

    const defaultCenter = {
      lat: pathOr(defaultLat, ['yardLat'], firstFacility),
      lng: pathOr(defaultLng, ['yardLong'], firstFacility),
    }

    return {
      initialCenter: {
        lat: defaultCenter.lat,
        lng: defaultCenter.lng,
      },
      mapCenter: {
        lat: currentLat || defaultCenter.lat,
        lng: currentLong || defaultCenter.lng,
      },
    }
  }

  const yardLocationCenter = {
    lat: pathOr(defaultLat, [selectedYardNum, 'yardLat'], stateMapViewData),
    lng: pathOr(defaultLng, [selectedYardNum, 'yardLong'], stateMapViewData),
  }

  return {
    initialCenter: {
      lat: yardLocationCenter.lat,
      lng: yardLocationCenter.lng,
    },
    mapCenter: {
      lat: currentLat || yardLocationCenter.lat,
      lng: currentLong || yardLocationCenter.lng,
    },
  }
}
