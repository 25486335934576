import { pathOr, compose, head } from 'ramda'
import fetcher from '../../utils/fetcher'
import { getMode, substitute, getYardFromUrl } from '../../utils'
import { endpoints } from '../../config/endpoints'
import { TIMEZONE_MAP } from '../../constants'

export const getYardDetails = (ctx) => {
  const yardStatus = fetcher.get(
    substitute(endpoints.get.kioskStatus, {
      facilityId: ctx.facilityId
    })
  )
  const yardDetails = fetcher.get(
    substitute(endpoints.get.yardDetails, {
      facilityId: ctx.facilityId
    })
  )
  return Promise.all([yardStatus, yardDetails]).then((res) => {
    const yardClosedMessage = pathOr('', ['data', 'data'], res[0])
    const timeZone = pathOr('', ['data', 'data', 'time_zone'], res[1])

    return {
      yardClosedMessage,
      yardOpen: !yardClosedMessage,
      timeZone: TIMEZONE_MAP[timeZone] || 'Asia/Kolkata',
      latitude: pathOr(
        '',
        ['data', 'data', 'yard_location_0_coordinate'],
        res[1]
      ),
      longitude: pathOr(
        '',
        ['data', 'data', 'yard_location_1_coordinate'],
        res[1]
      )
    }
  })
}

export const getClosestYard = (ctx) => {
  const { latitude, longitude } = ctx
  return fetcher
    .post(endpoints.post.closestYard, {
      latitude,
      longitude,
      skip_test_yards: getMode() !== 'test'
    })
    .then((response) => {
      const defaultValues = [{ yard_number: '', yard_state_code: '' }]
      const { yard_number: facilityId, yard_state_code: stateCode } = compose(
        head,
        pathOr(defaultValues, ['data', 'data'])
      )(response)
      return {
        facilityId,
        stateCode
      }
    })
}
