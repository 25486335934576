import moment from 'moment-timezone'
import { getPropertyFromLocalStorage } from '../../../utils/storage'
import {
  APPOINTMENT_READY_TIME,
  APPOINTMENT_STATUSES,
  MARKARRIVE_READY_TIME
} from '../../../constants'
import {
  convertDateToYardTimeZone,
  checkWithRangeForYard
} from '../../../utils'

export const isAppointmentScheduledForPast = (ctx) =>
  convertDateToYardTimeZone(
    moment(ctx.appointmentData.startTime),
    ctx.timeZone
  ).isBefore(convertDateToYardTimeZone(moment(), ctx.timeZone), 'day')

export const isAppointmentScheduledForToday = (ctx) =>
  convertDateToYardTimeZone(moment(), ctx.timeZone).isSame(
    convertDateToYardTimeZone(
      moment(ctx.appointmentData.startTime),
      ctx.timeZone
    ),
    'day'
  )

export const checkWithinAppointmentSlot = (ctx) =>
  convertDateToYardTimeZone(moment(), ctx.timeZone).isBetween(
    convertDateToYardTimeZone(
      moment(ctx.appointmentData.startTime),
      ctx.timeZone
    ),
    convertDateToYardTimeZone(moment(ctx.appointmentData.endTime), ctx.timeZone)
  )

export const hasPhoneNumber = () =>
  Boolean(getPropertyFromLocalStorage('phoneNumber'))

export const isPhoneNumberValid = (ctx) => ctx.form.inputs?.parsedPhone?.isValid

export const anyLotHasTitleStatusOrHasKeysY = (ctx) => true
// this is a temproary fix. In future if the reserve place in line button has to be shown/hidden, this guard can be used
// any(pipe(props(['hasKeys', 'titleStatus']), any(equals('Y'))))(
//   ctx.appointmentData.lotDetails
// )

export const isWithin30MinsFromAppointment = (ctx) =>
  convertDateToYardTimeZone(
    moment(ctx.appointmentData.startTime),
    ctx.timeZone
  ).diff(convertDateToYardTimeZone(moment(), ctx.timeZone), 'minutes') <=
  APPOINTMENT_READY_TIME

export const isNotWithinTimeRange = (ctx) =>
  convertDateToYardTimeZone(
    moment(ctx.appointmentData.startTime),
    ctx.timeZone
  ).diff(convertDateToYardTimeZone(moment(), ctx.timeZone), 'hours') >=
  MARKARRIVE_READY_TIME

export const hasAlreadyPassedAppointmentTime = (ctx) =>
  convertDateToYardTimeZone(
    moment(ctx.appointmentData.startTime),
    ctx.timeZone
  ).isBefore(convertDateToYardTimeZone(moment(), ctx.timeZone))

export const isAppointmentCancelled = (ctx) =>
  [APPOINTMENT_STATUSES.CANCELLED, APPOINTMENT_STATUSES.RESCHEDULED].includes(
    ctx.appointmentData.appointmentStatus
  )

export const isAppointmentCompleted = (ctx) =>
  [APPOINTMENT_STATUSES.COMPLETED].includes(
    ctx.appointmentData.appointmentStatus
  )

export const isLocationShared = (ctx, event) => {
  const markArriveRefCtx = ctx?.markAsArrivedMachineRef?.state?.context
  return !!markArriveRefCtx.latitude && !!markArriveRefCtx.longitude
}
export const isLocationNotMatching = (ctx, event) => {
  const markArriveRefCtx = ctx?.markAsArrivedMachineRef?.state?.context
  const yardData = event?.data
  return !checkWithRangeForYard(
    {
      lat: markArriveRefCtx.latitude,
      long: markArriveRefCtx.longitude
    },
    {
      lat: yardData?.yard_location_0_coordinate,
      long: yardData?.yard_location_1_coordinate
    }
  )
}
