import moment from 'moment-timezone'
import { checkWithRangeForYard, convertDateToYardTimeZone } from '../../utils'
import { APPOINTMENT_STATUSES, MARKARRIVE_READY_TIME } from '../../constants'

export const hasLotsDue = (ctx) => {
  return ctx.lots?.some((lot) => lot.billPaid === 'N')
}

export const isAppointmentScheduledForFutureDay = (ctx) => {
  return convertDateToYardTimeZone(
    moment(ctx.appointmentDate),
    ctx.timeZone
  ).isAfter(convertDateToYardTimeZone(moment(), ctx.timeZone), 'day')
}
export const isNotWithinTimeRange = (ctx) =>
  convertDateToYardTimeZone(moment(ctx.appointmentDate), ctx.timeZone).diff(
    convertDateToYardTimeZone(moment(), ctx.timeZone),
    'hours'
  ) >= MARKARRIVE_READY_TIME

export const isAppointmentMarkedAsArrived = (ctx) => {
  return ctx.appointmentStatus !== APPOINTMENT_STATUSES.PENDING
}

export const isLocationShared = (ctx) => {
  return !!ctx.latitude && !!ctx.longitude
}

export const isLocationNotMatchingWithLotYard = (ctx) => {
  return Number(ctx?.facilityId) !== Number(ctx.closestYard?.facilityId)
}

export const isSummaryPage = (ctx) => {
  return ctx.isInSummaryPage
}

export const locationMatching = (ctx, event) =>
  checkWithRangeForYard(
    { lat: ctx.latitude, long: ctx.longitude },
    {
      lat: event.data.yard_location_0_coordinate,
      long: event.data.yard_location_1_coordinate
    }
  )

export const isAppointmentScheduledForPast = (ctx) =>
  convertDateToYardTimeZone(moment(ctx.appointmentDate), ctx.timeZone).isBefore(
    convertDateToYardTimeZone(moment(), ctx.timeZone),
    'day'
  )
