export const towProviderData = {
  data: {
    driverDetails: {
      firstName: 'John',
      lastName: 'Viel',
      email: 'john.veil@gmail.com'
    },
    hasAppointment: false,
    appointmentDetails: {
      name: 'John',
      typeOfUser: 'Transporter',
      scheduledTime: '3:00 PM CST',
      scheduledDate: 'March 24, 2021',
      lotNumbers: [566789876, 556789455],
      address: '505 Idlewood Rd, Grand Prairie, TX 75051'
    }
  }
}

export const lotsPaid = {
  status: 'success',
  data: {
    44456128: {
      status: 'error',
      errors: {
        invalid_lot: ['Lot (44456128) not found.'],
        invalid_qr: ['Invalid QRCode']
      },
      code: 400
    },
    77971669: {
      status: 'error',
      errors: {
        lot_code: ['Provided code is invalid.']
      },
      code: 400
    },
    70965302: {
      status: 'error',
      errors: {
        ineligible_lot: ['Lot has missing lot bill.']
      },
      code: 400
    },
    12278978: {
      status: 'success',
      buyer_number: 12238
    }
  }
}

export const slots = {
  data: [
    {
      status: 'A',
      created_at: '2019-09-20T13:25:55+00:00',
      created_by: 'OPSPORTUSR',
      updated_at: '2020-02-24T16:57:49+00:00',
      updated_by: 'datamig@copart.com',
      source_system: 'YP14024',
      facility_schedule_id: 66327,
      schedule_type_code: 'BYRPU',
      facility_id: 68,
      start_datetime: '2021-04-08T16:00:00+00:00',
      end_datetime: '2021-04-08T17:00:00+00:00',
      capacity: 200,
      scheduled_status: null,
      scheduled_lots: 0,
      unscheduled_lots: null
    },
    {
      status: 'A',
      created_at: '2019-09-27T10:37:48+00:00',
      created_by: 'sidasaleti',
      updated_at: '2020-02-24T16:57:49+00:00',
      updated_by: 'datamig@copart.com',
      source_system: 'YP14024',
      facility_schedule_id: 70303,
      schedule_type_code: 'BYRPU',
      facility_id: 68,
      start_datetime: '2021-04-06T22:00:00+00:00',
      end_datetime: '2021-04-06T23:00:00+00:00',
      capacity: 20,
      scheduled_status: null,
      scheduled_lots: 0,
      unscheduled_lots: null
    },
    {
      status: 'A',
      created_at: '2019-09-20T13:25:55+00:00',
      created_by: 'OPSPORTUSR',
      updated_at: '2020-02-24T16:57:49+00:00',
      updated_by: 'datamig@copart.com',
      source_system: 'YP14024',
      facility_schedule_id: 68072,
      schedule_type_code: 'BYRPU',
      facility_id: 68,
      start_datetime: '2021-04-06T20:30:00+00:00',
      end_datetime: '2021-04-06T21:00:00+00:00',
      capacity: 200,
      scheduled_status: null,
      scheduled_lots: 0,
      unscheduled_lots: null
    },
    {
      status: 'A',
      created_at: '2019-09-20T13:25:55+00:00',
      created_by: 'OPSPORTUSR',
      updated_at: '2020-02-24T16:57:49+00:00',
      updated_by: 'datamig@copart.com',
      source_system: 'YP14024',
      facility_schedule_id: 66489,
      schedule_type_code: 'BYRPU',
      facility_id: 68,
      start_datetime: '2021-04-06T15:00:00+00:00',
      end_datetime: '2021-04-06T16:00:00+00:00',
      capacity: 200,
      scheduled_status: null,
      scheduled_lots: 0,
      unscheduled_lots: null
    },
    {
      status: 'A',
      created_at: '2019-09-20T13:25:55+00:00',
      created_by: 'OPSPORTUSR',
      updated_at: '2020-02-24T16:57:49+00:00',
      updated_by: 'datamig@copart.com',
      source_system: 'YP14024',
      facility_schedule_id: 66492,
      schedule_type_code: 'BYRPU',
      facility_id: 68,
      start_datetime: '2021-04-06T16:00:00+00:00',
      end_datetime: '2021-04-06T17:00:00+00:00',
      capacity: 200,
      scheduled_status: null,
      scheduled_lots: 0,
      unscheduled_lots: null
    },
    {
      status: 'A',
      created_at: '2019-09-27T10:37:48+00:00',
      created_by: 'sidasaleti',
      updated_at: '2020-02-24T16:57:49+00:00',
      updated_by: 'datamig@copart.com',
      source_system: 'YP14024',
      facility_schedule_id: 70306,
      schedule_type_code: 'BYRPU',
      facility_id: 68,
      start_datetime: '2021-04-06T22:00:00+00:00',
      end_datetime: '2021-04-06T23:00:00+00:00',
      capacity: 20,
      scheduled_status: null,
      scheduled_lots: 0,
      unscheduled_lots: null
    },
    {
      status: 'A',
      created_at: '2019-10-02T20:11:24+00:00',
      created_by: 'hisoni',
      updated_at: '2020-02-24T16:57:49+00:00',
      updated_by: 'datamig@copart.com',
      source_system: 'YP14024',
      facility_schedule_id: 71239,
      schedule_type_code: 'BYRPU',
      facility_id: 68,
      start_datetime: '2021-04-06T22:00:00+00:00',
      end_datetime: '2021-04-06T00:00:00+00:00',
      capacity: 100,
      scheduled_status: null,
      scheduled_lots: 0,
      unscheduled_lots: null
    },
    {
      status: 'A',
      created_at: '2019-10-02T20:11:24+00:00',
      created_by: 'hisoni',
      updated_at: '2020-02-24T16:57:49+00:00',
      updated_by: 'datamig@copart.com',
      source_system: 'YP14024',
      facility_schedule_id: 71242,
      schedule_type_code: 'BYRPU',
      facility_id: 68,
      start_datetime: '2021-04-06T22:00:00+00:00',
      end_datetime: '2021-04-06T00:00:00+00:00',
      capacity: 100,
      scheduled_status: null,
      scheduled_lots: 0,
      unscheduled_lots: null
    },
    {
      status: 'A',
      created_at: '2019-10-02T20:11:24+00:00',
      created_by: 'hisoni',
      updated_at: '2020-02-24T16:57:49+00:00',
      updated_by: 'datamig@copart.com',
      source_system: 'YP14024',
      facility_schedule_id: 71245,
      schedule_type_code: 'BYRPU',
      facility_id: 68,
      start_datetime: '2021-04-06T22:00:00+00:00',
      end_datetime: '2021-04-06T00:00:00+00:00',
      capacity: 100,
      scheduled_status: null,
      scheduled_lots: 0,
      unscheduled_lots: null
    }
  ]
}

export const walkinRequestSummary = {
  status: 'success',
  data: {
    status: 'A',
    created_at: '2021-03-30T05:34:33-07:00',
    created_by: 'YCS',
    updated_at: '2021-03-30T05:34:33-07:00',
    updated_by: 'YCS',
    source_system: 'YCS',
    id: 13998,
    facility_id: 13,
    country_code: 'USA',
    token_number: 2,
    user_type_cd: 'TRNSPTR',
    first_name: 'Shivani',
    last_name: 'Padma',
    phone_number: '18888888889',
    phone_ext: null,
    serviced_by: '',
    latitude: null,
    longitude: null,
    request_source: 'Mobile',
    check_in_time: '2021-03-30T05:34:33-07:00',
    request_time: 5,
    is_text_allowed: true,
    uniq_id: 'c3d73cc6fa12',
    request_status: 'Open',
    lots_count: 2,
    position: 2,
    wait_time: 5,
    show_wait_time: true,
    address: {
      status: 'A',
      created_at: '2020-08-18T02:58:17-07:00',
      created_by: 'datamig@copart.com',
      updated_at: '2020-08-18T02:58:17-07:00',
      updated_by: 'datamig@copart.com',
      source_system: 'USYD_MA_ADDR_MIG',
      address_id: 3042757,
      communication_id: 4104802,
      communication_type_code: 'ADDR',
      address_line_1: 'P.O. BOX 150438',
      address_line_2: '',
      address_line_3: null,
      city: 'LUFKIN',
      county: null,
      state: 'TX',
      state_code: 'TX',
      country_code: 'USA',
      postal_code: '75915',
      postal_code_2: '',
      address_detail: null
    },
    request_details: [
      { lot_number: 67899678, reasons: ['MKPAY', 'PKVEH'] },
      { lot_number: 77896547, reasons: ['MKPAY', 'PKVEH'] }
    ],
    show_queue_position: false,
    facility_name: 'TX - LUFKIN',
    facility_type_code: 'Y'
  }
}

export const confirmAppointment = {
  data: {
    // id: '7b3c5628b2b1'
  }
}

export const truckTypesRefData = {
  status: 'success',
  truck_types: [
    '1 Car',
    '2 Car (1 Flatbed, 1 Tow)',
    '2 Car (2 Flatbed)',
    '3 Car (2 Flatbed, 1 Tow)',
    '4 Car (3 Flatbed)',
    '4 Car (3 Flatbed, 1 Tow)',
    '> 4 Car'
  ]
}

export const getAppointmentDetails = {
  status: 'success',
  transporter_details: {
    id: 16450,
    name: 'suvarna',
    license_plate: 'ASDF1233',
    phone_number: '+918121592972',
    state: 'AX',
    country_code: 'USA',
    drivers_license: 'asd',
    license_state_code: 'AX',
    vehicle_details: 'Honda Accord',
    facility_id: 68,
    registered_user: 'N'
  },
  data: [
    {
      status: 'A',
      created_at: '2021-03-12T09:28:27+00:00',
      created_by: 'dispatch',
      updated_at: '2021-03-12T09:28:27+00:00',
      updated_by: 'dispatch',
      source_system: 'YCSAPI',
      appointment_header_id: 19885,
      facility_id: 68,
      transporter_id: 16450,
      facility_schedule_id: 299345,
      lot_count: 1,
      start_datetime: '2021-03-12T16:00:00+00:00',
      end_datetime: '2021-03-12T17:00:00+00:00',
      arrival_datetime: '2021-03-12T09:28:27+00:00',
      departed_datetime: null,
      appointment_status: 'ARRIVED',
      truck_type_code: '1C',
      appointment_details: [
        {
          status: 'A',
          created_at: '2021-03-12T09:28:27+00:00',
          created_by: 'dispatch',
          updated_at: '2021-03-12T09:28:27+00:00',
          updated_by: 'dispatch',
          source_system: 'YCSAPI',
          appointment_detail_id: 110329,
          appointment_header_id: 19885,
          member_id: 31909,
          lot_number: 68087651,
          start_datetime: '2021-03-12T16:00:00+00:00',
          end_datetime: '2021-03-12T17:00:00+00:00',
          actual_start_datetime: null,
          actual_end_datetime: null,
          appointment_detail_status: 'ARRIVED',
          loader_bundle_activity: {
            status: 'A',
            created_at: '2021-03-12T08:47:05+00:00',
            created_by: 'dispatch',
            updated_at: '2021-03-12T09:28:27+00:00',
            updated_by: 'dispatch',
            source_system: '',
            loader_bundle_activity_id: 43867,
            lot_number: 68087651,
            facility_id: 68,
            member_id: 31909,
            inventory_number: 68087651,
            transporter_id: 16450,
            claimed_employee_id: null,
            claimed_flag: false,
            claimed_datetime: null,
            delivered_employee_id: null,
            delivered_flag: false,
            delivered_datetime: null,
            lot_left_yard_flag: false,
            lot_left_yard_datetime: null,
            loader_bundle_status: '400',
            driver_marked_arrived: 1,
            staged_flag: 0,
            drop_off_date: null,
            appointment_scheduled_flag: false
          },
          lot: {
            status: 'A',
            created_at: '2021-03-09T03:30:26+00:00',
            created_by: 'realtime',
            updated_at: '2021-03-12T07:33:24+00:00',
            updated_by: 'YCS',
            source_system: 'YCS API',
            lot_id: 9782029,
            lot_number: 68087651,
            description: '16 HOND ACCORD RED',
            seller_number: 'WE09',
            cash_tag: null,
            damage_code: 'UK',
            stage: 80,
            lot_status: 'O',
            loss_type_code: 'B',
            lot_type: 'V',
            lot_model_year: 2016,
            lot_make: 'HOND',
            lot_model: 'ACCORD',
            responsible_facility_id: 68,
            has_keys: 'N',
            gate_pass_expiration: '2021-03-11T00:00:00+00:00',
            qr_cd_uid: '0639a9c4-bb0d-4708-a4ca-bc9287dfe2f7',
            title_status: 'Y',
            member_name: 'TEST09 FLAREON',
            amount_due: '169.00',
            member_address: {
              line_1: '2 BRYANT ST.',
              address_line1: '2 BRYANT ST.',
              address_line2: '',
              city: 'SAN FRANCISCO',
              state: 'CA',
              zip: '94105',
              zpp: '',
              country: 'USA'
            },
            as_is_where_is_note:
              'Document evidences transfer of title from seller to Member. Member agreesvehicle is sold "AS IS WHERE IS" with no warranties. Sale is final.Transporter acknowledges it is agent of Member and assumes responsibilityfor ensuring vehicle is loaded and secured for transport in compliance withlaw, and shall defend and indemnify Copart from any claim, damage, or lossrelated to loading and securing of vehicle or transportation from Copart\'spremises. This vehicle is authorized for export only to the country ofultimate destination for use by the ultimate consignee herein identified.It may not be resold, transferred, or otherwise disposed of to any othercountry or person except as authorized by US law. Member agrees to assumeall responsibility for complying with US export regulations includingaccurate filing of AES EEIs and obtaining required licenses. Copart onlymay be listed as USPPI if Member is outside US and purchases vehicle forexport from US; such export will be a Routed Export and Member shouldrefer to requirements at https://bit.ly/2JgpJVl.',
            disclaimers: [
              {
                type: 'as_is_where_is_note',
                message:
                  'Document evidences transfer of title from seller to Member. Member agreesvehicle is sold "AS IS WHERE IS" with no warranties. Sale is final.Transporter acknowledges it is agent of Member and assumes responsibilityfor ensuring vehicle is loaded and secured for transport in compliance withlaw, and shall defend and indemnify Copart from any claim, damage, or lossrelated to loading and securing of vehicle or transportation from Copart\'spremises. This vehicle is authorized for export only to the country ofultimate destination for use by the ultimate consignee herein identified.It may not be resold, transferred, or otherwise disposed of to any othercountry or person except as authorized by US law. Member agrees to assumeall responsibility for complying with US export regulations includingaccurate filing of AES EEIs and obtaining required licenses. Copart onlymay be listed as USPPI if Member is outside US and purchases vehicle forexport from US; such export will be a Routed Export and Member shouldrefer to requirements at https://bit.ly/2JgpJVl.'
              },
              {
                type: 'prop65',
                message:
                  'PROP 65 - PASSENGER VEHICLES WARNING: Operating, servicing and maintaining a passenger vehicle or off-road vehicle can expose you to chemicals including engine exhaust, carbon monoxide, phthalates, and lead, which are known to the State of California to cause cancer and birth defects and other reproductive harm. To minimize exposure, avoid breathing exhaust, do not idle the engine except as necessary, service your vehicle in a well-ventilated area and wear gloves or wash your hands frequently when servicing your vehicle. For more information, go to www.P65warnings.ca.gov/passenger-vehicle.'
              }
            ],
            thumb_image_url: null,
            full_image_url: null,
            vin: '1GKEC13T51R193580',
            run_condition: 'N',
            is_blu_car: false,
            is_cat_b: false,
            is_imaging_360_enabled: false
          }
        }
      ]
    },
    {
      status: 'A',
      created_at: '2021-03-12T09:28:27+00:00',
      created_by: 'dispatch',
      updated_at: '2021-03-12T09:28:27+00:00',
      updated_by: 'dispatch',
      source_system: 'YCSAPI',
      appointment_header_id: 19885,
      facility_id: 68,
      transporter_id: 16450,
      facility_schedule_id: 299345,
      lot_count: 1,
      start_datetime: '2021-03-12T16:00:00+00:00',
      end_datetime: '2021-03-12T17:00:00+00:00',
      arrival_datetime: '2021-03-12T09:28:27+00:00',
      departed_datetime: null,
      appointment_status: 'ARRIVED',
      truck_type_code: '1C',
      appointment_details: [
        {
          status: 'A',
          created_at: '2021-03-12T09:28:27+00:00',
          created_by: 'dispatch',
          updated_at: '2021-03-12T09:28:27+00:00',
          updated_by: 'dispatch',
          source_system: 'YCSAPI',
          appointment_detail_id: 110329,
          appointment_header_id: 19885,
          member_id: 31909,
          lot_number: 68087888,
          start_datetime: '2021-03-12T16:00:00+00:00',
          end_datetime: '2021-03-12T17:00:00+00:00',
          actual_start_datetime: null,
          actual_end_datetime: null,
          appointment_detail_status: 'ARRIVED',
          loader_bundle_activity: {
            status: 'A',
            created_at: '2021-03-12T08:47:05+00:00',
            created_by: 'dispatch',
            updated_at: '2021-03-12T09:28:27+00:00',
            updated_by: 'dispatch',
            source_system: '',
            loader_bundle_activity_id: 43867,
            lot_number: 68087888,
            facility_id: 68,
            member_id: 31909,
            inventory_number: 68087888,
            transporter_id: 16450,
            claimed_employee_id: null,
            claimed_flag: false,
            claimed_datetime: null,
            delivered_employee_id: null,
            delivered_flag: false,
            delivered_datetime: null,
            lot_left_yard_flag: false,
            lot_left_yard_datetime: null,
            loader_bundle_status: '400',
            driver_marked_arrived: 1,
            staged_flag: 0,
            drop_off_date: null,
            appointment_scheduled_flag: false
          },
          lot: {
            status: 'A',
            created_at: '2021-03-09T03:30:26+00:00',
            created_by: 'realtime',
            updated_at: '2021-03-12T07:33:24+00:00',
            updated_by: 'YCS',
            source_system: 'YCS API',
            lot_id: 9782029,
            lot_number: 68087888,
            description: '16 HOND ACCORD RED',
            seller_number: 'WE09',
            cash_tag: null,
            damage_code: 'UK',
            stage: 80,
            lot_status: 'O',
            loss_type_code: 'B',
            lot_type: 'V',
            lot_model_year: 2016,
            lot_make: 'HOND',
            lot_model: 'ACCORD',
            responsible_facility_id: 68,
            has_keys: 'N',
            gate_pass_expiration: '2021-03-11T00:00:00+00:00',
            qr_cd_uid: '0639a9c4-bb0d-4708-a4ca-bc9287dfe2f7',
            title_status: 'Y',
            member_name: 'TEST09 FLAREON',
            amount_due: '169.00',
            member_address: {
              line_1: '2 BRYANT ST.',
              address_line1: '2 BRYANT ST.',
              address_line2: '',
              city: 'SAN FRANCISCO',
              state: 'CA',
              zip: '94105',
              zpp: '',
              country: 'USA'
            },
            as_is_where_is_note:
              'Document evidences transfer of title from seller to Member. Member agreesvehicle is sold "AS IS WHERE IS" with no warranties. Sale is final.Transporter acknowledges it is agent of Member and assumes responsibilityfor ensuring vehicle is loaded and secured for transport in compliance withlaw, and shall defend and indemnify Copart from any claim, damage, or lossrelated to loading and securing of vehicle or transportation from Copart\'spremises. This vehicle is authorized for export only to the country ofultimate destination for use by the ultimate consignee herein identified.It may not be resold, transferred, or otherwise disposed of to any othercountry or person except as authorized by US law. Member agrees to assumeall responsibility for complying with US export regulations includingaccurate filing of AES EEIs and obtaining required licenses. Copart onlymay be listed as USPPI if Member is outside US and purchases vehicle forexport from US; such export will be a Routed Export and Member shouldrefer to requirements at https://bit.ly/2JgpJVl.',
            disclaimers: [
              {
                type: 'as_is_where_is_note',
                message:
                  'Document evidences transfer of title from seller to Member. Member agreesvehicle is sold "AS IS WHERE IS" with no warranties. Sale is final.Transporter acknowledges it is agent of Member and assumes responsibilityfor ensuring vehicle is loaded and secured for transport in compliance withlaw, and shall defend and indemnify Copart from any claim, damage, or lossrelated to loading and securing of vehicle or transportation from Copart\'spremises. This vehicle is authorized for export only to the country ofultimate destination for use by the ultimate consignee herein identified.It may not be resold, transferred, or otherwise disposed of to any othercountry or person except as authorized by US law. Member agrees to assumeall responsibility for complying with US export regulations includingaccurate filing of AES EEIs and obtaining required licenses. Copart onlymay be listed as USPPI if Member is outside US and purchases vehicle forexport from US; such export will be a Routed Export and Member shouldrefer to requirements at https://bit.ly/2JgpJVl.'
              },
              {
                type: 'prop65',
                message:
                  'PROP 65 - PASSENGER VEHICLES WARNING: Operating, servicing and maintaining a passenger vehicle or off-road vehicle can expose you to chemicals including engine exhaust, carbon monoxide, phthalates, and lead, which are known to the State of California to cause cancer and birth defects and other reproductive harm. To minimize exposure, avoid breathing exhaust, do not idle the engine except as necessary, service your vehicle in a well-ventilated area and wear gloves or wash your hands frequently when servicing your vehicle. For more information, go to www.P65warnings.ca.gov/passenger-vehicle.'
              }
            ],
            thumb_image_url: null,
            full_image_url: null,
            vin: '1GKEC13T51R193580',
            run_condition: 'N',
            is_blu_car: false,
            is_cat_b: false,
            is_imaging_360_enabled: false
          }
        }
      ]
    }
  ]
}
