import { pathOr } from 'ramda'
import fetcher from '../../utils/fetcher'
import { getSource } from '../../utils'

import { endpoints } from '../../config/endpoints'

export const markAsArrivedService = (ctx) => {
  const payload = {
    appointment_header_id: ctx.appointmentHeaderId,
    transporter_id: ctx.details.transporterId
  }
  return fetcher.post(endpoints.post.markAsArrived, payload).then((res) => {
    return res
  })
}

export const joinQueue = (ctx) => {
  const payload = {
    user_type: 'TRNSPTR',
    first_name: ctx.details?.firstName,
    last_name: ctx.details?.lastName,
    phone_number: ctx.details?.phoneNumber,
    latitude: ctx.latitude,
    longitude: ctx.longitude,
    lots_count: ctx.details?.numberOfLots,
    yard_number: ctx.details?.facilityId,
    state: ctx.details?.stateCode,
    source: getSource(),
    is_text_allowed: ctx.details?.textAllowed ? 'Y' : 'N',
    lots: ctx.lots.map((item) => ({
      lot_number: item.lotNumber,
      reasons: item.reasons,
      member_id: item.memberId
    }))
  }

  return fetcher.post(endpoints.post.joinQueue, payload).then((res) => {
    const uniqueId = pathOr({}, ['data', 'data', 'uniq_id'], res)
    return { virtualQueueId: uniqueId }
  })
}

export const cancelAppointment = (ctx) => {
  return fetcher
    .delete(endpoints.delete.cancelAppointment, {
      appointment_header_ids: [ctx.appointmentHeaderId],
      transporter_id: ctx.details.transporterId

      // transporter_details: {
      //   phone_number: ctx.details.rawPhoneNumber,
      //   transporter_id: ctx.details.transporterId
      // }
    })
    .then((res) => res)
}

export const sendSMSService = (ctx) => {
  const payload = {
    phone_number: `+${ctx.details?.phoneNumber}`,
    facility_id: ctx.details?.facilityId,
    start_datetime: ctx.startDateTime,
    appointment_header_id: ctx.appointmentHeaderId
  }
  return fetcher.post(endpoints.post.sendSMS, payload).then((res) => {
    return res
  })
}
