import { Machine, send, sendParent } from 'xstate'

const lotsGroupInnerEvents = {
  ADD_LOT: {
    actions: [
      'addLot',
      'changeNumberOfLotsField',
      send('UPDATE_LOT_INFO_ITEMS_TO_PARENT'),
      'updateNumberOfLotsToForm'
    ],
    target: '.checkingForViewSwitching'
  },
  SELECT_NUMBER_OF_LOTS: {
    actions: [
      'setNumberOfLots',
      'addOrRemoveLotItems',
      send('UPDATE_LOT_INFO_ITEMS_TO_PARENT'),
      'updateNumberOfLotsToForm'
    ],
    target: '.checkingForViewSwitching'
  },
  REMOVE_LOT: {
    actions: [
      'removeLotItem',
      'changeNumberOfLotsField',
      send('UPDATE_LOT_INFO_ITEMS_TO_PARENT'),
      'updateNumberOfLotsToForm'
    ],
    target: '.checkingForViewSwitching'
  }
}

const machine = Machine({
  id: 'lotsGroup',
  initial: 'editing',
  states: {
    editing: {
      initial: 'checkingTypeOfUser',
      on: {
        UPDATE_LOT_INFO_ITEMS_TO_PARENT: {
          actions: ['updateLotInfoItemsToForm']
        },
        MEMBER_NUMBER_CHANGE: {
          actions: [
            'sendMemberNumberUpdateEventToLotItemMachine',
            'setMemberNumber'
          ]
        }
      },
      states: {
        checkingTypeOfUser: {
          always: [
            {
              cond: 'isInTowProvidersFlow',
              target: 'towProvider'
            },
            {
              target: 'nonTowProvider'
            }
          ]
        },
        towProvider: {
          initial: 'validating',
          entry: ['entryAction', send('UPDATE_LOT_INFO_ITEMS_TO_PARENT')],
          on: {
            ...lotsGroupInnerEvents,
            UPDATE_LOT_ITEM: {
              actions: [
                'updateLotItem',
                send('UPDATE_LOT_INFO_ITEMS_TO_PARENT')
              ],
              target: '.checkingForViewSwitching'
            },
            UPDATE_REASONS: {
              actions: [
                'updateLotItem',
                send('UPDATE_LOT_INFO_ITEMS_TO_PARENT')
              ],
              target: '.checkingForViewSwitching'
            }
          },
          states: {
            checkingForViewSwitching: {
              always: [
                {
                  cond: 'isSomeOfTheSelectedReasonsIncludesPickupVehicle',
                  target: 'validating'
                },
                {
                  actions: [
                    sendParent('GOTO_NON_TOW_PROVIDER_STATE'),
                    'removeTowProviderReasons'
                  ],
                  target: '#lotsGroup.editing.nonTowProvider'
                }
              ]
            },
            validating: {
              always: [
                {
                  cond: 'isTowProvidersRequiredLotItemsFilled',
                  target: 'errorFree'
                },
                {
                  target: 'error'
                }
              ]
            },
            error: {},
            errorFree: {}
          }
        },
        nonTowProvider: {
          initial: 'validating',
          entry: ['entryAction', send('UPDATE_LOT_INFO_ITEMS_TO_PARENT')],
          on: {
            ...lotsGroupInnerEvents,
            UPDATE_LOT_ITEM: {
              actions: [
                'updateLotItem',
                send('UPDATE_LOT_INFO_ITEMS_TO_PARENT')
              ],
              target: '.checkingForViewSwitching'
            },
            UPDATE_REASONS: {
              actions: [
                'updateLotItem',
                send('UPDATE_LOT_INFO_ITEMS_TO_PARENT')
              ],
              target: '.checkingForViewSwitching'
            }
          },
          states: {
            checkingForViewSwitching: {
              always: [
                {
                  cond: 'isReasonsHasPickupVehicleAndTowProvider',
                  actions: [
                    sendParent('GOTO_TOW_PROVIDER_STATE'),
                    'setTowProviderReasons'
                  ],
                  target: '#lotsGroup.editing.towProvider'
                },
                {
                  target: 'validating'
                }
              ]
            },
            validating: {
              always: [
                {
                  cond: 'isNonTowProvidersRequiredLotItemsFilled',
                  target: 'errorFree'
                },
                {
                  target: 'error'
                }
              ]
            },
            error: {},
            errorFree: {}
          }
        }
      }
    }
  }
})

export default machine
