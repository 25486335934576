import machine from './machine'
import * as actions from './actions'
import * as services from './services'
import * as guards from './guards'

export default machine.withConfig({
  actions,
  services,
  guards
})
