import { isEmpty, isNil } from 'ramda'
import { isTowProviderCheck } from '../../utils'

export const isTowProvider = (ctx) => {
  return isTowProviderCheck(ctx.userType, ctx.reasons, ctx.facilityId)
}

export const islotNumberEmptyOrNil = (ctx) =>
  isNil(ctx.lot_number) || isEmpty(ctx.lot_number)

export const isMemberNumberOrGatePassPinEmpty = (ctx) => {
  return (
    (isNil(ctx.member_number) || isEmpty(ctx.member_number)) &&
    (isNil(ctx.gate_pass_pin) || isEmpty(ctx.gate_pass_pin))
  )
}

export const isPickupVehicleReasonUnSelected = (ctx, event) => {
  return !event.value.includes('PKVEH')
}

export const isLotNumberFilledOrGatePassPinFilled = (ctx) => {
  const isValidLotNumber = ctx.lot_number.length === 8
  return (
    !(isNil(ctx.gate_pass_pin) || isEmpty(ctx.gate_pass_pin)) &&
    !(isNil(ctx.lot_number) || isEmpty(ctx.lot_number)) &&
    isValidLotNumber
  )
}

export const isMemberNumberFilledOrGatePassPinFilled = (ctx) =>
  !(isNil(ctx.member_number) || isEmpty(ctx.member_number)) ||
  !(isNil(ctx.gate_pass_pin) || isEmpty(ctx.gate_pass_pin))

export const isGatePassPinFilledOrLotAndMemberNumberFilled = (ctx) => {
  const isValidLotNumber = ctx.lot_number.length === 8
  return (
    !(isNil(ctx.gate_pass_pin) || isEmpty(ctx.gate_pass_pin)) &&
    !(isNil(ctx.lot_number) || isEmpty(ctx.lot_number)) &&
    isValidLotNumber
  )
}

export const isInTowProviderFlow = (ctx, event) => {
  return isTowProviderCheck(ctx.userType, event.value, ctx.facilityId)
}
