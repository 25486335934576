import { compose, pathOr, values, map, head } from 'ramda'
import fetcher from '../utils/fetcher'
import { constructFacilityData, getMode, substitute } from '../utils'
import { getPropertyFromLocalStorage } from '../utils/storage'
import { countryMapper } from '../constants'
import { endpoints } from '../config/endpoints'

const STACK = process.env.REACT_APP_STACK || 'c'

const defaultCountry = STACK === 'c' ? 'USA' : 'GBR'

function getSource() {
  if (getPropertyFromLocalStorage('facilityId')) return 'Kiosk'
  return 'Mobile'
}

function getCountryCode(countryCode) {
  return countryMapper[countryCode] || defaultCountry
}

export async function joinQueue(params) {
  try {
    const payload = {
      user_type: params.userType,
      first_name: params.firstName,
      last_name: params.lastName,
      phone_number: params.phoneNumber,
      latitude: params.latitude,
      longitude: params.longitude,
      lots_count: params.numberOfLots,
      yard_number: params.facilityId,
      state: params.stateCode,
      source: getSource(),
      is_text_allowed: params.textAllowed ? 'Y' : 'N',
      lots: params.items,
      ...params.additionalAttributes
    }
    const response = await fetcher.post(endpoints.post.joinQueue, payload)
    const uniqueId = pathOr({}, ['data', 'data', 'uniq_id'], response)
    return { status: 'success', uniqueId }
  } catch ({ response }) {
    console.error('Failed', response)
    return {
      status: 'error',
      errorMessage: compose(head, pathOr([''], ['data', 'message']))(response)
    }
  }
}

export async function fetchRequestData(requestId) {
  try {
    let response = await fetcher.get(
      substitute(endpoints.get.walkInRequest, { requestId })
    )
    return pathOr({}, ['data', 'data'], response)
  } catch ({ response }) {
    console.error('Failed', response)
    throw response
  }
}

export async function fetchKioskConfig() {
  try {
    const response = await fetcher.get(endpoints.get.config)
    return pathOr({}, ['data', 'data'], response)
  } catch ({ response }) {
    console.error('Failed to fetch kiosk config', response)
    throw response
  }
}

export async function leaveQueue(requestId) {
  try {
    const response = await fetcher.put(
      substitute(endpoints.put.cancelWalkInRequest, { requestId }),
      {}
    )
    return response
  } catch ({ response }) {
    console.error('Failed', response)
  }
}

// fetching US user types & reasons ref data for Canada
export async function fetchReferenceData(type, countryCode) {
  try {
    const response = await fetcher.post(
      endpoints.post.references,
      { data_type: type },
      {
        countrycode: getCountryCode(
          countryCode === 'ca' && type !== 'states' ? 'us' : countryCode
        )
      }
    )
    return compose(
      map(head),
      values,
      pathOr({}, ['data', 'data', type])
    )(response)
  } catch ({ response }) {
    console.error('Failed', response)
  }
}

export async function fetchTruckTypes(type, countryCode) {
  try {
    const response = await fetcher.get(endpoints.get.truckTypes, {
      countrycode: getCountryCode(
        countryCode === 'ca' && type !== 'states' ? 'us' : countryCode
      )
    })
    return pathOr({}, ['data', 'data', type])(response)
  } catch ({ response }) {
    console.error('Failed', response)
  }
}

export async function fetchClosestYard(latitude, longitude) {
  try {
    const response = await fetcher.post(endpoints.post.closestYard, {
      latitude,
      longitude,
      skip_test_yards: getMode() !== 'test'
    })
    const defaultValues = [{ yard_number: '', yard_state_code: '' }]
    const { yard_number: closestFacilityId, yard_state_code: yardStateCode } =
      compose(head, pathOr(defaultValues, ['data', 'data']))(response)
    return {
      status: 'success',
      closestFacilityId,
      yardStateCode
    }
  } catch ({ response }) {
    console.error('Failed', response)
    return { status: 'error' }
  }
}

export async function fetchFacilitiesReferenceData(countryCode) {
  try {
    const response = await fetcher.post(
      endpoints.post.closestYard,
      {
        skip_test_yards: getMode() !== 'test'
      },
      {
        countrycode: getCountryCode(countryCode)
      }
    )
    return constructFacilityData(response, STACK)
  } catch ({ response }) {
    console.error('Failed', response)
    return {}
  }
}

export const getYardClosedMessage = async (facilityId) => {
  try {
    const response = await fetcher.get(
      substitute(endpoints.get.kioskStatus, { facilityId })
    )
    return pathOr('', ['data', 'data'], response)
  } catch ({ response }) {
    console.error('Failed', response)
    throw response
  }
}
