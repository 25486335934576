import React from 'react'
import { useService } from '@xstate/react'
import { GoogleApiWrapper } from 'google-maps-react'
import { GOOGLE_API_KEY } from './../constants'

import { Button, Icon, Message } from 'semantic-ui-react'
import ErrorMessage from '../components/ErrorMessage'
import { mergeMeta, stateMatchingConditionCheck, useLogging } from '../utils'
import locale from '../utils/locale'
import MapWithBounds from './MapWithBounds'

const MarkAsArrivedButton = (props) => {
  const { markAsArrivedMachineRef } = props
  const [state, send] = useService(markAsArrivedMachineRef)

  useLogging(markAsArrivedMachineRef)
  const { context } = state
  console.log('state:::', state)
  const googleMaps = props.google.maps

  return (
    <>
      {state.matches('alreadyArrivedAppointment.appointmentPopup') && (
        <div className="arrivedButton">
          <Button
            fluid
            onClick={() => {
              send('SHOW_APPOINTMENT_SUMMARY')
            }}
            primary
            style={{
              fontWeight: 'normal',
              backgroundColor: '#7dc033'
            }}
            data-testid="showAppointmentSummary"
          >
            {locale('showAppointment')}
          </Button>
        </div>
      )}
      {state.matches('alreadyArrivedAppointment') && (
        <>
          {mergeMeta(state.meta).warning && (
            <Message positive>{locale(mergeMeta(state.meta).warning)}</Message>
          )}{' '}
          <div className="arrivedButton">
            <Button
              fluid
              primary
              style={{
                fontWeight: 'normal'
              }}
              data-testid="markAsArrived"
              disabled={true}
            >
              <span>{locale('markAsArrived')}</span>
              <Icon name="check" />
            </Button>
          </div>
        </>
      )}

      {!state.matches('alreadyArrivedAppointment') &&
        !state.matches('futureDayAppointment') && (
          <>
            {stateMatchingConditionCheck(state, [
              'fetchingYardCoordinatesFailed'
            ]) && (
              <ErrorMessage message={locale(mergeMeta(state.meta).error)} />
            )}
            {stateMatchingConditionCheck(state, [
              'markAsArrivedEnabledState.error'
            ]) && <ErrorMessage message={state.context.error} />}
            {stateMatchingConditionCheck(state, [
              'fetchingYardCoordinates',
              'lotsUnPaid',
              'locationNotShared',
              'notWithinTimeRange'
            ]) && (
              <div
                className="locationSharingInstructions"
                style={{ marginBottom: '8px', marginRight: '0' }}
              >
                <p>
                  <strong>{locale(mergeMeta(state.meta).warning)}</strong>
                </p>
              </div>
            )}

            {stateMatchingConditionCheck(state, ['locationNotMatching']) && (
              <div>
                <div
                  className="locationSharingInstructions"
                  style={{ marginBottom: '8px', marginRight: '0' }}
                >
                  <p>
                    <strong>{locale(mergeMeta(state.meta).warning)}</strong>
                  </p>
                </div>
                <MapWithBounds
                  isMarkerShown
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places,geometry,drawing`}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={
                    <div style={{ height: `250px`, marginBottom: '10px' }} />
                  }
                  mapElement={<div style={{ height: `100%` }} />}
                  markers={context?.markers || []}
                  googleMaps={googleMaps}
                />
              </div>
            )}
            <div className="arrivedButton">
              <Button
                fluid
                onClick={() => {
                  send('MARK_AS_ARRIVED')
                }}
                loading={state.matches(
                  'markAsArrivedEnabledState.markingAsArrived'
                )}
                primary
                style={{
                  fontWeight: 'normal'
                }}
                data-testid="markAsArrived"
                disabled={!state.nextEvents.includes('MARK_AS_ARRIVED')}
              >
                <span>{locale('markAsArrived')}</span>
                {!state.nextEvents.includes('MARK_AS_ARRIVED') && (
                  <Icon name="warning sign" />
                )}
              </Button>
            </div>
          </>
        )}
    </>
  )
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY
})(MarkAsArrivedButton)
