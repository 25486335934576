import { isNil, isEmpty } from 'ramda'
import { isTowProviderCheck, getCountryCode } from '../../utils'
import { towProviderFlowIncludedReasons } from '../../constants'

const isValueEmpty = (val) => isNil(val) || isEmpty(val)

export const isNumberOfLotsGreaterThanLotsAdded = (ctx) =>
  Number(ctx.numberOfLots) > ctx.lotInfoItems.length

export const isNumberOfLotsEqualToLotsAdded = (ctx) =>
  Number(ctx.numberOfLots) === ctx.lotInfoItems.length

export const isInTowProvidersFlow = (ctx) => {
  return isTowProviderCheck(ctx.userType, ctx.reasons, ctx.facilityId)
}

export const isTowProvidersRequiredLotItemsFilled = (ctx) => {
  return ctx?.lotInfoItems?.every((item) => {
    if (item.reasons.includes('PKVEH')) {
      if (getCountryCode() !== 'CAN') {
        return (
          item.lot_number.length === 8 &&
          item.gate_pass_pin &&
          !isValueEmpty(item.reasons)
        )
      }
      return (
        ((item.lot_number.length === 8 && item.member_number) ||
          item.gate_pass_pin) &&
        !isValueEmpty(item.reasons)
      )
    }
    return !isValueEmpty(item.reasons)
  })
}

export const isNonTowProvidersRequiredLotItemsFilled = (ctx) => {
  return ctx?.lotInfoItems?.every((item) => {
    return !isValueEmpty(item.reasons)
  })
}

export const isSomeOfTheSelectedReasonsIncludesPickupVehicle = (ctx, event) => {
  return ctx?.lotInfoItems?.some((item) => {
    return item.reasons.find((reason) =>
      towProviderFlowIncludedReasons.includes(reason)
    )
  })
}

export const isReasonsHasPickupVehicleAndTowProvider = (ctx) => {
  return (
    ctx?.lotInfoItems?.some((item) => {
      return item.reasons.find((reason) =>
        towProviderFlowIncludedReasons.includes(reason)
      )
    }) &&
    (ctx.userType === 'TRNSPTR' || ctx.userType === 'MBR')
  )
}
