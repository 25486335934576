import { Machine, sendParent } from 'xstate'

const machine = Machine({
  id: 'appointmentPopup',
  initial: 'idle',
  on: {
    BOOK_NEW_APPOINTMENT: {
      actions: sendParent('GOTO_SELF_SERVE_FORM')
    },
    CLOSE: {
      actions: sendParent('CLOSE')
    },
    NAVIGATE_TO: {
      actions: sendParent((ctx, event) => ({
        type: 'NAVIGATE_TO',
        url: event.url
      }))
    }
  },
  context: {
    appointmentId: '',
    countryCode: '', // optional
    appointmentStatus: ''
  },
  states: {
    idle: {
      on: {
        SEND_SMS: {
          actions: [],
          target: 'sendingSMS'
        }
      }
    },
    sendingSMS: {
      invoke: {
        id: 'sendSMSService',
        src: 'sendSMSService',
        onDone: [
          {
            cond: 'isStatusOK',
            target: 'waitingToResendSMS'
          },
          {
            actions: ['setResponseError'],
            target: 'error'
          }
        ],
        onError: {
          actions: ['setServerError'],
          target: 'error'
        }
      }
    },
    waitingToResendSMS: {
      after: {
        15000: {
          target: 'resendSMS'
        }
      }
    },
    resendSMS: {
      on: {
        RESEND_SMS: {
          actions: [],
          target: 'sendingSMS'
        }
      }
    },
    error: {
      tags: ['serverError'],
      on: {
        SEND_SMS: {
          target: 'sendingSMS'
        }
      },
      exit: 'clearError'
    }
  }
})

export default machine
