import React, { useEffect, useState } from 'react'
import renderIf from 'render-if'
import { Icon, Button, Modal } from 'semantic-ui-react'

import { formatAddress, getNavigatorGeolocation } from '../../utils'
import locale from '../../utils/locale'
import MapView from './MapView'
import { isBlank } from '../../utils/isBlank'

const markerStyle = {
  color: '#000000',
  opacity: 0.4,
  margin: '2px 10px 0 0'
}

const selectFacilityStyle = {
  ...markerStyle,
  margin: 0
}

const FacilityInfo = (props) => {
  const { mapViewProps = {}, countryCode, stateCode } = props
  const { stateMapViewData = {}, facilityId } = mapViewProps

  const [openMapView, setMapView] = useState(false)
  const [selectedYardData, setSelectedYardData] = useState(
    stateMapViewData[facilityId] || {}
  )
  const [showAutoLocationSelectMsg, setAutoLocationSelectMsg] = useState(
    isBlank(facilityId)
  )

  const mapDataAvailable = !isBlank(stateMapViewData)
  useEffect(() => {
    facilityId && showAutoLocationSelectMsg && setAutoLocationSelectMsg(false)
    setSelectedYardData(stateMapViewData[facilityId] || {})
    // eslint-disable-next-line
  }, [facilityId, mapDataAvailable])

  const { addressSec1 = '', addressSec2 = '' } = formatAddress(selectedYardData)
  const showFacilityAddress = facilityId && !isBlank(selectedYardData)

  const onLocationSharingEnableClick = () => {
    getNavigatorGeolocation(function (position) {
      console.log('Latitude is :', position.coords.latitude)
      console.log('Longitude is :', position.coords.longitude)
    })
  }

  return (
    <>
      <div className="facilityInfoContainer">
        <div className="locDetailsSection">
          <span style={{ fontWeight: 'bold', margin: 0, fontSize: '16px' }}>
            {locale('chooseCopartLocation')}
          </span>
          <Button
            style={{ color: '#171717', fontWeight: 'normal' }}
            onClick={() => setMapView(true)}
          >
            <Icon name="map outline" />
            {locale('viewMap')}
          </Button>
        </div>
        {renderIf(openMapView)(
          <Modal open className="mapViewModal">
            <MapView
              setMapView={setMapView}
              setSelectedYardData={setSelectedYardData}
              countryCode={countryCode}
              stateCode={stateCode}
              {...mapViewProps}
            />
          </Modal>
        )}
        {renderIf(showAutoLocationSelectMsg)(
          <div
            className="locationSharingInstructions"
            onClick={onLocationSharingEnableClick}
          >
            {
              <p>
                {locale('allow')} <strong>{locale('locationSharing')}</strong>{' '}
                {locale('facilityAutoSelect')}
              </p>
            }
          </div>
        )}
        {props.children}
        <div className="addressContainer">
          <Icon name="map marker alternate" style={markerStyle} />
          <div>
            {renderIf(!showFacilityAddress)(
              <div style={selectFacilityStyle}>
                {locale('selectFacilityText')}
              </div>
            )}
            {renderIf(showFacilityAddress)(
              <>
                <div>{addressSec1}</div>
                <div>{addressSec2}</div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default FacilityInfo
