import React from 'react'
import enDefaults from './en'

export default {
  ...enDefaults,
  selectUserType: 'Escoja la opción que mejor lo describa',
  selectReasons:
    'Motivo(s) para la visita? (Seleccione todas las que correspondan)',
  lotIndex: 'Número de lote',
  firstName: 'Nombre',
  lastName: 'Apellido',
  enterFirstName: 'Nombre',
  enterLastName: 'Apellido',
  enterPhone: 'Número de teléfono móvil',
  selectState: 'El estado de la ubicación de Copart',
  selectCopartLocation: 'La ubicación de Copart',
  joinQueue: 'Ingresar inicio de sesión',
  joinOfficeQueue: 'Ingresar inicio de sesión',
  removeQueue: 'Eliminarme del inicio de sesión de visitantes',
  cancel: 'Cancelar',
  goHome: 'Regresar a la página de inicio',
  confirmRemoveMessage:
    'Perderá su posición actual. Esta acción no se puede deshacer.',
  copartSelfServeKiosk: 'Inicio de sesión del visitante',
  selfServiceKiosk: 'Inicio de sesión del visitante',
  selfServeKiosk: 'Inicio de sesión del visitante',
  unableToJoinQueue:
    'No se puede ingresar al inicio de sesión de visitante, inténtelo de nuevo más tarde.',
  textAllowed: 'Reciba actualizaciones por mensaje de texto',
  disclaimerText:
    'Si marca esta casilla, le otorga permiso a Copart para que le envíe mensajes de texto al número de teléfono que ingresó anteriormente.',
  thankYouSummary: 'Gracias por su paciencia.',
  thankYouPremiumMember: 'Gracias por ser un miembro Premier!',
  currentPosition: 'Su posición actual en la cola es',
  willSendText: 'Le enviaremos un mensaje de texto cuando un representante',
  nearFrontLine: 'esté listo para ayudarlo.',
  summary: 'Resumen',
  name: 'Nombre',
  userType: 'Tipo de usuario',
  reason: 'Motivo(s) para la visita',
  phone: 'Número de teléfono',
  copartLocation: 'Ubicación de Copart',
  State: 'Estado',
  Province: 'Provincia de ubicación de Copart',
  lotNumbers: 'Lote',
  createdTime: 'Hora de creación',
  English: 'Inglés',
  Spanish: 'Español',
  French: 'Francés',
  Russian: 'Ruso',
  setupKioskYard: 'Configurar kiosko para la instalación',
  requestStatus: 'Estado',
  welcome: '¡Bienvenido!',
  welcomeTo: 'Bienvenido al',
  receiveAssistance:
    'Complete la información a continuación para reservar su lugar en la fila.',
  somethingWentWrong: 'Se produjo un error',
  invalidExpiredRequest:
    'Solicitud no válida o caducada. No se pudo obtener la información del resumen para su pedido',
  thankYouForVisiting: 'Gracias por visitar Copart.',
  requireAssistance:
    'Si requiere asistencia, puede unirse a la cola al hacer clic en el botón Regresar a la página de inicio a continuación.',
  lastUpdateTime: 'Última actualización',
  estimatedWaitTime: 'Tiempo de espera estimado',
  minute: 'minute',
  minutes: 'minutes',
  readyToAssist:
    'Un representante de Copart está listo para ayudarlo. Acérquese al mostrador.',
  waitTimeDisclaimer:
    'Tenga en cuenta que su posición en la fila y el tiempo de espera puede cambiar ligeramente en función de la prioridad en la cola.',
  reportTimeDisclaimer:
    'Preséntese en la oficina tan pronto como se le notifique o su cita puede ser cancelada.',
  numberOfLots: 'Número de lotes',
  chooseDesigantionText:
    'Elija su designación apropiada a continuación para comenzar.',
  'Personal Info & Location': 'Personal Info & Location',
  'Reason for Visit': 'Motivo para la visita',
  enterMemberId:
    'Ingrese el número de miembro o dirección de correo electrónico',
  queue: 'Cola',
  hey: 'Ey',
  fasterService:
    'Para un servicio más rápido, ingrese todos los números de lote a continuación',
  selectFacilityText: 'Seleccione una instalación para ver la dirección',
  allow: 'Permitir',
  locationSharing: 'compartir ubicación',
  facilityAutoSelect:
    'para permitir la selección automática de su instalación más cercana.',
  nearbyFacilitiesText: 'Ubicaciones cercanas',
  mapViewHeader: 'Selecciona una ubicación',
  facilitySelectMapInstruction:
    'Seleccione un estado / provincia para ver las respectivas ubicaciones de Copart y toque la ubicación para la que desea hacer cola en el mapa para seleccionar una.',
  selectFacility: 'Seleccionar ubicación',
  locationDetails: 'Detalles de ubicación',
  viewMap: 'Ver el mapa',
  yard: 'Yarda',
  sublot: 'Sublote',
  noLocationsFound: '¡No se encontraron ubicaciones!',
  requestSubmitted: 'Solicitud enviada',
  chooseCopartLocation: 'Elija la ubicación de Copart',
  continue: 'Continuar',
  phoneNumberInvalidWarning:
    'Por favor, asegúrese de introducir un número de teléfono móvil válido',
  nonTowProviderSelfServeFormFillFormHelpText:
    'Complete la información obligatoria a continuación para ingresar el inicio de sesión de visitante.',
  welcomeLabel: 'Bienvenido',
  truckType: 'Tipo de camión',
  licenseState: 'Estado de la matrícula',
  licensePlateNumber: 'Numero de licencia',
  enterTruckType: 'Introduce el tipo de camión',
  enterLicenseState: 'Ingrese el estado de la licencia',
  enterLicensePlateNumber: 'Ingrese el número de placa',
  confirmation: 'Confirmación',
  or: 'o',
  limitedSlotsAvailableWarning:
    'Se recomienda a los clientes de Copart que programen citas para recoger vehículos. Debido a la alta demanda, puede haber cupos limitados disponibles. Por favor proceda a reservar una cita ahora.',
  checkSchedule: 'Consultar horario',
  towProviderSelfServeFormFillFormHelpText: (
    <>
      Complete la información <span style={{ color: 'red' }}>obligatoria</span>{' '}
      a continuación para ingresar el inicio de sesión de visitante.
    </>
  ),
  gatePassPin: 'Pasador de pase de puerta',
  slotsNotAvailableMessage:
    'Ranuras no disponibles. Para obtener ayuda, haga clic en el botón de abajo para ingresar al inicio de sesión de visitante.',
  didYouKnow: '¿Lo sabías?',
  payOnline: 'Copart ofrece varias formas de pagar en línea.',
  learnMore: 'Más información',
  Member: 'Miembro',
  'Make Payments': 'Realizar pagos',
  'Pickup Vehicles': 'Recoger vehículos',
  'Drop off/Pickup Titles or Paperwork':
    'Entrega/recogida de títulos o papeleo',
  'Drop off/Pickup Keys': 'Entrega/recogida de llaves',
  'Pickup Personal Vehicle Items': 'Recoger artículos de vehículos personales',
  Other: 'Otro',
  'Vehicle Inspection': 'Inspección vehicular',
  'Vehicle Drop off': 'Entrega del vehículo',
  'Sign Titles': 'Firmar títulos',
  'Pickup Check': 'Verificación de recogida',
  'Windshield labels': 'Etiquetas del parabrisas',
  'Preview vehicles': 'Vista previa de vehículos',
  'Questions about membership': 'Preguntas sobre la membresía',
  'Questions about a Vehicle': 'Preguntas sobre un vehículo',
  'Vehicle Owner': 'Propietario del vehículo',
  Adjuster: 'Ajustador',
  'CDS Seller': 'Vendedor de CDS',
  Vendor: 'Proveedor',
  'Premier Member': 'Miembro premier',
  Transporter: 'Transportador',
  'Unexpected error, could not verify the yard availability status':
    'Error inesperado, no se pudo verificar el estado de disponibilidad de las yardas',
  gatepassQuestion1: '¿Necesita un PIN de Pase de Puerta?',
  gatepassInfo1: `Si es un Transportista, por favor comuníquese con el miembro para obtener el PIN correcto de Pase de Puerta.
  Si es miembro, puede ver su PIN en Copart.com o en la aplicación móvil para miembros de Copart.`,
  gatepassQuestion2: `¿Cómo puede el miembro ver su PIN
   exclusivo de Pase de Puerta para cada lote?`,
  gatepassInfo2: `El miembro puede localizar el PIN iniciando sesión en Copart.com o en la aplicación móvil para miembros de Copart. Después de iniciar sesión, navegue hasta la pestaña Pagos. Aquí, el PIN del Pase de Puerta se mostrará para cualquier vehículo que el Miembro haya ganado en la página Pagos adeudados.`,
  gotIt: '¡Entiendo!',
  numberOfGatePassPINs: 'Numero de PIN de permiso de entrada'
}
