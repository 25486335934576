import React, { useEffect } from 'react'
import { Icon } from 'semantic-ui-react'

import locale from '../../utils/locale'
import { getNavigatorGeolocation } from '../../utils'
import Popup from '../../components/Popup'
import AppointmentPopup from './AppointmentPopup'

const AppointmentFound = ({ state, mapData, send }) => {
  const { context } = state

  useEffect(() => {
    getNavigatorGeolocation(function (position) {
      console.log('Latitude is :', position.coords.latitude)
      console.log('Longitude is :', position.coords.longitude)
      send({
        type: 'SET_LATITUDE_AND_LONGITUDE',
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Popup height="auto">
      <>
        <Icon
          name="close"
          onClick={() => {
            send('CLOSE')
          }}
          style={{ fontSize: '20px' }}
        />
        <div className="appointmentFound">
          <div className="welcomeLabel">{locale('welcomeLabel')}</div>
          {/* {state.matches('towProvider.appointmentFound.oneAppointment') && (
            <div className="pickupAppointmentHeading">
              {' '}
              <span>{locale('pickupAppointment')}</span>
            </div>
          )} */}
          {state.matches(
            'towProvider.appointmentFound.multipleAppointments'
          ) && (
            <div className="pickupAppointmentHeading">
              {/* <span>
                {locale('pickupAppointment')}{' '}
                {`(${context.appointmentCounter + 1}/${
                  context.transporter?.appointments.length
                })`}
              </span> */}
              <span class="nextPrev">
                <Icon
                  name="angle left"
                  style={{ fontSize: '24px', color: 'black' }}
                  disabled={!state.nextEvents.includes('PREVIOUS_APPOINTMENT')}
                  onClick={() => send('PREVIOUS_APPOINTMENT')}
                />
                <Icon
                  name="angle right"
                  style={{ fontSize: '24px', color: 'black' }}
                  disabled={!state.nextEvents.includes('NEXT_APPOINTMENT')}
                  onClick={() => send('NEXT_APPOINTMENT')}
                />
              </span>
            </div>
          )}
        </div>{' '}
        {context.transporter?.appointments?.map((appointment, index) => (
          <AppointmentPopup
            mapData={mapData}
            appointmentRef={appointment?.appointmentRef}
            show={context.appointmentCounter === index}
          />
        ))}
      </>
    </Popup>
  )
}

export default AppointmentFound
