import React from 'react'
import Link from 'react-router-dom/Link'
import hiringBannerConfig from '../config/hiringBanner'
import './style.css'

const STACK = process.env.REACT_APP_STACK || 'c'
const defaultCountry = STACK === 'c' ? 'us' : 'gb'

const HiringBanner = ({ showHiringBanner = false, onLinkClick = () => {} }) => (
  <>
    {showHiringBanner && hiringBannerConfig?.enabled[[defaultCountry]] && (
      <div className="BannerBox">
        <div className="BannerContainer">
          <div className="BannerText">
            <div className="BannerHeadlineText">
              {hiringBannerConfig?.headerText}
            </div>
            {hiringBannerConfig?.mainText?.map((text) => (
              <div className="BannerMainText">{text}</div>
            ))}
            <div className="BannerButton">
              <Link
                to={{ pathname: hiringBannerConfig?.careerLink }}
                className="BannerLink"
                target="_blank"
                onClick={onLinkClick}
              >
                Start Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    )}
  </>
)

export default HiringBanner
