import React from 'react'
import renderIf from 'render-if'
import { Icon } from 'semantic-ui-react'

import Banner from '../../components/Banner'
import { getPropertyFromLocalStorage } from '../../utils/storage'
import locale from '../../utils/locale'

const WelcomeBanner = ({
  facilityId,
  stateCode,
  firstName,
  userType,
  backToHome,
  fillInformationHelpText
}) => {
  return (
    <>
      {renderIf(
        getPropertyFromLocalStorage('facilityId') &&
          getPropertyFromLocalStorage('stateCode')
      )(<Banner facilityId={facilityId} stateCode={stateCode} />)}
      <div className="WelcomeMessageForm">
        <Icon
          name="angle left"
          onClick={backToHome}
          style={{ fontSize: '24px', position: 'absolute' }}
        />
        <div className="WelcomeUser">{locale('welcomeLabel')} </div>
        <div className="WelcomeMessageFormText">{fillInformationHelpText}</div>
      </div>
    </>
  )
}

export default WelcomeBanner
