import { assign } from '@xstate/immer'
import { head } from 'ramda'

export const setClosestYardDetails = assign((ctx, event) => {
  ctx.stateCode = event.data.stateCode
  ctx.facilityId = event.data.facilityId
})

export const setClosestYardDetailsFromUrl = assign((ctx, event) => {
  const yardToSkipLocation = event?.data?.yardFromUrl
  const facilities = event?.data?.facilities
  const selectedYardFromUrl = facilities?.find(
    (item) => item.key === Number(yardToSkipLocation)
  )
  ctx.stateCode = selectedYardFromUrl?.state
  ctx.facilityId = selectedYardFromUrl?.key
})

export const setYardDetailsAndStatus = assign((ctx, event) => {
  ctx.yardClosedMessage = event.data.yardClosedMessage
  ctx.yardOpen = event.data.yardOpen
  ctx.timeZone = event.data.timeZone
  ctx.latitude = event.data.latitude
  ctx.longitude = event.data.longitude
})

export const setLocationDetails = assign((ctx, event) => {
  ctx.latitude = event.data.latitude
  ctx.longitude = event.data.longitude
})

export const setYardStatusAndClosedServerError = assign((ctx, event) => {
  ctx.yardClosedMessage =
    'Unexpected error, could not verify the yard availability status'
  ctx.yardOpen = false
})
