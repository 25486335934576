import { assign } from '@xstate/immer'

export const setLotNumber = assign((ctx, event) => {
  if (event.value.length < 8) {
    ctx.isCopartDelivery = false
  }
  ctx.lot_number = event.value
})

export const setReasons = assign((ctx, event) => {
  ctx.reasons = event.value
})

export const setMemberNumber = assign((ctx, event) => {
  ctx.member_number = event.value
})

export const setGatePassPin = assign((ctx, event) => {
  ctx.gate_pass_pin = event.value
})

export const setshowHidePopUp = assign((ctx, event) => {
  ctx.showPopup = event.value
})

export const setIsCopartDelivery = assign((ctx, event) => {
  ctx.isCopartDelivery = event?.data?.data?.data[0]?.copart_delivery || false
})
